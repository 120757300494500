import { Data } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { forgotPasswordApi } from '../reduxToolKit/slices/forgotPasswordApiSlice'
import { toast } from 'react-toastify'
import OtpInput from 'react-otp-input';


const initialState = {
  resendOtp: "",
}

const Otp = () => {
  const { state } = useLocation()
  const [istate, updateState] = useState(initialState)
  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { resendOtp } = istate


  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   updateState({
  //     ...istate,
  //     [name]: value
  //   })
  // }

  useEffect(() => {
    updateState({
      ...istate, resendOtp: state?.otp?.data?.otp || state?.otp
    })
  }, [])

  const [loggedin, setLoggedIn] = useState(false);

  const handleSubmit = () => {
    console.log(resendOtp === otp)
    if (resendOtp === otp) {
      if (state?.from === "Agentlogin") {
        window.localStorage.setItem("swtadmin", JSON.stringify(state?.token))
        setLoggedIn(true)
        navigate('/newIndex')
      } else {
        console.log('In reset password section')
        return navigate('/resetPassword', { state: { data: state } });//
      }
    }
  }

  const handleResend = () => {
    const data = {
      email: state?.email || state?.otp.email
    }
    dispatch(forgotPasswordApi(data)).then((res) => {
      if (res?.payload?.status === 200) {


        console.log('RRRRRRRRRRRRRRRRRRRRRRRRRRRRRR', res)

        updateState({
          ...istate,
          resendOtp: res?.payload?.data?.otp
        })
        setOtp('')
        toast.success(res?.payload?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      } else {
        toast.error(res?.payload?.message ?? "AN Error Occured", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    })
  }

  // console.log('Otp from input box', otp)

  // console.log('State in otp', state.email)
  // if (loggedin) {
  //   return navigate('/newIndex')
  //   //<Navigate to={"/newIndex"} />
  // }
  return (
    <div>
      <section>
        <div className="ContactFormArea ContactArea">
          <div className="container">
            <div className="ContactFormHead TitleBox">
              <h3>Forget Password</h3>
              <span>OTP</span>
            </div>
            <div className="ContactFormBody ContactFormBody1">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="OTPBox">
                      {/* <input
                        type="text"
                        name='one'
                        value={one}
                        onChange={handleChange}
                        className="form-control"
                        maxLength={1} />
                      <input
                        type="text"
                        name='two'
                        value={two}
                        onChange={handleChange}
                        className="form-control"
                        maxLength={1} />
                      <input
                        type="text"
                        name='three'
                        value={three}
                        onChange={handleChange}
                        className="form-control"
                        maxLength={1} />
                      <input
                        type="text"
                        name='four'
                        value={four}
                        onChange={handleChange}
                        className="form-control"
                        maxLength={1} />
                      <input
                        type="text"
                        name='five'
                        value={five}
                        onChange={handleChange}
                        className="form-control"
                        maxLength={1} />
                      <input
                        type="text"
                        name='six'
                        value={six}
                        onChange={handleChange}
                        className="form-control"
                        maxLength={1} /> */}
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <p>{resendOtp}</p>
                    <p className="text-right" style={{ color: "blue" }}>
                      <a
                        onClick={handleResend}>Resend</a>
                    </p>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <button
                      onClick={handleSubmit}
                    >Submit </button>
                  </div>
                  {/* <p class="signIn">Already have account <a href="sign-up.html"> Sign In</a></p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Otp