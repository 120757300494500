import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";


function HotelsImages() {

    const { state } = useLocation();
    const imagesArr = state;
    console.log('imagesArr', imagesArr);

    const settings = {
        customPaging: function (i) {
            return (
                <a >
                    <img src={`http://localhost:8011/proxy/${imagesArr[i]}`} alt={`Slide ${i + 1}`} />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="slider-container px-5">
            <Slider {...settings}>
                {imagesArr.map((image, index) => (
                    <div key={index}>
                        <img
                            src={`http://localhost:8011/proxy/${imagesArr[index]}`}
                            alt={`Slide ${index + 1}`}
                        />
                    </div>
                ))}
            </Slider>

        </div>
    );
}

export default HotelsImages;
