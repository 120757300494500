// countrySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";
import signature from "../../config/signature";

export const fetchRooms = createAsyncThunk('rooms/fetchRooms', async () => {
    const response = await axios.get(`${privateData["proxy-url"]}/hotel-content-api/1.0/types/rooms`, {
        headers: {
            "Api-key": privateData["Api-key"],
            "X-Signature": signature(),
        }
    });
    return response.data;
});

const roomsSlice = createSlice({
    name: 'rooms',
    initialState: {
        rooms: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRooms.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRooms.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.rooms = action.payload;
            })
            .addCase(fetchRooms.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default roomsSlice.reducer;
