import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartnerList } from "../reduxToolKit/slices/partnerListApiSlice";

import OwlCarousel from 'react-owl-carousel2';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { fetchProudPartnerList } from '../reduxToolKit/slices/proudPartnerApiSlice';

const TrustedPartner = () => {
    const partners = useSelector((state) => state?.partnerList.partnerList.data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPartnerList())
    }, [])


    const options = {
        rewind: true,
        items: 6,
        autoplay: true,
        nav: false,
        navText: [],
        autoplayTimeout: 5000,
        smartSpeed: 600,
        dots: false,
        loop: true,
        margin: 10,
        responsive: {
            0: { items: 3 },
            600: { items: 5 },
            1000: { items: 6 }
        }
    };

    const events = {
        // onDragged: function (event) {...},
        // onChanged: function(event) { ...}
    };
    return (
        <OwlCarousel className="owl-theme" options={options} events={events}>
            {partners?.length > 0 ? partners.map((item, index) => (
                <figure key={index}>
                    <img src={item?.image} height="70px" width="100px" style={{ margin: '0 10px', aspectRatio: '16:9', borderRadius: '5px' }} alt={`Partner ${index}`} />
                </figure>
            )) : ""}
        </OwlCarousel>
        // <div>
        //     <div className="tab-content">
        //         <div>
        //             <OwlCarousel className="owl-theme" options={options} events={events}>
        //                 {partners?.length > 0 ? partners.map((item, index) => (
        //                     <figure key={index}>
        //                         <img src={require('../assests/images/package_1.png')} height="70px" width="100px" style={{ margin: '0 10px' }} alt={`Partner ${index}`} />
        //                     </figure>
        //                 )) : ""}
        //             </OwlCarousel>
        //         </div>
        //     </div>
        // </div>
    )
}

export default TrustedPartner
