import React, { useEffect } from 'react'
import { images } from '../utils/images'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFlyerList } from '../reduxToolKit/slices/flyerApiSlice'
import { Link } from 'react-router-dom'

const Marketing = () => {
    const dispatch = useDispatch()
    const flyerList = useSelector((state) => state?.flyerList)


    console.log('flyer list', flyerList?.flyerList?.data?.result)


    useEffect(() => {
        dispatch(fetchFlyerList())
    }, [])
    return (
        <div>
            <section>
                <div className="MarketingBanner">
                    <div className="MarketingBannerContent">
                        <h2>Promote your brand with ease</h2>
                        <p>Download and share personalized travel content</p>
                        <div class="SearchBar">
                            <i class="fa-solid fa-magnifying-glass"></i>
                            <input type="text" placeholder="Search travel posters and videos" />
                            <button type="submit">Search</button>
                        </div>
                    </div>

                </div>
            </section>
            <section>
                <div className="MarketingArea" >
                    <div className="row">
                        <div className="col-md-3">
                            {
                                flyerList?.flyerList?.data?.result?.length > 0 ? flyerList?.flyerList?.data?.result?.map((item) => {
                                    return (
                                        <>
                                         <div className='MartinBoxMain'>
                                         <h1>{item?.categoryName}</h1>
                                            <div className="MarketingBox">
                                                <Link to='/shareDealsPage' state={item}>
                                                    {
                                                        item?.documents?.length > 0 ? item?.documents?.map((item) => {
                                                            return (
                                                                <div>
                                                                    <figure>
                                                                        <img src={item?.flyerBanner} />
                                                                    </figure>
                                                                    <figcaption>
                                                                        <h4>
                                                                            {item?.flyerTitle}
                                                                        </h4>
                                                                        <h5>{item?.description}</h5>
                                                                    </figcaption>
                                                                </div>
                                                            )
                                                        }) : ""
                                                    }

                                                </Link>
                                            </div>
                                         </div>
                                            </>
                                    )
                                }) : ""

                            }
                        </div>




                        {/* <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="MarketingBox">
                            <figure>
                                <img src={images.package_1} />
                            </figure>
                            <figcaption>
                                <h4>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                                    harum.
                                </h4>
                            </figcaption>
                        </div>
                    </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Marketing