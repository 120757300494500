import React from "react";

const BookingDetail_Transfer = () => {
  return (
    <div>
      <main id="main" className="ProductMain">
        <section>
          <div className="FilterProductsArea">
            {/* <div class="d-flex my-5">
              <h5 class="" data-toggle="modal" data-target="#BookingConfirmed">BookingConfirmed</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5 class="" data-toggle="modal" data-target="#DeleteModal">Delete Modal</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5 class="" data-toggle="modal" data-target="#CountryModal">Country Modal</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div> */}
            {/* <span class="FilterProductsAreaOverlay">3 products added to the cart</span> */}
            <div className="row">
              <div className="col-md-6">
                <div className="EnterCustomerDetails">
                  <div className="HotelNameRatings Transfer">
                    <figure>
                      <img src="images/car_img_1.png" />
                    </figure>
                    <h3>Private - Standard (Car)</h3>
                    <p>
                      <img src="images/location.png" />
                      1995 Broadway, New York
                    </p>
                  </div>
                  <div className="BookingHotelChecks">
                    <div>
                      <h5>Thursday, Dec 8</h5>
                      <p>Delhi, Indira Gandhi Int.</p>
                    </div>
                    <div>
                      <figure>
                        <img src="images/check_in_out_img.png" />
                      </figure>
                    </div>
                    <div>
                      <h5>Friday, Dec 9</h5>
                      <p>Radisson Blu Hotel New Delhi Dwarka</p>
                    </div>
                  </div>
                  <div className="FlightNumberArea">
                    <h5>Flight number BA2760, Arrival time 06:25</h5>
                    <div className="YellowBox">
                      <p>
                        {" "}
                        <span>
                          <img src="images/error_img.png" />
                        </span>{" "}
                        If the information is not accurate, the supplier is not
                        responsible for the correct service provision.
                      </p>
                    </div>
                    <h6>Outbound, 2 Adults</h6>
                    <p>Cancellation fees — Outbound</p>
                    <div className="OutBoundBox">
                      <div className="OutboundSmall Green">
                        <figure>
                          <img src="images/send_img.png" />
                        </figure>
                        <h6>Free</h6>
                        <p>Free Until 23:59 PM on 13/11/2023</p>
                      </div>
                      <div className="OutboundSmall Red">
                        <figure>
                          <img src="images/send_img.png" />
                        </figure>
                        <h6>INR 1,740.31</h6>
                        <p>After 00:00 on 14/11/2023</p>
                      </div>
                      <h6>INR 2400</h6>
                    </div>
                    <p className="DateTime">
                      Date and time are calculated based on local time in the
                      destination. In case of no-show, different fees will
                      apply. Please refer to our T&amp;C.
                    </p>
                  </div>
                  <h4>Enter Customer Details</h4>
                  <div className="FlightListingRadios">
                    <div className="form-group">
                      <label className="Radio">
                        {" "}
                        Enter the lead passenger data only
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        {" "}
                        Enter the data for all passengers
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="text RadioText" style={{ display: "block" }}>
                    <form>
                      <div className="form-group ForName">
                        <div className="NameSelectBox">
                          <select>
                            <option>Mr.</option>
                          </select>
                          <span>
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email Address"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Pan Card Number"
                        />
                      </div>
                      <p>
                        We’ll call or text you to confirm your number. Standard
                        message and data rates apply. Privacy Policy
                      </p>
                    </form>
                  </div>
                  <div className="file RadioText">
                    <form>
                      <div className="form-group ForName">
                        <div className="NameSelectBox">
                          <select>
                            <option>Mr.</option>
                          </select>
                          <span>
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="PriceBookingDetails">
                  <div className="EmptyCartBox">
                    <p>
                      <img src="images/clock_icon.png" />
                      Expires in <span>03:00</span>
                    </p>
                    <span className="EmptyCart">Empty Cart</span>
                  </div>
                  <div className="PbdHotelName">
                    <h6>
                      {" "}
                      <span>
                        <img src="images/private_standard.png" />
                      </span>{" "}
                      Private - Standard (Car)
                    </h6>
                    <h5>
                      INR 200<span>(Remove)</span>
                    </h5>
                  </div>
                  <label className="CheckBox">
                    I have read <strong>Terms &amp; conditions</strong> and{" "}
                    <strong>Cancellation Policies</strong>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Price Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Amount</td>
                        <td>INR 60</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>INR 0</td>
                      </tr>
                      <tr>
                        <td>Service Fee</td>
                        <td>INR 25</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td>INR 2650</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="PbdHotelName WalletBox">
                    <label className="CheckBox my-0">
                      Wallet <small>Insufficient Balance</small>
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <h5>
                      INR 200<span>(Add Money)</span>
                    </h5>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#BookingConfirmed"
                  className="ConfirmBookigBtn"
                >
                  Confirm the Booking
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default BookingDetail_Transfer;
