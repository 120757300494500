// stateSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//const apiUrl = `https://api.countrystatecity.in/v1/countries/${preferredCountry}/states`;

export const fetchStates = createAsyncThunk('states/fetchStates', async (preferredCountry) => {
  const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${preferredCountry}/states`,{
    headers: { 
      'X-CSCAPI-KEY': 'TjFJOGV1d0FXZDQ0NU5NcnhlQkFmUnpXWlg1bEFBcUo4ejg3TmU3OQ=='
    }
  });
  return response.data;
});

const stateSlice = createSlice({
  name: 'states',
  initialState: {
    states: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.states = action.payload;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default stateSlice.reducer;
