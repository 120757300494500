import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";
import { isLoggedIn } from "../../utils/LoggedIn";

// const apiUrl = 'https://api.example.com/cities';

export const addToCartApi = createAsyncThunk(
  "addToCart/postAddToCart",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.post(
      `https://swtclub.com:4300/api/v1/user/cart/hotel`,
      payload,
      {
        headers: {
          "Authorization": isLoggedIn("swtadmin")
        },
      }
    );
    return response.data
      ;
  }
);

const addToCartHotelRoomSlice = createSlice({
  name: "hotelList",
  initialState: {
    hotelRoomAddToCart: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addToCartApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToCartApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hotelLists = action.payload;
      })
      .addCase(addToCartApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default addToCartHotelRoomSlice.reducer;