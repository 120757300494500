import React, { useState } from 'react'
import { images } from '../utils/images'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const initialState = {
    newPassword: "",
    confirmPassword: "",
    errors: ""
}

const ResetPassword = () => {
    const [iState, updateState] = useState(initialState)
    const navigate = useNavigate()
    const { newPassword, confirmPassword, errors } = iState
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const { state } = useLocation()

    console.log("state in reset password", state)

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword)
    }

    const handleTogglePassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    let handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!newPassword || !newPassword.trim()) {
            error.newPasswordError = " * Password can't be empty";
            formIsValid = false;
        } else {
            const validPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword);
            if (!validPassword) {
                error.newPasswordError = " * Enter valid Password";
                formIsValid = false;
            }
        }

        if (!confirmPassword || !confirmPassword.trim()) {
            error.confirmPasswordError = " * Password can't be empty";
            formIsValid = false;
        } else {
            const validPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(confirmPassword);
            if (!validPassword) {
                error.confirmPasswordError = " * Enter valid Password";
                formIsValid = false;
            }
        }

        if (newPassword !== confirmPassword) {
            error.reCheckpasswordError = " * Password didn't match";
            formIsValid = false;
        }

        updateState({
            ...iState,
            errors: error,
        });
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        const valid = handleValidation()
        console.log('valid', valid, newPassword, confirmPassword)
        if (valid) {
            const data = {
                password: confirmPassword,
                email: state?.data?.email
            }
            const result = await axios.post('https://swtclub.com:4300/api/v1/user/reset-password', data)
            console.log(result)
            if (result?.data?.status === 200) {
                toast.success(result?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                })
                navigate('/agentLogin')
                setShowPassword(false)
                setShowPassword2(false)
            } else {
                toast.error(result?.data?.message ?? "AN Error Occured", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                })
            }
        }
    }

    return (
        <div>
            <div>
                <>
                    <main id="main">
                        <section className="hero" id="hero">
                            <video autoPlay="" muted="" loop="" width="100%">
                                <source src={images.bannerVideo} type="video/mp4" />
                            </video>
                            <div
                                className="hero-content animate fadeInUp"
                                data-animate="fadeInUp"
                                data-duration="1.5s"
                                data-delay="0.3s"
                            >
                                <h1>
                                    Book Travel Boldly With SWT CLUB <br />
                                    <span>A Trusted Global B2B travel platform</span>
                                </h1>
                            </div>
                            {/* <a href="sign-up.html" class="SignUpBtn">Sign Up</a> */}
                        </section>
                    </main>
                    <section>
                        <div className="ContactFormArea ContactArea">
                            <div className="container">
                                <div className="ContactFormHead TitleBox">
                                    <h3>Login</h3>
                                    <span>Forgot Password</span>
                                </div>

                                <form
                                    onSubmit={handleSubmit}
                                >
                                    <div className="ContactFormBody">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder="Enter new Password"
                                                        name="newPassword"
                                                        value={newPassword}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="EyesIcon2"
                                                        onClick={handleTogglePassword}
                                                    >
                                                        {
                                                            showPassword ?
                                                                (
                                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                                ) : (
                                                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                                )}
                                                    </span>
                                                    <span style={{ color: "red" }} className="d-flex">
                                                        {errors?.newPasswordError}
                                                    </span>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type={showPassword2 ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder="Confirm new Password"
                                                        name="confirmPassword"
                                                        value={confirmPassword}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="EyesIcon2"
                                                        onClick={handleTogglePassword2}
                                                    >
                                                        {
                                                            showPassword2 ?
                                                                (
                                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                                ) : (
                                                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                                )}
                                                    </span>
                                                    <span style={{ color: "red" }} className="d-flex">
                                                        {errors?.confirmPasswordError}
                                                    </span>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit">Reset Password</button>
                                                </div>

                                                {/* <p className="signIn">
                                                Don't have account{" "}
                                                <Link to="/signUp"> Sign Up</Link>
                                            </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </>
            </div>
        </div>
    )
}

export default ResetPassword