import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'

const Voucher = () => {
    const { state } = useLocation()
    console.log('state in voucher', state)
    const totalAmount = state?.fullResponse.fullResponse.hotel.rooms?.reduce((acc, curr) => acc + Number(curr?.rates?.[0]?.net), 0)
    console.log('total amount', totalAmount)
    return (
        <div>
            <table
                cellSpacing={0}
                cellPadding={0}
                border={0}
                style={{ width: "100%", margin: "auto", backgroundColor: "#fff" }}
            >
                <tbody>
                    <tr>
                        <td style={{ padding: 0 }}>
                            <table
                                cellSpacing={0}
                                cellPadding={0}
                                border={0}
                                style={{ width: "100%", margin: "auto" }}
                            >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: 0, width: "100%" }}>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <h3 style={{ margin: 0, fontSize: 16 }}>
                                                                <span>Proforma invoice</span>
                                                                <span
                                                                    style={{
                                                                        float: "right",
                                                                        fontSize: 13,
                                                                        color: "#7e7d7d"
                                                                    }}
                                                                >
                                                                    23/05/2024
                                                                </span>
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: "50%",
                                                                textAlign: "left",
                                                                backgroundColor: "#fff"
                                                            }}
                                                        >
                                                            <table
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ padding: 15, borderRadius: 10 }}>
                                                                            <h3
                                                                                style={{ margin: "0 0 10px 0", fontSize: 15 }}
                                                                            >
                                                                                SWT CLUB PRIVATE LIMITE
                                                                            </h3>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 10px 0",
                                                                                    fontSize: 12,
                                                                                    fontWeight: 600,
                                                                                    color: "#5c5c5c"
                                                                                }}
                                                                            >
                                                                                SCO 1, FIRST FLOOR, GURU RAVI DASS
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 10px 0",
                                                                                    fontSize: 12,
                                                                                    fontWeight: 600,
                                                                                    color: "#5c5c5c"
                                                                                }}
                                                                            >
                                                                                136118
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 0px 0",
                                                                                    fontSize: 12,
                                                                                    fontWeight: 600,
                                                                                    color: "#5c5c5c"
                                                                                }}
                                                                            >
                                                                                India
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "50%",
                                                                textAlign: "left",
                                                                backgroundColor: "#fff"
                                                            }}
                                                        >
                                                            <table
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ padding: 15, borderRadius: 10 }}>
                                                                            <figure style={{ margin: 0, minHeight: 95 }}>
                                                                                <img src="https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/Logo.png" />
                                                                            </figure>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "100%", padding: 5 }}>
                                                            <table
                                                                cellPadding={5}
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            colSpan={2}
                                                                            style={{
                                                                                textAlign: "left",
                                                                                backgroundColor: "#005aae"
                                                                            }}
                                                                        >
                                                                            <h3
                                                                                style={{
                                                                                    margin: 0,
                                                                                    color: "#fff",
                                                                                    fontSize: 14,
                                                                                    padding: "5px 10px"
                                                                                }}
                                                                            >
                                                                                Booking Details
                                                                            </h3>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            <p
                                                                                style={{ fontSize: 14, margin: "0 0 10px 0" }}
                                                                            >
                                                                                <strong style={{ color: "#000" }}>
                                                                                    Booking reference :{" "}
                                                                                </strong>
                                                                                <span>{state?.fullResponse?.fullResponse?.reference}</span>
                                                                            </p>
                                                                            <p
                                                                                style={{ fontSize: 14, margin: "0 0 10px 0" }}
                                                                            >
                                                                                <strong style={{ color: "#000" }}>
                                                                                    Passenger name :
                                                                                </strong>
                                                                                <span>---</span>
                                                                            </p>
                                                                            <p
                                                                                style={{ fontSize: 14, margin: "0 0 10px 0" }}
                                                                            >
                                                                                <strong style={{ color: "#000" }}>
                                                                                    Agency Ref. :{" "}
                                                                                </strong>
                                                                                <span>{state?.fullResponse?.fullResponse?.clientReference}</span>
                                                                            </p>
                                                                        </td>
                                                                        <td style={{ width: "50%" }}>
                                                                            <p
                                                                                style={{ fontSize: 14, margin: "0 0 10px 0" }}
                                                                            >
                                                                                <strong style={{ color: "#000" }}>
                                                                                    Booking confirmation date :{" "}
                                                                                </strong>
                                                                                <span>{moment(state?.createdAt).format('DD/MM/YYYY')}</span>
                                                                            </p>
                                                                            <p
                                                                                style={{ fontSize: 14, margin: "0 0 10px 0" }}
                                                                            >
                                                                                <strong style={{ color: "#000" }}>
                                                                                    Payment type :
                                                                                </strong>
                                                                                <span>---</span>
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "100%", padding: 5 }}>
                                                            <table
                                                                cellPadding={5}
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            colSpan={4}
                                                                            style={{
                                                                                textAlign: "left",
                                                                                backgroundColor: "#005aae"
                                                                            }}
                                                                        >
                                                                            <h3
                                                                                style={{
                                                                                    margin: 0,
                                                                                    color: "#fff",
                                                                                    fontSize: 14,
                                                                                    padding: "5px 10px"
                                                                                }}
                                                                            >
                                                                                Services
                                                                            </h3>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>Room Type</span>
                                                                        </th>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>Board</span>
                                                                        </th>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>Occupancy</span>
                                                                        </th>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>&nbsp;</span>
                                                                        </th>
                                                                    </tr>
                                                                    {
                                                                        state?.fullResponse?.fullResponse?.hotel?.rooms?.map((item) => {
                                                                            return (<tr>
                                                                                <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                                    <span>{item?.name}</span>
                                                                                </td>
                                                                                <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                                    <span>{item?.rates?.[0]?.boardName}</span>
                                                                                </td>
                                                                                <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                                    <span>{item?.rates?.[0]?.adults} Adults, {item?.rates?.[0]?.children} Children</span>
                                                                                </td>
                                                                                <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                                    <strong>{item?.rates?.[0]?.net}</strong>
                                                                                </td>
                                                                            </tr>)
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "100%", padding: 5 }}>
                                                            <table
                                                                cellPadding={5}
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            colSpan={4}
                                                                            style={{
                                                                                textAlign: "left",
                                                                                backgroundColor: "#005aae"
                                                                            }}
                                                                        >
                                                                            <h3
                                                                                style={{
                                                                                    margin: 0,
                                                                                    color: "#fff",
                                                                                    fontSize: 14,
                                                                                    padding: "5px 10px"
                                                                                }}
                                                                            >
                                                                                Cancellation Charges
                                                                            </h3>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={4} style={{ textAlign: "left" }}>
                                                                            <h3
                                                                                className="Gray"
                                                                                style={{
                                                                                    margin: "0 0 10px 0",
                                                                                    padding: 10,
                                                                                    borderRadius: 2,
                                                                                    color: "#000",
                                                                                    fontSize: 13
                                                                                }}
                                                                            >
                                                                                ACCOMMODATION
                                                                            </h3>
                                                                            <h4
                                                                                style={{
                                                                                    margin: "0 0 10px 0",
                                                                                    fontSize: 15,
                                                                                    fontWeight: 700,
                                                                                    color: "#000"
                                                                                }}
                                                                            >
                                                                                {state?.fullResponse?.fullResponse?.hotel?.name}
                                                                            </h4>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 10px 0",
                                                                                    fontSize: 13,
                                                                                    fontWeight: 600,
                                                                                    color: "#5c5c5c"
                                                                                }}
                                                                            >
                                                                                {state?.fullResponse?.fullResponse?.hotel?.destinationName} ,
                                                                                {state?.fullResponse?.fullResponse?.hotel?.destinationCode}
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 0px 0",
                                                                                    fontSize: 13,
                                                                                    fontWeight: 600,
                                                                                    color: "#5c5c5c"
                                                                                }}
                                                                            >
                                                                                From :{" "}
                                                                                <strong style={{ color: "#000" }}>
                                                                                    {moment(state?.fullResponse?.fullResponse?.hotel?.checkIn).format('DD/MM/YYYY')}
                                                                                </strong>{" "}
                                                                                (Monday) - Outbound :{" "}
                                                                                <strong style={{ color: "#000" }}>
                                                                                    {moment(state?.fullResponse?.fullResponse?.hotel?.checkOut).format('DD/MM/YYYY')}
                                                                                </strong>{" "}
                                                                                (Friday)
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>Concept</span>
                                                                        </th>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>From</span>
                                                                        </th>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>Units</span>
                                                                        </th>
                                                                        <th style={{ textAlign: "left", fontSize: 13 }}>
                                                                            <span>Value</span>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                            <span>
                                                                                {/* Elite Palace Hotel / Elite Palace Hotel
                                                                                (DOUBLE ECONOMY PREMIUM) x 1 */}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                            {/* <span>03/06/2024 12:59</span> */}
                                                                        </td>
                                                                        <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                            <span>
                                                                                {/* 1  */}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ textAlign: "left", fontSize: 14 }}>
                                                                            <strong>
                                                                                {/* 25,047.62  */}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={4} style={{ textAlign: "left" }}>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 10px 0",
                                                                                    fontSize: 12,
                                                                                    fontWeight: 600,
                                                                                    color: "#000"
                                                                                }}
                                                                            >
                                                                                <strong>
                                                                                    Rate Information <br />
                                                                                    {/* Please note that these rates don't allow
                                                                                    modifications, therefore you would need to
                                                                                    cancel and create a new booking.
                                                                                    Cancellation charges apply as per
                                                                                    cancellation policy */}
                                                                                    {
                                                                                        state?.fullResponse?.fullResponse?.hotel?.rooms?.[0]?.rates?.[0]?.rateComments
                                                                                    }
                                                                                </strong>
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 0px 0",
                                                                                    fontSize: 12,
                                                                                    fontWeight: 600,
                                                                                    color: "#000",
                                                                                    textAlign: "right"
                                                                                }}
                                                                            >
                                                                                <strong>Total : {totalAmount}</strong>
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={4} style={{ textAlign: "right" }}>
                                                                            <h3
                                                                                className="Gray"
                                                                                style={{
                                                                                    margin: "0 0 0px 0",
                                                                                    padding: 12,
                                                                                    color: "#000",
                                                                                    fontSize: 16
                                                                                }}
                                                                            >
                                                                                Total net amount : {totalAmount}
                                                                            </h3>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "100%", padding: 5 }}>
                                                            <table
                                                                cellPadding={5}
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                textAlign: "left",
                                                                                backgroundColor: "#dddedf"
                                                                            }}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                    lineHeight: 22,
                                                                                    fontWeight: 600
                                                                                }}
                                                                            >
                                                                                <span>
                                                                                    Name Hotelbeds DMCC, Bank: CITIBANK NA(293
                                                                                    DN Road Greater Mumbai 400001)
                                                                                    Account:0526641019, SWIFT:CITIINBX, 7 days
                                                                                    prior to clients arrival (except group
                                                                                    bookings with fixed days in advance at the
                                                                                    time of the confirmation) . Please indicate
                                                                                    our reference number when making payment.
                                                                                    Thank you for your cooperation.
                                                                                </span>
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default Voucher