import React from 'react'
import CryptoJS from "crypto-js";
import { privateData } from "../config/apiKey"

const signature = () => {
    // Get the current timestamp in seconds
    const utcDate = Math.floor(new Date().getTime() / 1000);

    // Retrieve API key and secret
    let publicKey = privateData["Api-key"];
    let privateKey = privateData.secret;

    // Concatenate API key, secret, and timestamp
    const assemble = publicKey + privateKey + utcDate;

    // Generate SHA-256 hash
    const hash = CryptoJS.SHA256(assemble).toString(CryptoJS.enc.Hex);
    const encryption = hash;
    return encryption
}

export default signature