import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { images } from "../utils/images";

const Flights = () => {
  return (
    <div>
      <Header/>
      <main id="main" className="ProductMain">
        <section>
          <div className="FlightListingArea">
            <div className="FlightListingRadios">
              <div className="form-group">
                <label className="Radio">
                  {" "}
                  One Way
                  <input
                    type="radio"
                    name="Cuisine"
                    defaultValue="text"
                    defaultChecked=""
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="form-group">
                <label className="Radio">
                  {" "}
                  Round Trip
                  <input type="radio" name="Cuisine" defaultValue="file" />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="text RadioText" style={{ display: "block" }}>
              <div className="AccountHistoryFilter ShowFlightsFilter">
                <div className="Filter">
                  <div className="form-group">
                    <div className="CommonInputBox First Label">
                      <span className="CibLeftOverlay">
                        <img src={images.flightIcon} />
                      </span>
                      <h6>From</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="From"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="CommonInputBox First Label">
                      <span className="CibLeftOverlay">
                        <img src={images.flightIcon} />
                      </span>
                      <h6>To</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="To"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="InputBox">
                      <h6>Departure Date</h6>
                      <input type="date" className="form-control-date" />
                      <span className="FilterIcon">
                        <img src={images.calendarBlueImg} />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="InputBox SelectBox">
                      <span className="StaffIcon">
                        <img src={images.userIcon} />
                      </span>
                      <h6>Guests</h6>
                      <select>
                        <option>select</option>
                        <option>2 guests</option>
                        <option>3 guests</option>
                        <option>4 guests</option>
                        <option>5 guests</option>
                      </select>
                      <span className="FilterIcon">
                        <img src={images.chevronDown} />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <a href="show_flights.html" className="ShowHotels">
                      <img src={images.airplaneWhite} /> Show Flights
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="FilterLeftArea">
                    <h4>Filters</h4>
                    <div className="LeftCommonBox">
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                        href="#Price"
                      >
                        <h6>Price</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="collapse show multi-collapse" id="Price">
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            <figure>
                              <img
                                src={images.priceRange}
                                className="w-100"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="LeftCommonBox">
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                        href="#DepartureTime"
                      >
                        <h6>Departure Time</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                      <div
                        className="collapse show multi-collapse"
                        id="DepartureTime"
                      >
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            <figure>
                              <img
                                src={images.priceRange}
                                className="w-100"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="LeftCommonBox">
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                        href="#ArrivalTime"
                      >
                        <h6>Arrival Time</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                      <div
                        className="collapse show multi-collapse"
                        id="ArrivalTime"
                      >
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            <figure>
                              <img
                                src={images.priceRange}
                                className="w-100"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="LeftCommonBox">
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                        href="#Airlines"
                      >
                        <h6>Airlines</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                      <div
                        className="collapse show multi-collapse"
                        id="Airlines"
                      >
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            <label className="CheckBox">
                              {" "}
                              Emirated
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Fly Dubai
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Qatar
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Etihad
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="LeftCommonBox">
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                        href="#Refundable"
                      >
                        <h6>Refundable</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                      <div
                        className="collapse show multi-collapse"
                        id="Refundable"
                      >
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            <label className="CheckBox">
                              {" "}
                              Refundable
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Non-Refundable
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="LeftCommonBox">
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                        href="#Stops"
                      >
                        <h6>Stops</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="collapse show multi-collapse" id="Stops">
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            <label className="CheckBox">
                              {" "}
                              0
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              1
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              +2
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="ChechBoxShow d-none">
                    <div className="CheckBoxStart">
                      <div className="CheckBoxStartTop">
                        <h5>Departure - Indigo</h5>
                        <h6>Flight Details</h6>
                      </div>
                      <div className="CheckBoxStartBottom">
                        <figure>
                          <img src="images/emirates_small_logo.png" />
                        </figure>
                        <div>
                          <h5>12:00 PM</h5>
                          <p>Del</p>
                        </div>
                        <figure className="Second">
                          {" "}
                          <img src="images/img_10.png" />{" "}
                        </figure>
                        <div>
                          <h5>12:00 PM</h5>
                          <p>Del</p>
                        </div>
                      </div>
                    </div>
                    <div className="CheckBoxStart">
                      <div className="CheckBoxStartTop">
                        <h5>Departure - Indigo</h5>
                        <h6>Flight Details</h6>
                      </div>
                      <div className="CheckBoxStartBottom">
                        <figure>
                          <img src="images/emirates_small_logo.png" />
                        </figure>
                        <div>
                          <h5>12:00 PM</h5>
                          <p>Del</p>
                        </div>
                        <figure className="Second">
                          <img src="images/img_10.png" />{" "}
                        </figure>
                        <div>
                          <h5>12:00 PM</h5>
                          <p>Del</p>
                        </div>
                      </div>
                    </div>
                    <div className="CheckBoxEnd">
                      <a href="" className="AddToCart">
                        Add to Cart
                      </a>
                      <h3>
                        INR3,000 <span>/Adult</span>{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="ShowingRightArea">
                    <div className="ShowingPlacesRecommend">
                      <h6>
                        Showing 4 of<span> 257 places</span>
                      </h6>
                      <h6>
                        Sort By
                        <select>
                          <option selected="">Recommended</option>
                        </select>
                      </h6>
                    </div>
                    <div className="BookNowBoxTop">
                      <h6>Delhi to Chandigarh</h6>
                      <p>22 Nov 2023</p>
                    </div>
                    <div className="BookNowBox">
                      <figure>
                        <img src={images.emiratesLogo} />
                      </figure>
                      <div className="BookNowBoxDetails">
                        <div className="BnbdTop">
                          <div className="BnbdTopSmall">
                            <p>IXC</p>
                            <h6>12:00 pm</h6>
                            <p>Emirates</p>
                          </div>
                          <div className="BnbdTopSmall NonStop">
                            <h6>non stop</h6>
                            <p>2h 28m</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p>DEL</p>
                            <h6>01:28 pm</h6>
                            <p>EWR-BNA</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p className="SeatLeft">
                              <span>
                                <img src={images.leftIcon} />
                              </span>{" "}
                              SeatLeft
                            </p>
                          </div>
                          <div className="BnbdTopSmall Economy">
                            <h6>Economy, Refundable</h6>
                            <a href="javascript:void(0);" className="BrandBtn">
                              Add to Cart
                            </a>
                          </div>
                        </div>
                        <div className="BnbdBottom">
                          <ul>
                            <li>
                              <span>
                                <img src={images.baggageIcon} />
                              </span>
                              7 Kg Cabin Baggage
                            </li>
                            <li>
                              <span>
                                <img src={images.checkBaggageIcon} />
                              </span>
                              15 kg 01 Check-in Baggage
                            </li>
                          </ul>
                          <h6>
                            <span>INR3,000 </span>/Adult
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <div className="BookNowBox">
                      <figure>
                        <img src="images/emirates_logo.png" />
                      </figure>
                      <div className="BookNowBoxDetails">
                        <div className="BnbdTop">
                          <div className="BnbdTopSmall">
                            <p>IXC</p>
                            <h6>12:00 pm</h6>
                            <p>Emirates</p>
                          </div>
                          <div className="BnbdTopSmall NonStop">
                            <h6>non stop</h6>
                            <p>2h 28m</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p>DEL</p>
                            <h6>01:28 pm</h6>
                            <p>EWR-BNA</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p className="SeatLeft">
                              <span>
                                <img src="images/left_icon.png" />
                              </span>{" "}
                              SeatLeft
                            </p>
                          </div>
                          <div className="BnbdTopSmall Economy">
                            <h6>Economy, Refundable</h6>
                            <a href="javascript:void(0);" className="BrandBtn">
                              Add to Cart
                            </a>
                          </div>
                        </div>
                        <div className="BnbdBottom">
                          <ul>
                            <li>
                              <span>
                                <img src="images/baggage_icon.png" />
                              </span>
                              7 Kg Cabin Baggage
                            </li>
                            <li>
                              <span>
                                <img src="images/check_baggage_icon.png" />
                              </span>
                              15 kg 01 Check-in Baggage
                            </li>
                          </ul>
                          <h6>
                            <span>INR3,000 </span>/Adult
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="BookNowBox">
                      <figure>
                        <img src="images/emirates_logo.png" />
                      </figure>
                      <div className="BookNowBoxDetails">
                        <div className="BnbdTop">
                          <div className="BnbdTopSmall">
                            <p>IXC</p>
                            <h6>12:00 pm</h6>
                            <p>Emirates</p>
                          </div>
                          <div className="BnbdTopSmall NonStop">
                            <h6>non stop</h6>
                            <p>2h 28m</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p>DEL</p>
                            <h6>01:28 pm</h6>
                            <p>EWR-BNA</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p className="SeatLeft">
                              <span>
                                <img src="images/left_icon.png" />
                              </span>{" "}
                              SeatLeft
                            </p>
                          </div>
                          <div className="BnbdTopSmall Economy">
                            <h6>Economy, Refundable</h6>
                            <a href="javascript:void(0);" className="BrandBtn">
                              Add to Cart
                            </a>
                          </div>
                        </div>
                        <div className="BnbdBottom">
                          <ul>
                            <li>
                              <span>
                                <img src="images/baggage_icon.png" />
                              </span>
                              7 Kg Cabin Baggage
                            </li>
                            <li>
                              <span>
                                <img src="images/check_baggage_icon.png" />
                              </span>
                              15 kg 01 Check-in Baggage
                            </li>
                          </ul>
                          <h6>
                            <span>INR3,000 </span>/Adult
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="BookNowBox">
                      <figure>
                        <img src="images/emirates_logo.png" />
                      </figure>
                      <div className="BookNowBoxDetails">
                        <div className="BnbdTop">
                          <div className="BnbdTopSmall">
                            <p>IXC</p>
                            <h6>12:00 pm</h6>
                            <p>Emirates</p>
                          </div>
                          <div className="BnbdTopSmall NonStop">
                            <h6>non stop</h6>
                            <p>2h 28m</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p>DEL</p>
                            <h6>01:28 pm</h6>
                            <p>EWR-BNA</p>
                          </div>
                          <div className="BnbdTopSmall">
                            <p className="SeatLeft">
                              <span>
                                <img src="images/left_icon.png" />
                              </span>{" "}
                              SeatLeft
                            </p>
                          </div>
                          <div className="BnbdTopSmall Economy">
                            <h6>Economy, Refundable</h6>
                            <a href="javascript:void(0);" className="BrandBtn">
                              Add to Cart
                            </a>
                          </div>
                        </div>
                        <div className="BnbdBottom">
                          <ul>
                            <li>
                              <span>
                                <img src="images/baggage_icon.png" />
                              </span>
                              7 Kg Cabin Baggage
                            </li>
                            <li>
                              <span>
                                <img src="images/check_baggage_icon.png" />
                              </span>
                              15 kg 01 Check-in Baggage
                            </li>
                          </ul>
                          <h6>
                            <span>INR3,000 </span>/Adult
                          </h6>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <a href="javascript:void(0);" className="ShowMore">
                        Show more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
};

export default Flights;
