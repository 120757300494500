import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { addMoneyApi, fetchtransactionList } from '../reduxToolKit/slices/payment/payment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Header2 from './Header2'


const initialState = {
    walletModal: false,
    walletAmount: 0,
    amount: 0,
}
const Wallet = () => {
    const [iState, updateState] = useState(initialState)
    const { walletModal, walletAmount, amount } = iState
    const walletList = useSelector((state) => state?.transactionData?.transactionData)
    console.log('wallet list', walletList?.data)
    const totalAmount = walletList?.data?.reduce((a, b) => {
        console.log(a, '====', b)
        return a + Number(b.balance)
    }, 0)
    console.log('total amount', totalAmount)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const openWalletModal = () => {
        updateState({

            ...iState,
            walletModal: true,
        })
    }
    const handleClose = () => {
        updateState({
            ...iState,
            walletModal: false,

        })
    }
    const handleAddMoney = () => {
        try {
            if (amount) {
                const data = {
                    amount: String(amount),
                }
                dispatch(addMoneyApi(data)).then((res) => {
                    console.log('res', res)
                    if (res?.payload) {
                        window.localStorage.setItem('orderId', res?.payload?.order_id)
                        updateState({
                            walletModal: false,
                            amount: 0
                        })
                        // navigate(res.payload.payment_links.web);
                        console.log('payment gate way url', res.payload.payment_links.web)
                        window.location.href = res?.payload?.payment_links?.web;

                    }
                })
            }
        } catch (error) {
            console.log('error', error)
        }

    }
    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }

    useEffect(() => {
        dispatch(fetchtransactionList())
    }, [])
    return (
        <div>
            <Header2 />
            <>
                <main id="main" className="ProductMain">
                    <div className="MarketingDetailsArea">
                        <div className="WalletCard">
                            <aside>
                                <h2>INR {totalAmount}</h2>
                                <p>Amount</p>
                            </aside>
                            <a onClick={openWalletModal} className="AddMoneyBtn">
                                Add Money
                            </a>
                        </div>
                        <a className="AddMoneyBtn">
                            <div className="TableHeader">
                                <h3>Transaction</h3>
                            </div>
                            <ul className="TransactionTable">
                                {
                                    walletList?.data?.length > 0 ? walletList?.data?.map((item) => {
                                        return (<li>
                                            <aside>
                                                <h2></h2>
                                                <h5>Inr {item?.balance}</h5>
                                            </aside>
                                            <aside>
                                                <p>Txn ID {item?.transId}, {moment(item?.createdAt).format('DD/MM/YYYY hh:mm a')}</p>
                                                <p>Order Id {item?.orderId}</p>
                                            </aside>
                                        </li>)
                                    }) : "No Data Found"
                                }
                            </ul>
                        </a>
                    </div>
                    <a className="AddMoneyBtn"></a>
                </main>
                <a className="AddMoneyBtn"></a>
            </>

            <Modal
                show={walletModal}
                className="ModalBox ModalBoxAdd  modalBoxNew"
                centered>
                <Modal.Body
                >
                    <a onClick={handleClose} className='CloseModal'>X</a>

                    <div className='Category p-4'>
                        <h3>Add Money</h3>
                        <div className='form-group'>
                            <label>Amount</label>
                            <input type='number' name='amount' value={amount} onChange={handleChange} className='form-control' />
                        </div>
                        <button className='AddMoneyBtnNew' onClick={handleAddMoney}>Add Money</button>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Wallet