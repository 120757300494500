import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";





export const fetchDestination = createAsyncThunk('destinationList/fetchDestinationList', async (payload) => {
    const response = await axios.get(`${privateData["proxy-url"]}/hotel-content-api/1.0/locations/destinations?countryCodes=${payload.country}`, {
        headers: {
            "Api-key": privateData["Api-key"],
            "X-Signature": xSignature,
        }
    })
    console.log('response in destination', response)
    return response.data
})


const initialState = {
    destinationList: [],
    status: "idle",
    error: null,
}

const destinationSlice = createSlice({
    name: "destinationList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDestination.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchDestination.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.destinationList = action.payload;
            })
            .addCase(fetchDestination.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default destinationSlice.reducer;



