import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { images } from "../utils/images";
import { useDispatch } from "react-redux";
import { fetchTransferList } from "../reduxToolKit/slices/GetTransferListApiSlice";
import { useLocation } from "react-router-dom";

const Transfer = () => {
  const dispatch = useDispatch()
  const {state} = useLocation()
  console.log('state in transfer', state)
  useEffect(()=>{
    const data = {
      date : state?.[0],
      adults : state?.[1],
      children : state?.[2]
    } 
    dispatch(fetchTransferList({data}))
  },[])
  return (
    <div>
      <Header/>
      <section>
        <div className="FlightListingArea">
          <div className="TransferFilter">
            <div className="TopMainBox">
              <div className="FlightListingRadios ml-4">
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    One Way
                    <input
                      type="radio"
                      name="OneWay1"
                      defaultValue="file"
                      defaultChecked=""
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Round Trip
                    <input type="radio" name="OneWay1" defaultValue="text" />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="file RadioText" style={{ display: "block" }}>
                <div className="Filter">
                  <div className="form-group">
                    <div className="CommonInputBox First Label">
                      <span className="CibLeftOverlay">
                        <img src={images.hotelImg} />
                      </span>
                      <h6>From</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="From"
                      />
                      <span
                        className="CibRightOverlay"
                        data-toggle="modal"
                        data-target="#CountryModal"
                      >
                        <img src={images.barIcon} />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="CommonInputBox First Label">
                      <span className="CibLeftOverlay">
                        <img src={images.hotelImg}/>
                      </span>
                      <h6>To</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="To"
                      />
                      <span
                        className="CibRightOverlay"
                        data-toggle="modal"
                        data-target="#CountryModal"
                      >
                        <img src={images.barIcon} />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="InputBox">
                      <h6>Departure</h6>
                      <input type="date" className="form-control-date" />
                      <span className="FilterIcon">
                        <img src={images.calendarBlueImg} />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="InputBox SelectBox">
                      <span className="StaffIcon">
                        <img src={images.userIcon} />
                      </span>
                      <h6>Guests</h6>
                      <select>
                        <option>select</option>
                        <option>2 Guests</option>
                        <option>3 Guests</option>
                        <option>4 Guests</option>
                        <option>5 Guests</option>
                      </select>
                      <span className="FilterIcon">
                        <img src={images.chevronDown}/>
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <a href="javascript:void(0);" className="SearchBtn w-100">
                      <img src={images.show_transfer}/>
                      Show Transfer
                    </a>
                  </div>
                </div>
              </div>
              {/*    */}
            </div>
          </div>
          <div className="TravelArea p-0">
            <div className="">
              <div className="TravelHead"></div>
              <div className="TravelBody">
                <span className="Icon">
                  <img src={images.Safety} />{" "}
                </span>
                <button data-toggle="modal" data-target="#DetailsModal">
                  View Details
                </button>
                <h6>7 Points for Travel Safety.</h6>
                <p>
                  We collaborate closely with our Transfer Suppliers to ensure
                  the well-being of our shared customers.
                </p>
              </div>
              <div className="TravelFooter">
                <div className="TravelBox">
                  <div className="TravelLeft">
                    <img src={images.TravelCar} />
                  </div>
                  <div className="TravelRight">
                    <article>
                      <aside>
                        <h3>
                          Private <span>Standard Car</span>{" "}
                        </h3>
                        <p>
                          <span>
                            <img src={images.Door} /> Door to Door Service
                          </span>
                        </p>
                        <ol>
                          <li>
                            <span className="Icon">
                              <img src={images.Clock} />
                            </span>
                            Max. supplier waiting time (Domestic){" "}
                            <span>60 minutes</span>
                          </li>
                          <li>
                            <span className="Icon">
                              <img src={images.Clock} />
                            </span>
                            Max. supplier waiting time (International){" "}
                            <span>60 minutes</span>
                          </li>
                        </ol>
                      </aside>
                      <aside>
                        <ul>
                          <li>
                            <span>
                              <img src={images.Check} />
                            </span>{" "}
                            Exclusive ride for you
                          </li>
                          <li>
                            <span>
                              <img src={images.Check} />
                            </span>{" "}
                            1 piece of baggage allowed per person (
                            max.dimensions 158cm) length+width+height=158cm
                          </li>
                          <li>
                            <span>
                              <img src={images.Check} />
                            </span>{" "}
                            1 item of hand baggage allowed per person
                          </li>
                        </ul>
                      </aside>
                    </article>
                    <figcaption>
                      <button
                        className="View"
                        data-toggle="modal"
                        data-target="#DetailsModal"
                      >
                        View Details
                      </button>
                      <aside>
                        <h4>
                          INR 3,483.64 <span>/ month Excl. tax</span>{" "}
                        </h4>
                        <button
                          data-toggle="modal"
                          data-target="#DepartureModal"
                        >
                          Select
                        </button>
                      </aside>
                    </figcaption>
                  </div>
                </div>
                <div className="TravelBox">
                  <div className="TravelLeft">
                    <img src={images.TravelCar} />
                  </div>
                  <div className="TravelRight">
                    <article>
                      <aside>
                        <h3>
                          Private <span>Standard Car</span>{" "}
                        </h3>
                        <p>
                          <span>
                            <img src={images.Door} /> Door to Door Service
                          </span>
                        </p>
                        <ol>
                          <li>
                            <span className="Icon">
                              <img src={images.Clock} />
                            </span>
                            Max. supplier waiting time (Domestic){" "}
                            <span>60 minutes</span>
                          </li>
                          <li>
                            <span className="Icon">
                              <img src={images.Clock} />
                            </span>
                            Max. supplier waiting time (International){" "}
                            <span>60 minutes</span>
                          </li>
                        </ol>
                      </aside>
                      <aside>
                        <ul>
                          <li>
                            <span>
                              <img src={images.Check} />
                            </span>{" "}
                            Exclusive ride for you
                          </li>
                          <li>
                            <span>
                              <img src={images.Check} />
                            </span>{" "}
                            1 piece of baggage allowed per person (
                            max.dimensions 158cm) length+width+height=158cm
                          </li>
                          <li>
                            <span>
                              <img src={images.Check} />
                            </span>{" "}
                            1 item of hand baggage allowed per person
                          </li>
                        </ul>
                      </aside>
                    </article>
                    <figcaption>
                      <button
                        className="View"
                        data-toggle="modal"
                        data-target="#DetailsModal"
                      >
                        View Details
                      </button>
                      <aside>
                        <h4>
                          INR 3,483.64 <span>/ month Excl. tax</span>{" "}
                        </h4>
                        <button
                          data-toggle="modal"
                          data-target="#DepartureModal"
                        >
                          Select
                        </button>
                      </aside>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default Transfer;
