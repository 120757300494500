import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { images } from "../utils/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHotelList } from "../reduxToolKit/slices/hotelListApiSlice";
import Header2 from "./Header2";
import { Accordion } from "react-bootstrap";
import { fetchRooms } from "../reduxToolKit/slices/roomTypeApiSlice";
import { fetchAccommodations } from "../reduxToolKit/slices/accomodationApiSlice";
import { fetchBoards } from "../reduxToolKit/slices/boardApiSlice";
import { toast } from "react-toastify";
import Loader from './Loader'
import CustomHotelCard from "../CustomComponent/CustomHotelCard";
import CommonHotelSearch from "../CustomComponent/CommonHotelSearch";


const initialState = {
  country: "",
  destination: "",
  rating: "",
  minRate: "",
  maxPrice: "",
  boards: [],
  accommodations: [],
  rooms: [],
  startDate: "",
  endDate: "",
  roomsDetail: [{
    rooms: 1,
    adults: 2, children: '',
    paxes: [{
      type: "CH",
      age: "1"
    }]
  }],
  checkIn: "",
  checkOut: "",
  totalGuest: 0,
  lat: "",
  lng: "",
  reset: ""
}

function HotelList() {
  const [iState, updateState] = useState(initialState)
  const [showRoom, setShowRoom] = useState(false)
  const { rating, minRate, maxPrice, boards, accommodations, rooms, startDate, endDate, roomsDetail, checkIn, checkOut, totalGuest, lat, lng, reset } = iState

  //===============================================================
  const dispatch = useDispatch()
  const { status, hotelLists } = useSelector((state) => state?.hotelList);
  const boardslist = useSelector((state) => state?.boardsList?.boards)
  const accommodationsList = useSelector((state) => state?.accomodationList.accommodations.accommodations)
  const roomList = useSelector((state) => state?.roomsList?.rooms?.rooms)
  const { state } = useLocation()
  console.log('state in hotel list', state)
  console.log('iState', iState)

  const handleChange1 = (e, index, type) => {
    const { name, value, checked } = e.target
    if (type === 'rating') {
      if (checked) {
        updateState({
          ...iState,
          [name]: value
        })
      } else {
        updateState({
          ...iState,
          [name]: ""
        })
      }
    } else if (type === 'board') {
      if (checked) {
        updateState({
          ...iState,
          boards: [...boards, value]
        })
      } else {
        let newBoardList = []
        for (let i = 0; i < boards?.length; i++) {
          if (boards[i] !== value) {
            newBoardList.push(boards[i])
          }
        }
        updateState({
          ...iState,
          boards: newBoardList
        })
      }
    } else if (type === 'accommodation') {
      if (checked) {
        updateState({
          ...iState,
          accommodations: [...accommodations, value]
        })
      } else {
        let newAccommodationList = []
        for (let i = 0; i < accommodations?.length; i++) {
          if (accommodations[i] !== value) {
            newAccommodationList.push(accommodations[i])
          }
        }
        updateState({
          ...iState,
          accommodations: newAccommodationList
        })
      }
    } else if (type === 'roomtype') {
      if (checked) {
        updateState({
          ...iState,
          rooms: [...rooms, value]
        })
      } else {
        let newRoomList = []
        for (let i = 0; i < rooms?.length; i++) {
          if (rooms[i] !== value) {
            newRoomList.push(rooms[i])
          }
        }
        updateState({
          ...iState,
          rooms: newRoomList
        })
      }
    } else {
      updateState({
        ...iState,
        [name]: value
      })
    }
  }

  const handleShowHotel = () => {
    const data = {
      "stay": {
        "checkIn": checkIn,
        "checkOut": checkOut
      },
      "occupancies": roomsDetail?.map((item, index) => {
        return item.children === "" ? { rooms: index + 1, adults: item.adults, children: 0 } : { ...item };
      }),
      "geolocation": {
        // "latitude": loclatlng?.split('*')[0],
        // "longitude": loclatlng?.split('*')[1],
        "latitude": lat,//51.5072
        "longitude": lng,//0.1276
        "radius": 20,
        "unit": "km"
      },
      "reviews": [
        {
          "type": "HOTELBEDS",
          "maxRate": 5,
          "minRate": Number(rating),
          // "minReviewCount": 3
        }
      ],
      "boards": {
        "included": true,
        "board": boards
      },
      "accommodations": accommodations,
      "rooms": {
        "included": true,
        "room": rooms
      },
      "filter": {
        minRate: 100,
        maxRate: maxPrice ?? ''
      }
    }
    dispatch(fetchHotelList(data))?.then((res) => {
      // console.log('Response of hotel list api from hotel bed', res)
      if (res?.payload?.hotels?.hotels?.length > 0) {
        toast.success('Hotels Found Sucessfully', {
          position: 'top-right',
          autoClose: 1000
        })
        // navigate('/hotelList', {
        //   state: {
        //     checkIn,
        //     checkOut,
        //     roomsDetail
        //   }
        // })
      } else {
        toast.error('Hotel not available', {
          position: 'top-right',
          autoClose: 1000
        })
      }
    })
  }

  useEffect(() => {
    dispatch(fetchRooms())
    dispatch(fetchAccommodations())
    dispatch(fetchBoards())//[...data]
    console.log('State', state?.checkIn, state?.checkOut, state?.lat, state?.lng)
    updateState((prev) => {
      console.log('in useEffect of hotel list', prev?.checkIn)
      return {
        ...prev,
        checkIn: state?.checkIn,
        checkOut: state?.checkOut,
        lat: state?.lat,
        lng: state?.lng
      }
    }, [])
    console.log('iState', iState)
  }, [state]);//country

  // updateState({
  //   ...iState,
  //   checkIn: state?.checkIn,
  //   checkOut: state?.checkOut,
  //   lat: state?.lat,
  //   lng: state?.lng
  // }, [])

  console.log('iState in hotel list', iState)

  // useEffect(() => {
  //   const totalGuest = roomsDetail?.reduce((acc, curr) => acc + curr.adults ?? 0, 0)
  //   updateState({
  //     ...iState,
  //     totalGuest: totalGuest
  //   })
  // }, [roomsDetail])
  // code for total Guest re consider in case of further requirement

  const resetFilter = () => {
    updateState({
      ...iState,
      rating: '',// look for array implementi
      boards: [],
      accommodations: [],
      rooms: [],
      reset: "hit"
    })
  }

  // const getSearchFeed = () => {
  //   console.log('Function executed sucessfully')
  // }
  return (
    <div>
      <Header2 />
      <main id="main" className="ProductMain FlightListingArea">
        <section>
          <div className="ProductFilter mt-5 mt-5" >
            <div className="TopMainBox mt-0">
              <div
                className="tab-content"
              >
                <div className="tab-pane fade show active">
                  <div className="d-flex align-items-center pt-2">
                    <h5>Enter City, Area or Hotel Name</h5>
                  </div>
                  <CommonHotelSearch stateData={{ iState, updateState, state }} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="FilterLeftArea">
                <h4>Filters</h4>
                <Accordion alwaysOpen>
                  <Accordion.Item eventKey="1" className="LeftCommonBox">
                    <Accordion.Header>
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                      >
                        <h6>Board</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="collapse show multi-collapse" id="Board">
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            {
                              boardslist?.boards?.length > 0 ? boardslist?.boards?.slice(1)?.map((item, i) => {
                                return (
                                  <label className="CheckBox">
                                    {" "}
                                    {item?.description?.content}
                                    <input type="checkbox" name="board" checked={boards?.includes(item?.code)} value={item?.code} onClick={(e) => handleChange1(e, i, 'board')} />
                                    <span className="checkmark" />
                                  </label>
                                )
                              }) : ""
                            }

                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="LeftCommonBox">
                    <Accordion.Header>
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                      // href="#Rating"
                      >
                        <h6>Rating</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="collapse show multi-collapse" id="Rating">
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            <div className="RatingCheckboxs">
                              <ul>
                                <li>
                                  <input type="checkbox" name="rating" value='1' onClick={(e) => handleChange1(e, '', 'rating')} />
                                  <aside>
                                    <label >1+</label>
                                  </aside>
                                </li>
                                <li>
                                  <input type="checkbox" name="rating" value='2' onClick={(e) => handleChange1(e, '', 'rating')} />
                                  <aside>
                                    <label >2+</label>
                                  </aside>
                                </li>
                                <li>
                                  <input type="checkbox" name="rating" value='3' onClick={(e) => handleChange1(e, '', 'rating')} />
                                  <aside>
                                    <label >3+</label>
                                  </aside>
                                </li>
                                <li>
                                  <input type="checkbox" name="rating" value='4' onClick={(e) => handleChange1(e, '', 'rating')} />
                                  <aside>
                                    <label >4+</label>
                                  </aside>
                                </li>
                                <li style={{ marginTop: '10px' }}>
                                  <input type="checkbox" name="rating" value='5' onClick={(e) => handleChange1(e, '', 'rating')} />
                                  <aside>
                                    <label >5</label>
                                  </aside>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9" className="LeftCommonBox">
                    <Accordion.Header>
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"

                      >
                        <h6>Accommodation Type</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        className="collapse show multi-collapse"
                        id="AccommodationType"
                      >
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            {
                              accommodationsList?.length > 0 ? accommodationsList?.map((item) => {
                                return (
                                  <label className="CheckBox">
                                    {" "}
                                    {
                                      item?.typeDescription
                                    }
                                    <input type="checkbox" name="accommodation" value={item?.code} checked={accommodations?.includes(item?.code)} onChange={(e) => handleChange1(e, "", 'accommodation')} />
                                    <span className="checkmark" />
                                  </label>
                                )
                              }) : ""
                            }
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="10" className="LeftCommonBox">
                    <Accordion.Header>
                      <div
                        className="LeftCommonHeading"
                        data-toggle="collapse"
                      // href="#VacationRental"
                      >
                        <h6>Room Types</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        className="collapse show multi-collapse"
                        id="VacationRental"
                      >
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            {
                              roomList?.length > 0 ? roomList?.map((item) => {
                                return (
                                  <label className="CheckBox">
                                    {" "}
                                    {
                                      item?.description
                                    }
                                    <input type="checkbox" name="roomtype" value={item?.code} onChange={(e) => handleChange1(e, "", "roomtype")} />
                                    <span className="checkmark" />
                                  </label>
                                )
                              }) : ""
                            }
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  <Accordion.Item eventKey="15" className="LeftCommonBox">
                    <Accordion.Header>
                      <div
                        className="LeftCommonHeading"
                      >
                        <h6>Max Price</h6>
                        <span>
                          <i className="fa fa-angle-up" aria-hidden="true" />
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="collapse show multi-collapse" id="Price">
                        <div className="card card-body">
                          <div className="LeftCommonContent">
                            {/* <figure>
                              <img
                                src={images?.priceRange}
                                className="w-100"
                              />
                            </figure> */}
                            <input type="number" readOnly="true" value={maxPrice} />
                            <input
                              type="range" min="100" max="1000" className="w-100" name="maxPrice" onChange={handleChange1} />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="form-group">
                  <a
                    className="ShowHotels ApplyBtn"
                    to="/hotelList"
                    onClick={handleShowHotel}// it is for filter
                    style={{ border: '2px solid red' }}
                  >
                    <img src={images?.showHotel} /> Apply-1
                  </a>
                </div>
                <div className="form-group">
                  <a
                    className="ShowHotels ApplyBtn"
                    to="/hotelList"
                    onClick={resetFilter}
                    style={{ background: 'black' }}
                  >
                    <img src={images?.showHotel} /> Reset
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="ShowingRightArea">
                <div className="ShowingPlacesRecommend">
                </div>
                {
                  status
                    ?
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                      }}
                    >
                      <Loader />
                      {/* <LoadingSpinner color={"#fac6cf"} size={30} /> */}
                    </div>

                    : hotelLists?.hotels?.hotels?.length > 0
                      ? hotelLists?.hotels?.hotels?.map((item) => (
                        <CustomHotelCard
                          cardData={item}
                          checkIn={checkIn}
                          checkOut={checkOut}
                          totalGuest={roomsDetail}
                        />
                      ))
                      : "No Data found"
                }
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default HotelList;
