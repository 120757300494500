import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";
import signature from "../../config/signature";

export const fetchBoards = createAsyncThunk('boards/fetchboards', async (payload) => {
    console.log('Board code', payload)
    const response = await axios.get(`${privateData["proxy-url"]}/hotel-content-api/1.0/types/boards?codes=${["CB", "RO", "HB", "SC", "CO", "CE", "BB"]}`, {
        headers: {
            "Api-key": privateData["Api-key"],
            "X-Signature": signature(),
        }
    });
    return response.data;
});

const boardsSlice = createSlice({
    name: 'boards',
    initialState: {
        boards: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBoards.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBoards.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.boards = action.payload;
            })
            .addCase(fetchBoards.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default boardsSlice.reducer;
