import React, { useEffect, useState } from "react";
import "../App.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ColorRing } from "react-loader-spinner";

import Header from "./Header";
import Footer from "./Footer";
import { privateData } from "../config/apiKey";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import { useCountUp } from 'react-countup';
//================ IMPORT OF IMAGES ==============================//
import bannerVideo from "../assests/video/bannerVideo.mp4";
import { images } from "../utils/images";
import axios from "axios";
import Header2 from "./Header2";
import { isLoggedIn } from "../utils/LoggedIn";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocationList } from "../reduxToolKit/slices/locationListApiSlice";
import { fetchHotelList } from "../reduxToolKit/slices/hotelListApiSlice";
import { fetchPartnerList } from "../reduxToolKit/slices/partnerListApiSlice";
import SwtMap from "./SwtMap";
import { fetchProudPartnerList } from "../reduxToolKit/slices/proudPartnerApiSlice";
import { toast } from "react-toastify";
import { fetchStaticContent } from "../reduxToolKit/slices/staticContentApiSlice";
import Loader from "./Loader";
import moment from "moment/moment";
import { packageApi } from "../reduxToolKit/slices/packageApiSlice";
import CommonHotelSearch from "../CustomComponent/CommonHotelSearch";
//=======================================================
const initialState = {
  showSearchModal: false,
  cityName: "",
  checkIn: "",
  checkOut: "",
  rooms: "",
  guests: "",
  roomsDetail: [{
    rooms: 1,
    adults: 2, children: '',
    paxes: [{
      type: "CH",
      age: "1"
    }]
  }],
  continent: "",
  location: "",
  lat: "",
  lng: "",
  hotelName: "",
  errors: "",
  packageCityName: "",
  packageStartDate: "",
  packageEndDate: "",

  numberOfGuests: "",
  hotelCode: "",
  transferDepartureDate: "",

  errorMsg: {}
};
const Index = () => {
  const [iState, updateState] = useState(initialState);
  const { cityName, checkIn, checkOut, rooms, guests, roomsDetail, showSearchModal, continent, location, lat, lng, hotelName, errors, packageCityName, packageStartDate, numberOfGuests, hotelCode, transferDepartureDate, packageEndDate, errorMsg } = iState;
  const [showHeader, setShowHeader] = useState(false)
  const [showHotelModal, setShowHotelModel] = useState(false);
  const [numberOfRooms, setNumberOfRooms] = useState(1)
  const [ShowAddChildAndAdult, setShowAddChildAndAdult] = useState(false)
  const [transferAdults, setTransferAdults] = useState(0)
  const [transferChildren, setTransferChildren] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const hotelList = useSelector((state) => state?.hotelLists)
  const locationList = useSelector((state) => state?.locationList?.locationList?.data?.result)
  const status = useSelector((state) => state.locationList)

  const partners = useSelector((state) => state?.partnerList.partnerList.data)
  const proudPartner = useSelector((state) => state?.proudPartenrList.ProudPartnerList.data)

  const [confirmModal, setConfirm] = useState(false);
  const handleConfirmModalClose = () => {


    setConfirm(false);
  };
  console.log('Proud partner', proudPartner)
  //==========show Room================
  const [showRoom, setShowRoom] = useState(false)
  //const [roomsArray, setRoomsArray] = useState([])
  // let roomsArray = []
  const childAge = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]// Add validation for rooms

  const MaxAdults = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const closeHotelModal = () => {
    console.log("showHotelModal", showHotelModal);
    setShowHotelModel(false);
  };

  const handleRoom = () => {
    setShowRoom(!showRoom)
  }

  const handleNumberOfRoomsAdd = () => {
    updateState({
      ...iState,
      roomsDetail: [...roomsDetail, { rooms: roomsDetail?.length + 1, adults: 2, children: '' }]
    })
  }

  const handleChange = (e, i, type, ageindex) => {
    // console.log(e, i, type, ageindex)
    const { name, value } = e.target
    console.log('name', name, 'type', type, 'value', value)
    if (type === 'remove') {
      console.log("roomsDetail", roomsDetail, 'item to be removed', i)
      const removedItemArray = []
      for (let j = 0; j < roomsDetail.length; j++) {
        if (j !== i) {
          removedItemArray.push(roomsDetail[j])
        }
      }
      console.log("removedItemArray", removedItemArray)
      updateState({
        ...iState,
        roomsDetail: removedItemArray
      })
    } else {
      if (name === 'children' && value <= 4) {
        const user = [...roomsDetail];
        user[i] = { ...user[i], [name]: value };

        let updatedPaxes = []
        for (let i = 0; i < value; i++) {
          updatedPaxes.push({ type: 'CH', age: '1' })
        }

        console.log('index of object', i, 'value', value)
        console.log("updatedPaxes", updatedPaxes)

        updateState((prev) => ({
          ...prev,
          roomsDetail: user.map((room, index) => (index === i ? { ...room, paxes: updatedPaxes } : room)),
        }));
        console.log('istate', iState)
      }
      if (name === 'adults' && value <= 8) {
        const user = [...roomsDetail]
        user[i] = { ...user[i], [name]: value }
        console.log('Add room functionality', e, "**********", i, '***********', type, name)
        updateState((prev) => ({ ...prev, roomsDetail: user }))
        console.log(user)
      }
      if (type === 'childAge') {
        console.log('name', name, 'value', value, 'type', type)
        const user = [...roomsDetail]
        for (let j = 0; j <= i; j++) {
          console.log('i and j', i, j)
          if (j === i) {
            // [ user[j].paxes[ageindex]] = {type:'CH',[name]:value}  [ user[j].paxes[ageindex]] = {type:'CH',[name]:value}
            console.log('age of child at index x', user[j].paxes[ageindex].age = value)
          }
        }
        // user[i] = { ...user[i], [user[i].paxes[ageindex]]:[[name]:value] }
        console.log(' user ', user)
        updateState((prev) => ({ ...prev, roomsDetail: user }))
        // console.log('roomsDetail', roomsDetail)
      }
    }

  }

  console.log('rooms detail zero', roomsDetail?.[0])

  const handleChange2 = (e, type, item) => {
    if (e.keyCode == 8) {
      // prevent default behavior
      e.preventDefault();

      updateState({
        ...iState,
        location: hotelName
      })
    }

    const { name, value } = e.target
    updateState({
      ...iState,
      [name]: value
    })
  }

  const handleChange3 = (e, type, item) => {
    console.log('handle change 3', item)
    updateState({
      ...iState,
      lat: item.Lat,
      lng: item.Long,
      hotelName: item.Hotel_Name,
      hotelCode: item?.HBD_Hotel_ID
      ,
      location: type == "location" ? item.Destination_Name : item.Hotel_Name
    })
    console.log('hotel code', iState)
  }

  const handleChangePackage = (e) => {
    const { name, value } = e.target
    updateState({
      ...iState,
      [name]: value
    })
  }
  console.log('iState', iState)
  // const handleValidation =()=>{
  //   let error = {};
  //   let formIsValid = true;

  //   if (!countyId.trim()) {
  //     error.countyIdError = " * County can't be empty";
  //     formIsValid = false;
  //   }

  //   if (!zone.trim()) {
  //     error.zoneError = " * zone Code can't be empty";
  //     formIsValid = false;
  //   }

  //   updateState({
  //     ...iState,
  //     errors: error,
  //   });
  //   return formIsValid;
  // }

  const handleShowHotel = () => {
    const data = {
      "stay": {
        "checkIn": checkIn,
        "checkOut": checkOut
      },
      "occupancies": roomsDetail.map((item, index) => {
        return item.children === "" ? { rooms: index + 1, adults: item.adults, children: 0 } : { ...item };
      }),
      "geolocation": {
        // "latitude": loclatlng?.split('*')[0],
        "latitude": lat,
        //"longitude": loclatlng?.split('*')[1],
        "longitude": lng,
        "radius": 20,
        "unit": "km"
      },
      // "hotels": {
      //   "hotel": [
      //     hotelCode
      //   ]
      // },
      "reviews": [
        {
          "type": "HOTELBEDS",
          "maxRate": 5,
          "minRate": 1,
          // "minReviewCount": 3
        }
      ],
    }
    dispatch(fetchHotelList(data))?.then((res) => {
      console.log('Response of hotel list api from hotel bed', res)
      if (res?.payload?.hotels?.hotels?.length > 0) {
        toast.success('Hotels Found Sucessfully', {
          position: 'top-right',
          autoClose: 1000
        })
        navigate('/hotelList', {
          state: {
            checkIn,
            checkOut,
            roomsDetail
          }
        })
      } else {
        toast.error('Hotel not available', {
          position: 'top-right',
          autoClose: 1000
        })
      }
    })
  }

  const handleSearchByZone = () => {
    updateState({
      ...iState,
      showSearchModal: true
    })
  }

  const handleCloseModal = () => {
    updateState({
      ...iState,
      showSearchModal: false
    })
  }

  const roomsapply = () => {
    setShowRoom(false)
  }

  const totalAdults = () => {
    let totalAdults = 0;
    let totalChildren = 0;

    for (let i = 0; i < roomsDetail.length; i++) {
      totalAdults += roomsDetail[i]?.adults === "" ? 0 : Number(roomsDetail[i]?.adults);
      totalChildren += roomsDetail[i]?.child === "" ? 0 : Number(roomsDetail[i]?.children);
    }

    // if (totalChildren > 0) {
    //   ;
    // } else {
    //   return `${totalAdults} - Adults`;
    // }
    return `Adults - ${totalAdults}  Children - ${totalChildren}`
  }

  const addTransferAdults = () => {
    setTransferAdults((prev) => prev + 1)
  }

  const removeTransferAdults = () => {
    if (transferAdults > 0) {
      setTransferAdults((prev) => prev - 1)
    }
  }
  //setTransferChildren
  const addTransferChilren = () => {
    setTransferChildren((prev) => prev + 1)
  }

  const removeTransferChildren = () => {
    if (transferChildren > 0) { setTransferChildren((prev) => prev - 1) }
  }

  const showChildrenAndAdultsAdd = () => {
    setShowAddChildAndAdult((prev) => !prev)
  }

  console.log('total adults', totalAdults())
  useEffect(() => {
    dispatch(fetchPartnerList())
    dispatch(fetchProudPartnerList())
    dispatch(fetchStaticContent())
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, [])

  useEffect(() => {
    if (location) {
      const getData = setTimeout(() => {
        dispatch(fetchLocationList({ data: location }))
      }, 2000);
      return () => { clearTimeout(getData) }
    }
  }, [location])

  useEffect(() => {
    AOS.init();
  }, [])



  useCountUp({ ref: 'HotelCounter', end: 300 });
  useCountUp({ ref: 'CountriesCounterr', end: 200 });
  useCountUp({ ref: 'TravelDistributors', end: 71 });
  useCountUp({ ref: 'SourceMarket', end: 150 });
  useCountUp({ ref: 'TransferRoute', end: 24 });
  useCountUp({ ref: 'ActivitiesTickets', end: 18 });
  useCountUp({ ref: 'CarRentalSupplier', end: 5 });





  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};


    if (!packageCityName) {
      flag = false;
      errorMsg.packageCityName = " Destination field is required";
    }

    if (!packageStartDate) {
      flag = false;
      errorMsg.packageStartDate = " Start date field is required";
    }

    if (!packageEndDate) {
      flag = false;
      errorMsg.packageEndDate = "End date field is required";
    }
    if (!numberOfGuests) {
      flag = false;
      errorMsg.numberOfGuests = "No. of guests is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };
  const handleSubmit = () => {

    let formIsValid = handleValidation();

    if (formIsValid) {

      setConfirm(true)



      dispatch(packageApi({ search: packageCityName, startDate: packageStartDate, endDate: packageEndDate })).then((res) => {



        if (res.payload.data.total > 0) {
          setConfirm(false)

          navigate('/packages', { state: [packageCityName, packageStartDate, numberOfGuests] })

        } else {
          toast.error("No package found", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          })

        }

      })

    }



  }




  let destination = locationList?.slice(0, 10)?.filter((item, index, self) =>
    index === self.findIndex((t) => t.Destination_Name === item.Destination_Name)
  );
  console.log(status.status, 'DDDDDDDDDDDDDDd')
  return (
    <>
      <div>
        <div className="TransBackground">
          <Header2 />
        </div>
        {/* {
        hotelList?.status ? <Loader width='200px' height="200px" /> : ""
      } */}
        <main id="main">
          <section className="hero" id="hero">
            <video autoPlay="true" muted="true" loop="true" width="100%">
              <source src={bannerVideo} type="video/mp4" />
            </video>

            <div
              // className="hero-content animate fadeInUp"
              // data-animate="fadeInUp"
              // data-duration="1.5s"
              // data-delay="0.3s"
              className="hero-content"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1>
                Book Travel Boldly With SWT CLUB <br />
                <span>A Trusted Global B2B travel platform</span>
              </h1>
            </div>
            {
              isLoggedIn("swtadmin") ? "" : <Link to="/signup" className="SignUpBtn">
                Sign Up
              </Link>
            }

          </section>
          <section>
            <div className="OurPartnersArea ConnectWorldArea">
              <div className="ConnectTabsArea">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="FindHotels"
                >
                  <div className="CommonTabs">
                    <Nav>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="FindHotels"
                        //  as={Link}
                        //   to="/payment-management/Payout"
                        >
                          <img src={images.country_icon} />{" "}
                          Find Hotels
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                      <Nav.Link
                        eventKey="FindFlight"
                      //   as={Link}
                      //   to="/payment-management/Transactions"
                      >
                        <img src={images.flightIcon} />{" "}
                        {" "}
                        Find Flight
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Packages"
                      //  as={Link}
                      //   to="/payment-management/Payout"
                      >
                        <img src={images.packageIcon} />{" "}
                        Package
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="FindActivities">
                        <img src={images.destination} />{" "}
                        Find Activities
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="FindTransfers"
                      //  as={Link}
                      //   to="/payment-management/Payout"
                      >
                        <img src={images.showTransferBlue} />{" "}
                        Find Transfers
                      </Nav.Link>
                    </Nav.Item> */}
                      {/* <Nav.Item>
                        <Nav.Link eventKey="FindFlight">
                          <img src={images.flightIcon} /> Find Flight
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="Packages">
                          <img src={images.packageIcon} /> Package
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="FindTransfers">
                          <img src={images.showTransferBlue} /> Find Transfers
                        </Nav.Link>
                      </Nav.Item> */}

                    </Nav>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="FindHotels">
                      <div
                        className="tab-content"
                      >
                        <div className="tab-pane fade show active">
                          <div className="d-flex align-items-center pt-2">
                            <h5>Enter City, Area or Hotel Name</h5>
                          </div>
                          <CommonHotelSearch />
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FindFlight">
                      <div className="tab-content">
                        <div className="d-flex align-items-center pt-2">
                          <h5>Book Your Flight</h5>
                          <div className="FlightListingRadios ml-4">
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                One Way
                                <input
                                  type="radio"
                                  name="OneWay2"
                                  defaultValue="text"
                                  defaultChecked=""
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Round Trip
                                <input type="radio" name="OneWay2" defaultValue="file" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text RadioText" style={{ display: "block" }}>
                          <div className="Filter">
                            <div className="form-group">
                              <div className="CommonInputBox">
                                <span className="CibLeftOverlay">
                                  <img src={images.flightIcon} />
                                </span>
                                <h6>From</h6>
                                <input type="text" className="form-control" placeholder="From" />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="CommonInputBox">
                                <span className="CibLeftOverlay">
                                  <img src={images.flightIcon} />
                                </span>
                                <h6>To</h6>
                                <input type="text" className="form-control" placeholder="To" />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="InputBox">
                                <h6>Departure Date</h6>
                                <input type="date" className="form-control-date" />
                                <span className="FilterIcon">
                                  <img src={images.calendarBlueImg} />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="InputBox SelectBox">
                                <span className="StaffIcon">
                                  <img src={images.userIcon} />
                                </span>
                                <h6>Guests</h6>
                                <select>
                                  <option selected="">select</option>
                                  <option>2 guests</option>
                                  <option>3 guests</option>
                                  <option>4 guests</option>
                                  <option>5 guests</option>
                                </select>
                                <span className="FilterIcon">
                                  <img src={images.chevronDown} />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <a href="show_flights.html" className="ShowHotels">
                                <img src={images.airplaneWhite} /> Show Flights
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="file RadioText">
                          <div className="Filter">
                            <div className="form-group">
                              <div className="CommonInputBox">
                                <span className="CibLeftOverlay">
                                  <img src={images.flightIcon} />
                                </span>
                                <h6>From</h6>
                                <input type="text" className="form-control" placeholder="From" />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="CommonInputBox">
                                <span className="CibLeftOverlay">
                                  <img src={images.flightIcon} />
                                </span>
                                <h6>To</h6>
                                <input type="text" className="form-control" placeholder="To" />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="InputBox">
                                <h6>Departure Date</h6>
                                <input type="date" className="form-control-date" />
                                <span className="FilterIcon">
                                  <img src={images.calendarBlueImg} />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="InputBox">
                                <h6>Return Date</h6>
                                <input type="date" className="form-control-date" />
                                <span className="FilterIcon">
                                  <img src={images.calendarBlueImg} />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="InputBox SelectBox">
                                <span className="StaffIcon">
                                  <img src={images.userIcon} />
                                </span>
                                <h6>Guests</h6>
                                <select>
                                  <option selected="">select</option>
                                  <option>2 guests</option>
                                  <option>3 guests</option>
                                  <option>4 guests</option>
                                  <option>5 guests</option>
                                </select>
                                <span className="FilterIcon">
                                  <img src={images.chevronDown} />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <a href="show_flights.html" className="ShowHotels">
                                <img src={images.airplaneWhite} /> Show Flights
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Tab.Pane>
                    <Tab.Pane eventKey="Packages">
                      <div className="tab-content">
                        <div className="tab-pane fade show active">
                          <div className="d-flex align-items-center pt-2">
                            <h5>Enter City, Area or Hotel Name</h5>
                          </div>
                          <div className="Filter">
                            <div className="form-group">
                              <div className="CommonInputBox">
                                <span className="CibLeftOverlay">
                                  <img src={images.hotelImg} />
                                </span>
                                <h6>Destination</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Destination"
                                  name='packageCityName'
                                  value={packageCityName}
                                  onChange={handleChangePackage}
                                />
                                <span className="CibRightOverlay">
                                  <img src={images.barIcon} />
                                </span>
                              </div>
                              {errorMsg.packageCityName && !packageCityName && (
                                <p style={{ color: "red" }}>{errorMsg.packageCityName}</p>
                              )}

                            </div>
                            <div className="form-group">
                              <div className="InputBox">
                                <h6>From</h6>
                                <input
                                  type="date"
                                  min={moment(new Date()).format("YYYY-MM-DD")}

                                  className="form-control-date"
                                  name="packageStartDate"
                                  value={packageStartDate}
                                  onChange={handleChangePackage}

                                />

                                <span className="FilterIcon">
                                  <img src={images.calendarBlueImg} />
                                </span>
                              </div>
                              {errorMsg.packageStartDate && !packageStartDate && (
                                <p style={{ color: "red" }}>{errorMsg.packageStartDate}</p>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="InputBox">
                                <h6>To</h6>
                                <input
                                  type="date"

                                  className="form-control-date"

                                  disabled={!packageStartDate}



                                  name="packageEndDate"
                                  min={packageStartDate}

                                  value={packageEndDate}
                                  onChange={handleChangePackage}

                                />


                                <span className="FilterIcon">
                                  <img src={images.calendarBlueImg} />
                                </span>
                              </div>
                              {errorMsg.packageEndDate && !packageEndDate && (
                                <p style={{ color: "red" }}>{errorMsg.packageEndDate}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="InputBox SelectBox">
                                <span className="StaffIcon">
                                  <img src={images.userIcon} />
                                </span>
                                <h6>Guests</h6>
                                <select name="numberOfGuests" value={numberOfGuests} onChange={handleChangePackage}>
                                  <option selected="">select</option>
                                  {
                                    MaxAdults?.map((item) => {
                                      return (
                                        <option value={item}>{item}</option>
                                      )
                                    })
                                  }
                                </select>

                                <span className="FilterIcon">
                                  <img src={images.chevronDown} />
                                </span>
                              </div>

                              {errorMsg.numberOfGuests && !numberOfGuests && (
                                <p style={{ color: "red" }}>{errorMsg.numberOfGuests}</p>
                              )}
                            </div>
                            <div className="form-group">
                              <a onClick={handleSubmit} className="ShowHotels"

                              >
                                <img src={images.showHotel} /> Show Packages
                              </a>
                              {/* <Link >Show Package</Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FindActivities">
                      <div className="tab-content">
                        <h5>Book Your Activities</h5>
                        <div className="Filter">
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.destination} />
                              </span>
                              <h6>Country</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Country Name"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.destination} />
                              </span>
                              <h6>Destination</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Destination"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>From</h6>
                              <input type="date" className="form-control-date" />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>To</h6>
                              <input type="date" className="form-control-date" />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox SelectBox">
                              <span className="StaffIcon">
                                <img src={images.userIcon} />
                              </span>
                              <h6>Guests</h6>
                              <select>
                                <option selected="">select</option>
                                <option>2 guests</option>
                                <option>3 guests</option>
                                <option>4 guests</option>
                                <option>5 guests</option>
                              </select>
                              <span className="FilterIcon">
                                <img src={images.chevronDown} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <Link to="/activities" className="ShowHotels">
                              <img src={images.destinationWhite} /> Show
                              Activities
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FindTransfers">
                      <div className="tab-content">
                        <div className="d-flex align-items-center pt-2">
                          <h5>Enter City,Area or Hotel Name</h5>
                          <div className="FlightListingRadios ml-4">
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                One Way
                                <input
                                  type="radio"
                                  name="OneWay2"
                                  defaultValue="text"
                                  defaultChecked=""
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Round Trip
                                <input
                                  type="radio"
                                  name="OneWay2"
                                  defaultValue="file"
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="Filter">
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.hotelImg} />
                              </span>
                              <h6>From</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="From"
                              />
                              <span className="CibRightOverlay">
                                <img src={images.barIcon} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.hotelImg} />
                              </span>
                              <h6>To</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="To"
                              />
                              <span className="CibRightOverlay">
                                <img src={images.barIcon} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>Departure Date</h6>
                              <input type="date" className="form-control-date" name="transferDepartureDate" value={transferDepartureDate} onChange={handleChange2} />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="NewSearchBar">
                              <input type="text" class="form-control" placeholder="Search" value="" onClick={showChildrenAndAdultsAdd} />
                              {
                                ShowAddChildAndAdult ? <div class="NsbarOverlay">
                                  <h4>Room 1</h4>
                                  <div class="d-flex align-items-center justify-content-between mb-2">
                                    <h6>Adults</h6>
                                    <div class="PlusMinusBOx">
                                      <span onClick={removeTransferAdults}>
                                        <i class="fa-solid fa-minus"></i>
                                      </span>
                                      <input type="text" class="form-control"
                                        name="adults" value={transferAdults} />
                                      <span onClick={addTransferAdults}>
                                        <i class="fa-solid fa-plus"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between">
                                    <h6>Children</h6>
                                    <div class="PlusMinusBOx" >
                                      <span onClick={removeTransferChildren}>
                                        <i class="fa-solid fa-minus"></i>
                                      </span>
                                      <input type="text" class="form-control"
                                        name="children" value={transferChildren} />
                                      <span onClick={addTransferChilren}>
                                        <i class="fa-solid fa-plus"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="NewSelectBox">
                                    <div class="d-flex mt-4 mb-3"></div>
                                  </div><a>
                                    <div>
                                      {/* <span>
                                    <i class="fa-solid fa-plus"></i>
                                    </span>
                                    Add more rooms */}
                                    </div>
                                    <button class="Button" onClick={showChildrenAndAdultsAdd}>Apply</button>
                                  </a>
                                </div> : ""
                              }
                            </div>
                          </div>
                          <div className="form-group">
                            <Link className="ShowHotels" to="/transfer" state={[transferDepartureDate, transferAdults, transferChildren]}>
                              <img src={images.showHotel} /> Show Transfer
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
              <div
                // className="section-title animate fadeInUp"
                // data-animate="fadeInUp"
                // data-duration="2s"
                // data-delay="0.1s"
                className="section-title"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <h1>
                  Connecting The <span>World’s Travel Trade</span>
                </h1>
                <p>
                  We build strong relationships at both ends of the travel chain
                  so that we can provide products, <br /> services and solutions
                  that help all our client and supplier partners grow
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className="BecomeEmpowerArea">
              <div className="row">
                <div className="col-md-6">
                  <div className="BecomeEmpowerLeft">
                    <figure
                      // className="animate fadeInUp"
                      // data-animate="fadeInUp"
                      // data-duration="1.5s"
                      // data-delay="0.7s"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="0.5s"
                    >
                      <img src={images.agent_1} />
                    </figure>
                    <figure
                      // className="animate fadeInUp"
                      // data-animate="fadeInUp"
                      // data-duration="1.5s"
                      // data-delay="0.3s"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="0.7s"
                    >
                      <img src={images.agent_2} />
                    </figure>
                    <figure
                      // className="animate fadeInUp"
                      // data-animate="fadeInUp"
                      // data-duration="1.5s"
                      // data-delay="0.5s"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="0.5s"
                    >
                      <img src={images.agent_3} />
                    </figure>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="BecomeEmpowerRight animate fadeInUp">
                    <h1
                      // className="animate fadeInUp"
                      // data-animate="fadeInUp"
                      // data-duration="2s"
                      // data-delay="0.1s"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      Become an <span>Empowered Travel Agent</span>
                    </h1>
                    <ul>
                      <li
                        // className="animate fadeInUp"
                        // data-animate="fadeInUp"
                        // data-duration="1.5s"
                        // data-delay="0.3s"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="0.4s"
                      >
                        <i className="fa fa-check" aria-hidden="true" />
                        <span>
                          Access and book millions of Destinations Itineraries and
                          packages
                        </span>
                      </li>
                      <li
                        // className="animate fadeInUp"
                        // data-animate="fadeInUp"
                        // data-duration="1.5s"
                        // data-delay="0.5s"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="0.3s"
                      >
                        <i className="fa fa-check" aria-hidden="true" />
                        <span>
                          Unlock Best Deals of International and National Hotels,
                          Airline, Transfers and Sightseeing
                        </span>
                      </li>
                      <li
                        // className="animate fadeInUp"
                        // data-animate="fadeInUp"
                        // data-duration="1.5s"
                        // data-delay="0.7s"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="0.1s"
                      >
                        <i className="fa fa-check" aria-hidden="true" />
                        <span>Dedicated Expert Team for Assistance</span>
                      </li>
                      <li
                        // className="animate fadeInUp"
                        // data-animate="fadeInUp"
                        // data-duration="1.5s"
                        // data-delay="0.9s"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="0.1s"
                      >
                        <i className="fa fa-check" aria-hidden="true" />
                        <span>Get exceptional Service with Trust</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="HappyClientArea">
              <div className="row">
                <div className="col-md-3">
                  <div className="Counter-Box">
                    <h1>
                      <span className="count" data-max="300"></span>
                    </h1>
                    <p>Hotels</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="Counter-Box">
                    <h1>
                      <span className="count" data-max="195"></span>
                    </h1>
                    <p>Destination Countries</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="Counter-Box">
                    <h1>
                      <span className="count" data-max="71"></span>k
                    </h1>
                    <p>Travel Distributors</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="Counter-Box">
                    <h1>
                      <span className="count" data-max="150"></span>
                    </h1>
                    <p>Source Market</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="Counter-Box mb-0 text-right">
                    <h1>
                      <span className="count" data-max="24"></span>k
                    </h1>
                    <p>Transfer Route</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="Counter-Box mb-0">
                    <h1>
                      <span className="count" data-max="18"></span>k
                    </h1>
                    <p>Activities &amp; Tickets</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="Counter-Box mb-0 text-left">
                    <h1>
                      <span className="count" data-max="5"></span>k
                    </h1>
                    <p>Car Rental Supplier</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="FindingYourDreams">
              <div className="row">
                <div className="col-md-4">
                  <span
                    className="WhyChooseUs"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >WHY CHOOSE US</span>
                </div>
                <div className="col-md-7">
                  <div
                    className="section-title animate fadeInUp"
                    data-animate="fadeInUp"
                    data-duration="1.5s"
                    data-delay="0.3s"
                  >
                    <h1
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      Finding your{" "}
                      <span>
                        dream <br /> destination
                      </span>{" "}
                      is our priority
                    </h1>
                    <p data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="400">
                      We are a Global DMC helping Travel Agents in India connect with travel service providers worldwide through an online interface. We have been working in the Indian Tourism Industry since 2007. An Indian army veteran established the company as a proprietor firm.
                    </p>
                  </div>
                </div>
                <div className="col-md-3 mx-auto">
                  <div className="FindingDreamsBox" data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="500">
                    <span className="Icon">
                      <img src={images.serviceIcon} />
                    </span>
                    <h5>Service</h5>
                    <p>
                      We promise to offer high quality and diverse services to our
                      partners. We aim to empower our partners with a plethora of
                      travel product options carefully curated by the best travel
                      service provider across the globe.
                    </p>
                  </div>
                </div>
                <div className="col-md-3 mx-auto">
                  <div className="FindingDreamsBox Second" data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="600">
                    <span className="Icon">
                      <img src={images.willingnessIcon} />
                    </span>
                    <h5>Willingness</h5>
                    <p>
                      The willingness to serve our partners with the utmost
                      professionalism, dedication and time is the core focus of
                      SWT. We 24X7 willingly work together to solve the problem of
                      late reverts faced by Travel Agents.
                    </p>
                  </div>
                </div>
                <div className="col-md-3 mx-auto">
                  <div className="FindingDreamsBox Third" data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="700">
                    <span className="Icon">
                      <img src={images.trustIcon} />
                    </span>
                    <h5>Trust</h5>
                    <p>
                      SWT act as a bridge of trust between Travel agents of India
                      and global travel partners. We have a strong team backed by
                      the highest work ethics calibrate with trusted international
                      partners to serve you with the best products at a
                      competitive price.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="OurSolutionsArea">
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <div className="OurSolutionsLeft">
                    {/* <span class="HowWeWork animate fadeInUp" data-animate="fadeInUp" data-duration="0.3s" data-delay="0.3s" >HOW WE WORK</span> */}{" "}

                    <h1
                      // className="animate fadeInUp"
                      // data-animate="fadeInUp"
                      // data-duration="2s"
                      // data-delay="0.5s"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      How <span>Our Solutions</span>
                      <br /> Can Help
                    </h1>
                    <p
                      // className="animate fadeInUp"
                      // data-animate="fadeInUp"
                      // data-duration="2s"
                      // data-delay="0.7s"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      The solution for those of you who don't want the hassle of
                      ordering travel tickets, with just 3 steps you can travel
                      anywhere you want.
                    </p>
                    {""}
                    {/* <a class="animate fadeInUp" data-animate="fadeInUp" data-duration="2s" data-delay="0.9s" href="javascript:void(0);">Booking now</a> */}{" "}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="OurSolutionsRight"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div
                      className="OsrBox"
                    >
                      <h2>Make Your Itinerary</h2>
                      <p>
                        Find your travel destination, because we have covered all
                        regions in the world.
                      </p>
                      <span>1</span>
                    </div>
                    <div
                      className="OsrBox"
                    >
                      <h2>Fair Pricing</h2>
                      <p>
                        After you find the trip you want to go to, you can
                        directly order ticket.
                      </p>
                      <span>2</span>
                    </div>
                    <div
                      className="OsrBox"
                    >
                      <h2>Pay and Go to destination</h2>
                      <p>
                        After you pay, you can immediatly enjoy the trip to the
                        destination.
                      </p>
                      <span>3</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="OurReachArea">
              <div
                className="section-title animate fadeInUp"
                data-animate="fadeInUp"
                data-duration="1.5s"
                data-delay="0.3s"
              >
                <h1>
                  Our <span>Reach</span>
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore <br /> et dolore magna
                  aliqua. Ut enim ad.
                </p>
              </div>
              <SwtMap type={"index"} />
              {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28036.837992602235!2d77.25942301083984!3d28.551597699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84369151d512cd45%3A0xa8b9685a307a3583!2sbookmymap!5e0!3m2!1sen!2sin!4v1699092191618!5m2!1sen!2sin"
              width="100%"
              height={600}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            /> */}
            </div>
          </section>
          {/* <section>
          <div className="ChoosePackageArea">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="ChoosePackageLeft"
                  data-aos="fade-up"
                  data-aos-duration="800"

                >
                  <h1>
                    OUR <span>PRODUCT</span> OFFERINGS
                  </h1>
                  <h1>Choose the <span>right <br> package</span> with SWT</h1>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="ChoosePackageRight"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <p>
                    Beside our Do-It-Yourself Customized Packages, we also{" "}
                    <br /> have Series &amp; Ready Packages to make your Tour{" "}
                    <br /> Packages’ Designs and Offerings fast.
                  </p>
                </div>
              </div>
              <div
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div className="row"

                >
                  <div className="col-md-4">
                    <div
                      className="ChoosePackageBox animate fadeInUp"

                    >
                      <figure>
                        <img src={images.package_1} />
                        <span>Grand Tour of Europe - Group Tour </span>
                      </figure>
                      <h2>INR. 18,090</h2>
                      <span className="Center">Agent Login</span>
                      <ul>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Per PAX</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>13 Nights 14 Days</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Accommodation</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Sightseeings</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Indian Meals</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Tour Manager</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="ChoosePackageBox White"
                    >
                      <figure>
                        <span>Recommended</span>
                        <img src={images.package_2} />
                        <span>Blissful Europe - Group Tour</span>
                      </figure>
                      <h2>INR. 21,060</h2>
                      <span className="Center">Agent Login</span>
                      <ul>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Per PAX</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>13 Nights 14 Days</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Accommodation</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Sightseeings</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Indian Meals</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Tour Manager</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="ChoosePackageBox"
                    >
                      <figure>
                        <img src={images.package_3} />
                        <span>Blissful Europe - Group Tour</span>
                      </figure>
                      <h2>INR. 12,090</h2>
                      <span className="Center">Agent Login</span>
                      <ul>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Per PAX</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>13 Nights 14 Days</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Accommodation</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Sightseeings</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Indian Meals</span>
                        </li>
                        <li>
                          <img src={images.greenCheck} />
                          <span>Tour Manager</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section> */}
          <section>
            <div className="AdventureActivity">
              <div
                className="section-title"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h1>
                  Adventure &amp; <span>Activity</span>{" "}
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore <br /> et dolore magna
                  aliqua. Ut enim ad.
                </p>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div
                    className="AdventureBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Sign Up</h6>
                    <p>
                      Agent will complete the registration process to become
                      member of SWT CLUB.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="AdventureBox animate fadeInUp"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Login</h6>
                    <p>Login access granted.</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="AdventureBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Unlock Power</h6>
                    <p>Unlock thousands of Travel products available for you.</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="AdventureBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Make Your Itinerary</h6>
                    <p>
                      Create your itinerary by selecting from the variety of
                      readymade itineraries available.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="AdventureBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Fair Pricing</h6>
                    <p>
                      All the travel products listed on portal have fair pricing
                      net to travel agent partners.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="AdventureBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Book Your Itinerary</h6>
                    <p>
                      Our Team will check and confirm availablity with shortest
                      revertal time.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="AdventureBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Online Payment</h6>
                    <p>
                      After receiving confirmation, Travel agent Partner can make
                      payment both online and offline.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="AdventureBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <span className="Icon">
                      <img src={images.adventure_1} />
                    </span>
                    <h6>Booking Confirmed</h6>
                    <p>
                      Your Booking is confirmed and our operation team will assist
                      you with final handover.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="AdventureActivityLeft"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h1>
                      Our{" "}
                      <span>
                        News and <br />
                        insights
                      </span>{" "}
                      from experts
                    </h1>
                    {/* <h1>Choose the <span>right <br> package</span> with SWT</h1> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="AdventureActivityRight"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <p>
                      Lorem ipsum dolor sit amet, consectetur <br /> adipiscing
                      elit, sed do eiusmod tempor incididunt <br /> ut labore et
                      dolore magna aliqua. Ut enim ad.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="BestHotelDealsArea">
              <div className="row">
                <div className="col-md-6">
                  <div className="BestHotelDealsLeft">
                    <h1
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      Best Hotel <span>Deals</span>
                    </h1>
                    <div
                      className="BhdaBox"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <h5>Surya Vilas, Solan</h5>
                      <p>
                        Experience an unforgettable romance on the heritage of
                        Solan.
                      </p>
                      <div className="BhdaDetails">
                        <p>
                          <span>
                            <img src={images.calendar} />
                          </span>
                          7 Days 6 Nights
                        </p>
                        <p>
                          <span>
                            <img src={images.star} />
                          </span>
                          4.7 (reviews)
                        </p>
                      </div>
                      <span className="BhdaBoxOverlay">Book Now</span>
                    </div>
                    <div
                      className="BhdaBox"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <h5>Khas Bagh Amer, Jaipur, Rajasthan</h5>
                      <p>Give your family a fun-filled vacation in Rajasthan.</p>
                      <div className="BhdaDetails">
                        <p>
                          <span>
                            <img src={images.calendar} />
                          </span>
                          10 Days 9 Nights
                        </p>
                        <p>
                          <span>
                            <img src={images.star} />
                          </span>
                          4.7 (reviews)
                        </p>
                      </div>
                      <span className="BhdaBoxOverlay">Book Now</span>
                    </div>
                    <div
                      className="BhdaBox"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <h5>Svasara Jungle Lodge, Jim Corbett</h5>
                      <p>
                        Enjoy thrilling outdoor adventures in the natural beauty
                        of Jim Corbett.
                      </p>
                      <div className="BhdaDetails">
                        <p>
                          <span>
                            <img src={images.calendar} />
                          </span>
                          5 Days 4 Nights
                        </p>
                        <p>
                          <span>
                            <img src={images.star} />
                          </span>
                          4.7 (reviews)
                        </p>
                      </div>
                      <span className="BhdaBoxOverlay">Book Now</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="BestHotelDealsRight"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <figure>
                      <img src={images.hotel} />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="TestimonialsArea">
              <div
                className="section-title"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <span className="TestimonialSpan">Testimonial</span>
                <h1>
                  Our <span>Empowered</span> Travel Agents
                </h1>
                <p>
                  A good domain will clearly reflect your identity and purpose, be
                  easy to remember, and can increase <br /> your visibility and
                  branding.
                </p>
              </div>
              <div className="owl-carousel owl-theme">
                <div className="item">
                  <div className="TestimonialsBox">
                    <span className="quote">
                      <img src={images.quote} />
                    </span>
                    <hr />
                    <h5>
                      Inventore vel alias consequatur nihil maiores voluptate enim
                      ea deleniti.Inventore vel alias consequatur nihil maiores
                      voluptate enim ea deleniti.
                    </h5>
                    <div className="TestimonialsDetails">
                      <figure>
                        <img src={images.testimonial_1} />
                      </figure>
                      <h6>Lola Ross</h6>
                      <p>Digital Marketing Director</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="TestimonialsBox">
                    <span className="quote">
                      <img src={images.quote} />
                    </span>
                    <hr />
                    <h5>
                      Inventore vel alias consequatur nihil maiores voluptate enim
                      ea deleniti.Inventore vel alias consequatur nihil maiores
                      voluptate enim ea deleniti.
                    </h5>
                    <div className="TestimonialsDetails">
                      <figure>
                        <img src={images.testimonial_1} />
                      </figure>
                      <h6>Lola Ross</h6>
                      <p>Digital Marketing Director</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="TestimonialsBox">
                    <span className="quote">
                      <img src={images.quote} />
                    </span>
                    <hr />
                    <h5>
                      Inventore vel alias consequatur nihil maiores voluptate enim
                      ea deleniti.Inventore vel alias consequatur nihil maiores
                      voluptate enim ea deleniti.
                    </h5>
                    <div className="TestimonialsDetails">
                      <figure>
                        <img src={images.testimonial_1} />
                      </figure>
                      <h6>Lola Ross</h6>
                      <p>Digital Marketing Director</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="TestimonialsBox">
                    <span className="quote">
                      <img src={images.quote} />
                    </span>
                    <hr />
                    <h5>
                      Inventore vel alias consequatur nihil maiores voluptate enim
                      ea deleniti.Inventore vel alias consequatur nihil maiores
                      voluptate enim ea deleniti.
                    </h5>
                    <div className="TestimonialsDetails">
                      <figure>
                        <img src={images.testimonial_1} />
                      </figure>
                      <h6>Lola Ross</h6>
                      <p>Digital Marketing Director</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="TestimonialsBox">
                    <span className="quote">
                      <img src={images.quote} />
                    </span>
                    <hr />
                    <h5>
                      Inventore vel alias consequatur nihil maiores voluptate enim
                      ea deleniti.Inventore vel alias consequatur nihil maiores
                      voluptate enim ea deleniti.
                    </h5>
                    <div className="TestimonialsDetails">
                      <figure>
                        <img src={images.testimonial_1} />
                      </figure>
                      <h6>Lola Ross</h6>
                      <p>Digital Marketing Director</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="TestimonialsBox">
                    <span className="quote">
                      <img src={images.quote} />
                    </span>
                    <hr />
                    <h5>
                      Inventore vel alias consequatur nihil maiores voluptate enim
                      ea deleniti.Inventore vel alias consequatur nihil maiores
                      voluptate enim ea deleniti.
                    </h5>
                    <div className="TestimonialsDetails">
                      <figure>
                        <img src={images.testimonial_1} />
                      </figure>
                      <h6>Lola Ross</h6>
                      <p>Digital Marketing Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="OurPartnersArea">
              <div
                // className="section-title animate fadeInUp"
                // data-animate="fadeInUp"
                // data-duration="1s"
                // data-delay="0.2s"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1>
                  Our <span>Partners</span>
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor.
                </p>
              </div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="TrustedPartner"
              >
                <div className="CommonTabs">
                  <Nav className="nav-tabs justify-content-center mb-5">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="TrustedPartner"
                      //  as={Link}
                      //   to="/payment-management/Payout"
                      >
                        {/* <img src={images.country_icon} /> */}
                        {" "}
                        Trusted Partner
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="ProudMember"
                      //   as={Link}
                      //   to="/payment-management/Transactions"
                      >
                        {/* <img src={images.flightIcon} /> */}
                        {" "}
                        {" "}
                        Proud Member
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="TrustedPartner">
                    <div className="tab-content">
                      <div className="tab-pane fade show active">
                        <div className="tab-content-details" style={{ display: 'flex', justifyContent: "center", alignItems: "center", margin: '0 10px' }}>
                          {partners?.length > 0 ? partners.map((item) => {
                            return (
                              <figure>
                                <img src={item?.image} height="70px" width="100px" style={{ margin: ' 0 10px' }} />
                              </figure>
                            )
                          }) : ""}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ProudMember">
                    <div className="tab-content">
                      <div className="tab-pane fade show active" >
                        <div className="tab-content-details" style={{ display: 'flex', justifyContent: "center", alignItems: "center", margin: '0 10px' }}>
                          {proudPartner?.length > 0 ? proudPartner.map((item) => {
                            return (
                              <figure>
                                <img src={item?.image} height="70px" width="100px" style={{ margin: ' 0 10px' }} />
                              </figure>
                            )
                          }) : ""}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
              {/* <div className="PartnersTabs">
              <ul
                className="nav nav-tabs justify-content-center animate fadeInUp"
                id="myTab"
                data-animate="fadeInUp"
                data-duration="1s"
                data-delay="0.4s"
              >
                <li className="nav-item">
                  <a
                    href="#TrustedPartner"
                    className="nav-link active"
                    data-toggle="tab"
                  >
                    Trusted Partner
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#ProudMember" className="nav-link" data-toggle="tab">
                    Proud Member
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="TrustedPartner">
                  <div className="tab-content-details" id="myTab">
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                  </div>
                  <div className="tab-content-details">
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                  </div>
                </div>
                <div className="tab-pane fade" id="ProudMember">
                  <div className="tab-content-details">
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                  </div>
                  <div className="tab-content-details">
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </section>
        </main>
      </div>
      <Footer />
      <Modal
        className="ModalBox"
        show={showHotelModal}
      // onHide={false}
      >
        <Modal.Body>
          {/* <a className="CloseModal" >
          x
        </a> */}
          <div class="modal-content">
            <div class="TransferBox">
              <div class="TransferHeader">
                <h5>
                  <span>
                    <img src="images/Refresh.png" />{" "}
                  </span>
                  Searching for
                  <strong>Istanbul</strong>
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  onClick={closeHotelModal}
                >
                  x
                </button>
              </div>
              <div class="TransferBody">
                <a href="product.html">
                  <div class="SearchInfo">
                    <h6>
                      <strong>Destination</strong>
                      <span>Istanbul, Mississippi Terminal</span>
                    </h6>
                    <p>
                      <span>
                        <img src="images/Calender.png" />{" "}
                      </span>{" "}
                      21 August - 22 August
                    </p>
                    <p>
                      <span>
                        <img src="images/hotel-grey.png" />{" "}
                      </span>{" "}
                      21 August - 22 August
                    </p>
                    <p>
                      <span>
                        <img src="images/user.png" />{" "}
                      </span>{" "}
                      21 August - 22 August
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modalBox modalBoxNew"
        show={showSearchModal}
        centered
      // onHide={false}
      >
        <Modal.Body>
          {/* <a className="CloseModal" >
          x
        </a> */}
          <div class="modal-content">
            <div class="TransferBox">
              <div className="TransferHeader">
                <div
                  className="SearchRadio mb-0"
                // style={{display:"flex", justifyContent:"center", alignContent:"baseline"}}
                >
                  <ul>
                    <li>
                      <label className="Radio">
                        Africa
                        <input
                          type="radio"
                          defaultValue="Africa"
                          name="continent"
                          defaultChecked="Africa"
                          value="Africa"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        America
                        <input
                          type="radio"
                          defaultValue="America"
                          name="continent"
                          value="America"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        Asia
                        <input
                          type="radio"
                          name="continent"
                          value="Asia"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        Europe
                        <input
                          type="radio"
                          defaultValue="Europe"
                          name="continent"
                          value="Europe"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        Oceania
                        <input
                          type="radio"
                          defaultValue="Oceania"
                          name="continent"
                          value="Oceania"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                  </ul>
                </div>
                <button type="button" onClick={handleCloseModal}>
                  ×
                </button>
              </div>
              <div class="TransferBody">
                <div
                  style={{ zIndex: "9999999" }}
                >
                  <div className="CountriesArea">
                    <div className="Heading">
                      <h3>Africa Countries</h3>
                    </div>
                    <ul>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modalBox modalBoxNew"
        show={showSearchModal}
        centered
      // onHide={false}
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleConfirmModalClose} >
            x
          </a>
          <div class="modal-content">
            <div class="TransferBox">
              <div className="TransferHeader">
                <div
                  className="SearchRadio mb-0"
                // style={{display:"flex", justifyContent:"center", alignContent:"baseline"}}
                >
                  <ul>
                    <li>
                      <label className="Radio">
                        Africa
                        <input
                          type="radio"
                          defaultValue="Africa"
                          name="continent"
                          defaultChecked="Africa"
                          value="Africa"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        America
                        <input
                          type="radio"
                          defaultValue="America"
                          name="continent"
                          value="America"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        Asia
                        <input
                          type="radio"
                          name="continent"
                          value="Asia"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        Europe
                        <input
                          type="radio"
                          defaultValue="Europe"
                          name="continent"
                          value="Europe"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                    <li>
                      <label className="Radio">
                        Oceania
                        <input
                          type="radio"
                          defaultValue="Oceania"
                          name="continent"
                          value="Oceania"
                          onChange={handleChange2}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                  </ul>
                </div>
                <button type="button" onClick={handleCloseModal}>
                  ×
                </button>
              </div>
              <div class="TransferBody">
                <div
                  style={{ zIndex: "9999999" }}
                >
                  <div className="CountriesArea">
                    <div className="Heading">
                      <h3>Africa Countries</h3>
                    </div>
                    <ul>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                      <li>Algeria</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modalBox modalBoxNew"
        show={confirmModal}
        centered
        onHide={handleConfirmModalClose}
      >
        <Modal.Body>

          <div class="TransferBox">
            <div class="TransferHeader">
              <h5>
                <span><img src={require("../assests/images/Refresh.png")} /> </span>
                Searching for {' '}
                <strong>{packageCityName}</strong>
              </h5>
              <button type="button" data-dismiss="modal" onClick={handleConfirmModalClose}> ×</button>
            </div>
            <div class="TransferBody">
              <a >
                <div class="SearchInfo">
                  <h6>
                    <strong>Destination</strong>
                    <span>{packageCityName}</span>
                  </h6>
                  <p><span><img src={require("../assests/images/Calender.png")} /> </span> {moment(packageStartDate)?.format('ll')} -{moment(packageEndDate)?.format('ll')}</p>
                </div>
              </a>
            </div>

          </div>
        </Modal.Body>
      </Modal>

    </>



  );
};

export default Index;
