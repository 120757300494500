export const privateData = {
    "proxy-url":"http://localhost:8010/proxy",
    "Api-key": "94f3740f6f33639e6e588c4b0781663f",
    "secret" : "c49b771d45"
  }


  ///I have updated your apikey 94f3740f6f33639e6e588c4b0781663f 50000  request per day and 20 queries per second(Content API is limited to 4 qps )


































//34afe8a6e7cfcee7c9b52168a27823e4
//e43f9fe53a

// official website api key and secret :
//94f3740f6f33639e6e588c4b0781663f
//secret 
//c49b771d45


//Api Key - d1b6bf73a1385f15ed12652a89d8775e  expired
// "secret" : "8e6be992fc"
//harshit12
//Thatsme@

// riya
//42fb8e51b5	
//977eebd9d3cd83b1f70cf8430b9d3deb

// sakshi
//a8c71880b180494f56645b08031a3ba8
//498e461981

// Aftab
//48139bb489d480e440abd412edced8ea
//b03ac4a24f

// for transfer api 
//14f67b00271cbad54fed483672a39b5b
//960459df9c