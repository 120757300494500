import country_icon from "../assests/images/country_icon.png";
import logo from "../assests/images/logo.png"
import bannerVideo from "../assests/video/bannerVideo.mp4";
import hotelImg from "../assests/images/find_hotels.png"
import barIcon from "../assests/images/bar_icon.png"
import calendarBlueImg from "../assests/images/calendar_blue.png"
import userIcon from "../assests/images/user_icon.png"
import chevronDown from "../assests/images/chevron_down.png"
import showHotel from "../assests/images/show_hotels.png"
import flightIcon from "../assests/images/flight_icon.png"
import airplaneWhite from "../assests/images/airplane_white.png"
import agent_1 from "../assests/images/agent_1.png"
import agent_2 from "../assests/images/agent_2.png"
import agent_3 from "../assests/images/agent_3.png"
import serviceIcon from "../assests/images/service-icon.png"
import willingnessIcon from "../assests/images/willingness-icon.png"
import trustIcon from "../assests/images/trust-icon.png"
import package_1 from "../assests/images/package_1.png"
import greenCheck from "../assests/images/green_check.png"
import package_2 from "../assests/images/package_2.png"
import package_3 from "../assests/images/package_3.png"
import adventure_1 from "../assests/images/adventure-1.png"
import adventure_2 from "../assests/images/adventure-2.png"
import adventure_3 from "../assests/images/adventure-3.png"
import adventure_4 from "../assests/images/adventure-4.png"
import adventure_5 from "../assests/images/adventure-5.png"
import adventure_6 from "../assests/images/adventure-6.png"
import adventure_7 from "../assests/images/adventure-7.png"
import adventure_8 from "../assests/images/adventure-8.png"
import calendar from "../assests/images/calendar.png"
import star from "../assests/images/star.png"
import hotel from "../assests/images/hotel_img.png"
import quote from "../assests/images/quote.png"
import testimonial_1 from "../assests/images/testimonial_1.png"
import partner_1 from "../assests/images/partner_1.png"
import partner_2 from "../assests/images/partner_2.png"
import partner_3 from "../assests/images/partner_3.png"
import partner_4 from "../assests/images/partner_4.png"
import partner_5 from "../assests/images/partner_5.png"
import partner_6 from "../assests/images/partner_6.png"
import instagramIcon from "../assests/images/instagram.png"
import twitterIcon from "../assests/images/twitter.png"
import youtubeIcon from "../assests/images/youtube.png"
import priceRange from "../assests/images/price_range_img.png"
import emiratesLogo from "../assests/images/emirates_logo.png"
import leftIcon from "../assests/images/left_icon.png"
import baggageIcon from "../assests/images/baggage_icon.png"
import checkBaggageIcon from "../assests/images/check_baggage_icon.png"
import destination from "../assests/images/destination.png"
import destinationWhite from "../assests/images/destination_white.png"
import interior from "../assests/images/interior.png"
import small_1 from "../assests/images/small_01.png"
import small_2 from "../assests/images/small_02.png"
import small_3 from "../assests/images/small_03.png"
import small_4 from "../assests/images/small_04.png"
import small_5 from "../assests/images/small_05.png"
import heart from "../assests/images/heart.png"
import rightArrow from "../assests/images/right-arrow.png"
import location from "../assests/images/location.png"
import shareIcon from "../assests/images/share_icon.png"
import downloadIcon from "../assests/images/download_icon.png"
import hotelBanner from "../assests/images/hotel_banner.png"
import overviewIcon from "../assests/images/overview_icon.png"
import room_1 from "../assests/images/rooms_01.png"
import room_2 from "../assests/images/rooms_02.png"
import room_3 from "../assests/images/rooms_03.png"
import room_4 from "../assests/images/rooms_04.png"
import flightSvgRepo from "../assests/images/flight-svgrepo.png"
import cancellationFees from "../assests/images/cancellation_fees.png"
import locationMapIcon from "../assests/images/location_map_icon.png"
import ameneties from "../assests/images/amenities_1.png"
import shoppingCart from "../assests/images/cart_icon.png"
import avatar from "../assests/images/avatar.png"
import packageIcon from "../assests/images/package_icon.png"
import showTransferBlue from "../assests/images/show_transfer_blue.png"
import adventureNew from "../assests/images/adventure-new.png"
import checkInOUT from "../assests/images/check_in_out_img.png"
import superiorRoom from "../assests/images/superior_room.png"
import clock from "../assests/images/Clock.png"
import hotelImg1 from "../assests/images/hotel-img-1.png"
import hotelImg2 from "../assests/images/hotel-img-2.png"
import hotelImg3 from "../assests/images/hotel-img-3.png"
import hotelImg4 from "../assests/images/hotel-img-4.png"
import hotelImg5 from "../assests/images/hotel-img-5.png"
import ShowIconImage from "../assests/images/show-icon.png"
import confirm from "../assests/images/confirm_icon.png"
import partner1 from "../assests/images/partner-1.png"
import partner2 from "../assests/images/partner-2.png"
import partner3 from "../assests/images/partner-3.png"
import partner4 from "../assests/images/partner-4.png"
import partner5 from "../assests/images/partner-5.png"
import partner6 from "../assests/images/partner-6.png"
import partner7 from "../assests/images/partner-7.png"
import partner8 from "../assests/images/partner-8.png"
import why1 from "../assests/images/why-1.png"
import why2 from "../assests/images/why-2.png"
import why3 from "../assests/images/why-3.png"
import why4 from "../assests/images/why-4.png"


export const images = {
    confirm,
    ShowIconImage,
    hotelImg1,
    hotelImg2,
    hotelImg3,
    hotelImg4,
    hotelImg5,
    clock,
    superiorRoom,
    checkInOUT,
    adventureNew,
    showTransferBlue,
    packageIcon,
    avatar,
    shoppingCart,
    ameneties,
    country_icon,
    bannerVideo,
    hotelImg,
    barIcon,
    calendarBlueImg,
    userIcon,
    chevronDown,
    showHotel,
    flightIcon,
    airplaneWhite,
    agent_1,
    agent_2,
    agent_3,
    serviceIcon,
    willingnessIcon,
    trustIcon,
    package_1,
    greenCheck,
    package_2,
    package_3,
    adventure_1,
    adventure_2,
    adventure_3,
    adventure_4,
    adventure_5,
    adventure_6,
    adventure_7,
    adventure_8,
    calendar,
    star,
    hotel,
    quote,
    testimonial_1,
    partner_1,
    partner_2,
    partner_3,
    partner_4,
    partner_5,
    partner_6,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
    logo,
    priceRange,
    emiratesLogo,
    leftIcon,
    baggageIcon,
    checkBaggageIcon,
    destination,
    destinationWhite,
    interior,
    small_1,
    small_2,
    small_3,
    small_4,
    small_5,
    heart,
    rightArrow,
    location,
    shareIcon,
    downloadIcon,
    hotelBanner,
    overviewIcon,
    room_1,
    room_2,
    room_3,
    room_4,
    flightSvgRepo,
    cancellationFees,
    locationMapIcon,
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    why1,
    why2,
    why3,
    why4
}