// countrySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";
import signature from "../../config/signature";

export const fetchAccommodations = createAsyncThunk('accommodations/fetchaccommodations', async () => {
    const response = await axios.get(`${privateData["proxy-url"]}/hotel-content-api/1.0/types/accommodations?codes=${["K", "Y", "W", "G", "H", "Q", "S", "D", "P", "M"]}`, {
        headers: {
            "Api-key": privateData["Api-key"],
            "X-Signature": signature(),
        }
    });
    return response.data;
});

const accommodationsSlice = createSlice({
    name: 'accommodations',
    initialState: {
        accommodations: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccommodations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAccommodations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.accommodations = action.payload;
            })
            .addCase(fetchAccommodations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default accommodationsSlice.reducer;
