import CryptoJS from "crypto-js";
import {privateData} from "../config/apiKey"

// Get the current timestamp in seconds
const utcDate = Math.floor(new Date().getTime() / 1000);

// Retrieve API key and secret
let publicKey = privateData["Api-key"] ;
let privateKey = privateData.secret;

// Concatenate API key, secret, and timestamp
const assemble = publicKey + privateKey + utcDate;

// Generate SHA-256 hash
const hash = CryptoJS.SHA256(assemble).toString(CryptoJS.enc.Hex);
const encryption = hash;

// Set X-Signature header value

export const xSignature = encryption
console.log('X-Signature:', xSignature,'private-key',privateKey);
