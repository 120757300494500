import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoggedIn } from "../../utils/LoggedIn";


export const signUpUpdateApi = createAsyncThunk(
  "signUpupdate/postsignUpupdate",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.put(
      "https://swtclub.com:4300/api/v1/user/update-user",
      payload,
      {
        headers: {
          "Authorization": payload.token
          //isLoggedIn("adminLogin")
        },
      }
    );
    return response.data
      ;
  }
);