import React, { useEffect, useState } from 'react'
import { images } from '../utils/images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotelList } from '../reduxToolKit/slices/hotelListApiSlice';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { fetchLocationList } from '../reduxToolKit/slices/locationListApiSlice';
import { isLoggedIn } from '../utils/LoggedIn';


const initialState = {
    showSearchModal: false,
    cityName: "",
    checkIn: "",
    checkOut: "",
    rooms: "",
    guests: "",
    roomsDetail: [{
        rooms: 1,
        adults: 2, children: '',
        paxes: [{
            type: "CH",
            age: "1"
        }]
    }],
    continent: "",
    location: "",
    lat: "",
    lng: "",
    hotelName: "",
    errors: "",
    packageCityName: "",
    packageStartDate: "",
    packageEndDate: "",

    numberOfGuests: "",
    hotelCode: "",
    transferDepartureDate: "",

    errorMsg: {},
    //================type for search=================//
    type: ""
};
const CommonHotelSearch = (props) => {
    console.log('props in common hotel search', props, 'function',)
    const [iState, updateState] = useState(initialState);
    const { cityName, checkIn, checkOut, rooms, guests, roomsDetail, showSearchModal, continent, location, lat, lng, hotelName, errors, packageCityName, packageStartDate, numberOfGuests, hotelCode, transferDepartureDate, packageEndDate, errorMsg, type } = iState;
    const [showRoom, setShowRoom] = useState(false)
    const locationList = useSelector((state) => state?.locationList?.locationList?.data?.result)
    const status = useSelector((state) => state.locationList)
    const newLocationList = useSelector((state) => state?.locationList?.locationList?.data)
    const [showSearchDiv, setShowSearchDiv] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const childAge = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

    const handleChange = (e, i, type, ageindex) => {
        // console.log(e, i, type, ageindex)
        const { name, value } = e.target
        // console.log('name', name, 'type', type, 'value', value)
        if (type === 'remove') {
            // console.log("roomsDetail", roomsDetail, 'item to be removed', i)
            const removedItemArray = []
            for (let j = 0; j < roomsDetail.length; j++) {
                if (j !== i) {
                    removedItemArray.push(roomsDetail[j])
                }
            }
            //console.log("removedItemArray", removedItemArray)
            updateState({
                ...iState,
                roomsDetail: removedItemArray
            })
        } else {
            if (name === 'children' && value <= 4) {
                const user = [...roomsDetail];
                user[i] = { ...user[i], [name]: value };

                let updatedPaxes = []
                for (let i = 0; i < value; i++) {
                    updatedPaxes.push({ type: 'CH', age: '1' })
                }

                // console.log('index of object', i, 'value', value)
                // console.log("updatedPaxes", updatedPaxes)

                updateState((prev) => ({
                    ...prev,
                    roomsDetail: user.map((room, index) => (index === i ? { ...room, paxes: updatedPaxes } : room)),
                }));
                console.log('istate', iState)
            }
            if (name === 'adults' && value <= 8) {
                const user = [...roomsDetail]
                user[i] = { ...user[i], [name]: value }
                //console.log('Add room functionality', e, "**********", i, '***********', type, name)
                updateState((prev) => ({ ...prev, roomsDetail: user }))
                //console.log(user)
            }
            if (type === 'childAge') {
                //console.log('name', name, 'value', value, 'type', type)
                const user = [...roomsDetail]
                for (let j = 0; j <= i; j++) {
                    // console.log('i and j', i, j)
                    if (j === i) {
                        // [ user[j].paxes[ageindex]] = {type:'CH',[name]:value}  [ user[j].paxes[ageindex]] = {type:'CH',[name]:value}
                        //console.log('age of child at index x', user[j].paxes[ageindex].age = value)
                    }
                }
                // user[i] = { ...user[i], [user[i].paxes[ageindex]]:[[name]:value] }
                //console.log(' user ', user)
                updateState((prev) => ({ ...prev, roomsDetail: user }))
                // console.log('roomsDetail', roomsDetail)
            }
        }

    }

    const handleChange2 = (e, type) => {
        // console.log('type in search bar', type)
        // if (e.keyCode == 8) {
        //     // prevent default behavior
        //     e.preventDefault();

        //     updateState({
        //         ...iState,
        //         location: hotelName
        //     })
        // }
        const { name, value } = e.target
        updateState({
            ...iState,
            [name]: value
        })
        //console.log('Props', props)
        // if (props?.stateData?.updateState ?? null) {
        //     props.stateData.updateState({
        //         ...props.stateData.iState,
        //         [name]: value
        //     })
        // }

        if (type === 'searchField') {
            setShowSearchDiv(true)
        }
    }

    const handleKeyDown = (e) => {
        //console.log('key down', e.onKeyDown)
    }

    const handleRoom = () => {
        setShowRoom(!showRoom)
    }

    const totalAdults = () => {
        let totalAdults = 0;
        let totalChildren = 0;

        for (let i = 0; i < roomsDetail.length; i++) {
            totalAdults += roomsDetail[i]?.adults === "" ? 0 : Number(roomsDetail[i]?.adults);
            totalChildren += roomsDetail[i]?.child === "" ? 0 : Number(roomsDetail[i]?.children);
        }

        // if (totalChildren > 0) {
        //   ;
        // } else {
        //   return `${totalAdults} - Adults`;
        // }
        return `Adults - ${totalAdults}  Children - ${totalChildren}`
    }

    const handleNumberOfRoomsAdd = () => {
        updateState({
            ...iState,
            roomsDetail: [...roomsDetail, { rooms: roomsDetail?.length + 1, adults: 2, children: '' }]
        })
    }

    const handleSearchByZone = () => {
        updateState({
            ...iState,
            showSearchModal: true
        })
    }

    const handleChange3 = (e, type, item) => {
        if (type === 'location') {
            updateState({
                ...iState,
                lat: Number((item?.lat).toFixed(4)),
                lng: Number((item?.long).toFixed(4)),
                hotelName: item?._id?.Destination_Name,
                hotelCode: item?.HBD_Hotel_ID,
                location: `${item?._id?.Destination_Name}, ${item?._id?.Zone_Name}, ${item?._id?.Country_Name}`,
                //location: type == "location" ? item.Destination_Name : item.Hotel_Name
                type: type
            })
        } else {
            updateState({
                ...iState,
                location: item?.Hotel_Name,
                hotelCode: item?.HBD_Hotel_ID,
                type: type
            })
            // setShowSearchDiv(false)
        }

        setShowSearchDiv(false)
        // console.log('hotel code', iState)
    }

    const roomsapply = () => {
        setShowRoom(false)
    }


    // For handling validation for search
    const handleValidation = () => {
        const error = {}
        let isValid = true

        if (!location) {
            error.LocationError = "This field is mandatory"
            isValid = false
        }

        updateState({
            ...iState,
            errors: error
        })
        return isValid
    }
    const handleShowHotel = () => {
        if (isLoggedIn("swtadmin")) {
            console.log('Api call executed')
            if (type === 'location') {
                console.log('Api call executed')
                const data = {
                    "stay": {
                        "checkIn": checkIn,
                        "checkOut": checkOut
                    },
                    "occupancies": roomsDetail.map((item, index) => {
                        return item.children === "" ? { rooms: index + 1, adults: item.adults, children: 0 } : { ...item };
                    }),
                    "geolocation": {
                        // "latitude": loclatlng?.split('*')[0],
                        "latitude": lat,
                        //"longitude": loclatlng?.split('*')[1],
                        "longitude": lng,
                        "radius": 20,
                        "unit": "km"
                    },
                    // "hotels": {
                    //   "hotel": [
                    //     hotelCode
                    //   ]
                    // },
                    "reviews": [
                        {
                            "type": "HOTELBEDS",
                            "maxRate": 5,
                            "minRate": 1,
                            // "minReviewCount": 3
                        }
                    ],
                }
                dispatch(fetchHotelList(data))?.then((res) => {
                    //console.log('Response of hotel list api from hotel bed', res)
                    if (res?.payload?.hotels?.hotels?.length > 0) {
                        toast.success('Hotels Found Sucessfully', {
                            position: 'top-right',
                            autoClose: 1000
                        })
                        navigate('/hotelList', {
                            state: {
                                checkIn,
                                checkOut,
                                roomsDetail,
                                lat,
                                lng,
                                location,
                                hotelName,
                                hotelCode,
                                type
                            }
                        })
                    } else {
                        toast.error('Hotel not available', {
                            position: 'top-right',
                            autoClose: 1000
                        })
                    }
                })
            } else {
                const data = {
                    "stay": {
                        "checkIn": checkIn,
                        "checkOut": checkOut
                    },
                    "occupancies": roomsDetail.map((item, index) => {
                        return item.children === "" ? { rooms: index + 1, adults: item.adults, children: 0 } : { ...item };
                    }),
                    // "geolocation": {
                    //     "latitude": loclatlng?.split('*')[0],
                    //     "latitude": lat,
                    //     "longitude": loclatlng?.split('*')[1],
                    //     "longitude": lng,
                    //     "radius": 20,
                    //     "unit": "km"
                    // },
                    "hotels": {
                        "hotel": [
                            hotelCode
                        ]
                    },
                    "reviews": [
                        {
                            "type": "HOTELBEDS",
                            "maxRate": 5,
                            "minRate": 1,
                            // "minReviewCount": 3
                        }
                    ],
                }
                dispatch(fetchHotelList(data))?.then((res) => {
                    //console.log('Response of hotel list api from hotel bed', res)
                    if (res?.payload?.hotels?.hotels?.length > 0) {
                        toast.success('Hotels Found Sucessfully', {
                            position: 'top-right',
                            autoClose: 1000
                        })
                        navigate('/hotelList', {
                            state: {
                                checkIn,
                                checkOut,
                                roomsDetail
                            }
                        })
                    } else {
                        toast.error('Hotel not available', {
                            position: 'top-right',
                            autoClose: 1000
                        })
                    }
                })
            }
        } else {
            navigate('/agentLogin')
        }

    }

    let destination = newLocationList?.Destinations?.slice(0, 10)
    let hotelList = newLocationList?.hotelByName?.slice(0, 20)

    useEffect(() => {
        if (location) {//
            const getData = setTimeout(() => {
                dispatch(fetchLocationList({ data: location })).then((res) => {
                    //console.log('Response of internal hotel list', res)
                    if (res?.payload?.status === 200) {
                        // if (!(res?.payload?.data?.Destinations?.length) && !(res?.payload?.data?.hotelByName?.length)) {
                        //     setShowSearchDiv(false)
                        // } else {
                        //     setShowSearchDiv(true)
                        // }

                    }
                })
            }, 2000);
            return () => { clearTimeout(getData) }
        }
    }, [location])

    useEffect(() => {
        updateState({
            ...iState,
            "checkIn": props?.stateData?.iState?.checkIn,
            "checkOut": props?.stateData?.iState?.checkOut,
            "lat": props?.stateData?.iState?.lat,
            "lng": props?.stateData?.iState?.lng,
            "location": props?.stateData?.state?.location,
            "type": props?.stateData?.state?.type,
        })
        // if (props?.stateData?.iState?.reset === "hit") {
        //     console.log('Reset logic')
        //     handleShowHotel()
        //     props.stateData.updateState({
        //         ...props.stateData.iState,
        //         "reset": ""
        //     })
        // }

    }, [props])

    // useEffect(() => {
    //     console.log('type', type, 'lat', lat, 'lng', lng, 'checkin', checkIn, 'checkOut', checkOut)
    //     handleShowHotel()
    // }, [type, lat, lng, location, checkIn, checkOut])
    return (
        <div>
            <div className="Filter">
                <div className="form-group">
                    <div className="CommonInputBox First HotelDropdownMain">
                        <span className="CibLeftOverlay">
                            <img src={images.hotelImg} />
                        </span>
                        <h6>Enter City, Area or Hotel Name</h6>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Hotel Name"
                            name="location"
                            value={location}
                            onChange={(e) => handleChange2(e, 'searchField')}
                            onKeyDown={handleKeyDown}
                        />
                        <span className="CibRightOverlay">
                            <img src={images.barIcon} onClick={handleSearchByZone} />
                        </span>
                        {
                            showSearchDiv ?
                                <div className="HotelDropdownOverlay">
                                    <div className="HotelDropdownBox">
                                        <h2>Destination</h2>
                                        <ul>
                                            {status?.status &&
                                                <ColorRing
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    ariaLabel="blocks-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="blocks-wrapper"
                                                    colors={[
                                                        "#b8c480",
                                                        "#B2A3B5",
                                                        "#F4442E",
                                                        "#51E5FF",
                                                        "#429EA6",
                                                    ]}
                                                />
                                            }
                                            {destination?.map((item, index) => {
                                                //console.log('item in destination', item)
                                                return (
                                                    <li
                                                        onClick={(e) => handleChange3(e, "location", item)}

                                                    >{`${item?._id?.Destination_Name}, ${item?._id?.Zone_Name}, ${item?._id?.Country_Name}`}
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>

                                    </div>
                                    <div className="HotelDropdownBox">
                                        <h2>Hotel list</h2>
                                        {/* <p>
                                      {locationList?.length > 0 && (
                                        <ul name='loclatlng'>
                                          {locationList?.slice(0, 10)?.map((item, index) => (
                                            <li
                                              // name='loclatlng'
                                              value={`${item?.Lat}*${item?.Long}`}
                                              onClick={handleChange2}>{item?.Hotel_Name}
                                              </li>
                                          ))}
                                        </ul>
                                      )}

                                    </p> */}
                                        <ul>
                                            {status?.status &&
                                                <ColorRing
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    ariaLabel="blocks-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="blocks-wrapper"
                                                    colors={[
                                                        "#b8c480",
                                                        "#B2A3B5",
                                                        "#F4442E",
                                                        "#51E5FF",
                                                        "#429EA6",
                                                    ]}
                                                />
                                            }
                                            {
                                                //locationList?.slice(0, 10)
                                                hotelList?.map((item, index) => {
                                                    return (
                                                        <li
                                                            onClick={(e) => handleChange3(e, "hotelName", item)}>{item?.Hotel_Name}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div> : null
                        }

                    </div>
                </div>
                <div className="form-group">
                    <div className="InputBox">
                        <h6>Check In</h6>
                        <input
                            type="date"
                            className="form-control-date"
                            name="checkIn"
                            value={checkIn}
                            max={checkOut || ""}
                            min={new Date().toISOString().split("T")[0]} // Format the current date as YYYY-MM-DD
                            onChange={(e) => handleChange2(e, 'checkin')}
                        />

                        <span className="FilterIcon">
                            <img src={images.calendarBlueImg} />
                        </span>
                    </div>
                </div>
                <div className="form-group">
                    <div className="InputBox">
                        <h6>Check Out</h6>
                        <input
                            type="date"
                            className="form-control-date"
                            name="checkOut"
                            value={checkOut}
                            onChange={(e) => handleChange2(e, 'checkOut')}
                            min={checkIn || ""}

                            disabled={!checkIn}
                        />
                        <span className="FilterIcon">
                            <img src={images.calendarBlueImg} />
                        </span>
                    </div>
                </div>
                <div className="form-group">
                    <div className="NewSearchBar">
                        <input type="text" className="form-control" placeholder="Search" onClick={handleRoom}
                            value={showRoom ? "" : totalAdults()}
                        />
                        {
                            //showRoom
                            showRoom ?
                                roomsDetail?.map((item, roomindex) => {
                                    return (
                                        <div className="NsbarOverlay">
                                            <h4>Room {roomindex + 1}</h4>
                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <h6>Adults</h6>
                                                <div className="PlusMinusBOx">
                                                    <span onClick={(e) => handleChange(e, roomindex, 'adult')}>
                                                        <i className="fa-solid fa-minus" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="adults"
                                                        value={item?.adults}
                                                        onChange={(e) => handleChange(e, roomindex)}
                                                    />
                                                    <span onClick={(e) => handleChange(e, roomindex, 'adult')}>
                                                        <i className="fa-solid fa-plus" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6>Children</h6>
                                                <div className="PlusMinusBOx" >
                                                    <span onClick={(e) => handleChange(e, roomindex, 'child')}>
                                                        <i className="fa-solid fa-minus" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="children"
                                                        value={item?.children}
                                                        //max='52'
                                                        // disabled={Number(item?.child) > 4 ? true : false}
                                                        onChange={(e) => handleChange(e, roomindex)}
                                                    />
                                                    <span onClick={(e) => handleChange(e, roomindex, 'child')}>
                                                        <i className="fa-solid fa-plus" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="NewSelectBox">
                                                {
                                                    item?.child > 0 ? <h6>Children Ages</h6> : ""
                                                }
                                                <div className="d-flex mt-4 mb-3">

                                                    {
                                                        item?.children > 0 ? roomsDetail?.[roomindex]?.paxes?.map((item, ageindex) => {
                                                            return (
                                                                <div className="addition-person"
                                                                //className="d-flex mt-4 mb-3"
                                                                >
                                                                    <select className="form-control"
                                                                        name='age'
                                                                        onChange={(e) => handleChange(e, roomindex, 'childAge', ageindex)}
                                                                    >
                                                                        {
                                                                            childAge?.map((age) => {
                                                                                return (
                                                                                    <option
                                                                                        //value={item} 
                                                                                        selected={age === Number(item?.age)}
                                                                                    >{age}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                </div>
                                                            )
                                                        }) : ""
                                                    }
                                                </div>
                                            </div>
                                            {
                                                roomindex < 9 ? <a>
                                                    <>
                                                        {
                                                            roomindex === roomsDetail.length - 1 ?
                                                                <>
                                                                    <div onClick={handleNumberOfRoomsAdd}>
                                                                        <span >
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                        Add more rooms
                                                                    </div>
                                                                    <button className="Button"
                                                                        onClick={roomsapply}
                                                                    >Apply</button>
                                                                </>
                                                                : <div onClick={(e) => handleChange(e, roomindex, 'remove')}>
                                                                    <span>
                                                                        <i className="fa-solid fa-minus" />
                                                                    </span>
                                                                    Remove Room
                                                                </div>
                                                        }

                                                    </>
                                                </a> : ""
                                            }

                                        </div>
                                    )
                                })
                                : ""
                        }
                        {/* <h4>Apply</h4>                               */}
                    </div>
                </div>
                <div className="form-group">
                    <a
                        className="ShowHotels"
                        to="/hotelList"
                        onClick={handleShowHotel}
                    >
                        <img src={images.showHotel} /> Show Hotels
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CommonHotelSearch