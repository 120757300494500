import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaticContent } from "../reduxToolKit/slices/staticContentApiSlice";
import Header2 from "./Header2";
import { isLoggedIn } from "../utils/LoggedIn";

const TermsAndConditions = () => {
    const terms_conditions = useSelector((state) => state?.staticContentData?.StaticContentData?.data?.[4])
    const dispatch = useDispatch()

    console.log('terms and condition', terms_conditions)

    const createAboutMarkup = () => {
        return { __html: terms_conditions?.description }
    }
    useEffect(()=>{
        dispatch(fetchStaticContent()) 
    },[])
    return (
        <div>
            {
                isLoggedIn("swtadmin") ? <Header2 /> : <Header />
            }
            <main id="main" class="ProductMain">
                <section>
                    <div class="FilterProductsArea">
                        <div class="AboutArea mt-5">
                            <div class="section-title">
                                <h1>Terms and conditions</h1>
                            </div>
                            <p dangerouslySetInnerHTML={createAboutMarkup()} />
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default TermsAndConditions