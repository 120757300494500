import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadImageFunction } from "../utils/uploadImage";
import { toast } from "react-toastify";
import { addQuotation } from "../reduxToolKit/slices/bookPackageApiSlice";
import { useDispatch } from "react-redux";

const initialState = {
  departure: "",
  destination: "",
  depart: "",
  numberofNights: "",
  hotelCategory: "",
  leadPassengerName: "",
  roomGuests: "",
  airPortTransfer: "",
  mealPlan: "",
  description: "",
  company: "",
  image: "",
  errorMsg: {},
};
const Quotation = () => {
  const { state } = useLocation();

  const [iState, updateState] = useState(initialState);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    departure,
    destination,
    depart,
    numberofNights,
    hotelCategory,
    leadPassengerName,
    roomGuests,
    airPortTransfer,
    mealPlan,
    description,
    company,
    image,
    errorMsg,
  } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleValidation = () => {
    let formIsValid = true;

    let errorMsg = {};

    if (!destination) {
      errorMsg.destination = "* Please enter destination";
      formIsValid = false;
    }

    if (!departure) {
      errorMsg.departure = "* Please select departure";
      formIsValid = false;
    }

    if (!depart) {
      errorMsg.depart = "* Please enter depart";
      formIsValid = false;
    }

    if (!numberofNights) {
      errorMsg.numberofNights = "* Please enter number of nights";
      formIsValid = false;
    }

    if (!hotelCategory) {
      errorMsg.hotelCategory = "* Please enter hotel category";
      formIsValid = false;
    }

    if (!leadPassengerName) {
      errorMsg.leadPassengerName = "* Please enter lead passenger name";
      formIsValid = false;
    }

    if (!roomGuests) {
      errorMsg.roomGuests = "* Please enter rooms and guests";
      formIsValid = false;
    }

    if (!airPortTransfer) {
      errorMsg.airPortTransfer = "* Please enter airport tarnafer";
      formIsValid = false;
    }

    if (!mealPlan) {
      errorMsg.mealPlan = "* Please enter meal plan";
      formIsValid = false;
    }

    if (!description) {
      errorMsg.description = "* Please enter description";
      formIsValid = false;
    }

    if (!image) {
      errorMsg.image = "* Please upload image";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;
  const onFileHandler = async (e) => {
    const file = e.target.files[0];
    const image = e.target.name;
    console.log("file", file, "image", image);
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        const uploadedImage = await uploadImageFunction(
          file,
          image,
          updateState,
          iState
        );
        console.log("Uploaded image", uploadedImage);
      } else {
        toast.error("Please upload in JPEG,PNG,JPG format Only", {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          imageLoading: false,
        });
      }
    }
  };

  const handleCheck = (name) => {
    updateState({ ...iState, departure: name });
  };

  const handleSubmit = () => {
    const data = {};
    if (handleValidation()) {
      dispatch(addQuotation(data)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Qotation added sucessfull", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState(initialState);

          navigate("/package_details", { state: state });
        }
      });
    }
  };

  return (
    <div>
      <main id="main" className="ProductMain">
        <section>
          <div className="SendQueryFilter">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>Packages Quotation:</h2>
                </div>
                <div className="d-flex">
                  <div className="form-group">
                    <label className="Radio">
                      {" "}
                      Group Departure
                      <input
                        type="radio"
                        defaultValue="Africa"
                        name="from"
                        onChange={() => handleCheck("Group Departure")}
                        defaultChecked=""
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group ml-4">
                    <label className="Radio">
                      {" "}
                      Customer Departure
                      <input
                        type="radio"
                        defaultValue="Africa"
                        name="from"
                        onChange={() => handleCheck("Customer Departure")}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                {errorMsg.departure && !departure && (
                  <p style={{ color: "red" }}>{errorMsg.departure}</p>
                )}
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Destination</h6>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={destination}
                    name="destination"
                    placeholder="Depart"
                  />
                </div>
                {errorMsg.destination && !destination && (
                  <p style={{ color: "red" }}>{errorMsg.destination}</p>
                )}
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <h6>Depart</h6>
                  <input
                    onChange={handleChange}
                    value={depart}
                    name="depart"
                    type="text"
                    className="form-control"
                    placeholder="Depart"
                  />
                  {errorMsg.depart && !depart && (
                    <p style={{ color: "red" }}>{errorMsg.depart}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Number of Nights</h6>
                  <input
                    type="number"
                    onChange={handleChange}
                    value={numberofNights}
                    name="numberofNights"
                    className="form-control"
                    placeholder="Number of Nights"
                  />
                  {errorMsg.numberofNights && !numberofNights && (
                    <p style={{ color: "red" }}>{errorMsg.numberofNights}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Hotel Category</h6>
                  <select
                    onChange={handleChange}
                    value={hotelCategory}
                    name="hotelCategory"
                    className="form-control"
                  >
                    <option value="">Choose Hotel Category</option>
                    <option value="Budget">Budget</option>
                    <option value="Luxury">Luxury</option>
                  </select>

                  {errorMsg.hotelCategory && !hotelCategory && (
                    <p style={{ color: "red" }}>{errorMsg.hotelCategory}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Lead Passenger Name</h6>
                  <input
                    type="text"
                    onChange={handleChange}
                    value={leadPassengerName}
                    name="leadPassengerName"
                    className="form-control"
                    placeholder="Lead Passenger Name"
                  />

                  {errorMsg.leadPassengerName && !leadPassengerName && (
                    <p style={{ color: "red" }}>{errorMsg.leadPassengerName}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Rooms and Guests</h6>
                  <input
                    type="text"
                    onChange={handleChange}
                    value={roomGuests}
                    name="roomGuests"
                    className="form-control"
                    placeholder="Rooms and Guests"
                  />

                  {errorMsg.roomGuests && !roomGuests && (
                    <p style={{ color: "red" }}>{errorMsg.roomGuests}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Airport Transfers</h6>
                  <input
                    type="text"
                    onChange={handleChange}
                    value={airPortTransfer}
                    name="airPortTransfer"
                    className="form-control"
                    placeholder="Rooms and Guests"
                  />

                  {errorMsg.airPortTransfer && !airPortTransfer && (
                    <p style={{ color: "red" }}>{errorMsg.airPortTransfer}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Meal Plan</h6>
                  <select
                    className="form-control"
                    onChange={handleChange}
                    value={mealPlan}
                    name="mealPlan"
                  >
                    <option selected="">Choose Meal Plan</option>

                    <option selected="Breakfast">Breakfast</option>
                    <option selected="Lunch">Lunch</option>
                    <option selected="Dinner">Dinner</option>
                  </select>

                  {errorMsg.mealPlan && !mealPlan && (
                    <p style={{ color: "red" }}>{errorMsg.mealPlan}</p>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <h6>Description</h6>
                  <textarea
                    onChange={handleChange}
                    value={description}
                    name="description"
                    id="editornew"
                    className="form-control"
                    rows={5}
                    defaultValue={""}
                  />

                  {errorMsg.description && !description && (
                    <p style={{ color: "red" }}>{errorMsg.description}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    onChange={handleChange}
                    value={company}
                    name="company"
                    className="form-control"
                    placeholder="Rooms and Guests"
                  />

                  {errorMsg.company && !company && (
                    <p style={{ color: "red" }}>{errorMsg.company}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <input type="file" name="image" onChange={onFileHandler} />
                </div>

                {image && <img src={image}  />}

                {errorMsg.image && !image && (
                  <p style={{ color: "red" }}>{errorMsg.image}</p>
                )}
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="SubmitBtn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Quotation;
