import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { images } from "../utils/images";
import { toast } from 'react-toastify';
import moment from 'moment';
import { packageApi } from '../reduxToolKit/slices/packageApiSlice';
import { isLoggedIn } from '../utils/LoggedIn';
const initialState = {
    packageCityName: "",
    packageStartDate: "",
    packageEndDate: "",
    errorMsg: {}
};
const PackageSearch = () => {
    const [iState, updateState] = useState(initialState)
    const { packageCityName, packageStartDate, packageEndDate, errorMsg, numberOfGuests } = iState
    const [confirmModal, setConfirm] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const MaxAdults = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const handleConfirmModalClose = () => {


        setConfirm(false);
    };
    const handleChangePackage = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }
    const handleValidation = () => {
        let flag = true;
        let errorMsg = {};


        if (!packageCityName) {
            flag = false;
            errorMsg.packageCityName = " Destination field is required";
        }

        if (!packageStartDate) {
            flag = false;
            errorMsg.packageStartDate = " Start date field is required";
        }

        if (!packageEndDate) {
            flag = false;
            errorMsg.packageEndDate = "End date field is required";
        }
        if (!numberOfGuests) {
            flag = false;
            errorMsg.numberOfGuests = "No. of guests is required";
        }

        updateState({ ...iState, errorMsg });

        return flag;
    };
    const handleSubmit = () => {
        if (isLoggedIn("swtadmin")) {
            let formIsValid = handleValidation();
            if (formIsValid) {

                setConfirm(true)



                dispatch(packageApi({ search: packageCityName, startDate: packageStartDate, endDate: packageEndDate })).then((res) => {



                    if (res.payload.data.total > 0) {
                        setConfirm(false)

                        navigate('/packages', { state: [packageCityName, packageStartDate, numberOfGuests] })

                    } else {
                        toast.error("No package found", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                        })

                    }

                })

            }
        } else {
            navigate('/agentLogin')
        }

    }
    return (
        <div>
            <div className="tab-content">
                <div className="tab-pane fade show active">
                    <div className="d-flex align-items-center pt-2">
                        <h5>Enter City, Area or Hotel Name</h5>
                    </div>
                    <div className="Filter">
                        <div className="form-group">
                            <div className="CommonInputBox">
                                <span className="CibLeftOverlay">
                                    <img src={images.hotelImg} />
                                </span>
                                <h6>Destination</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Destination"
                                    name='packageCityName'
                                    value={packageCityName}
                                    onChange={handleChangePackage}
                                />
                                <span className="CibRightOverlay">
                                    <img src={images.barIcon} />
                                </span>
                            </div>
                            {errorMsg.packageCityName && !packageCityName && (
                                <p style={{ color: "red" }}>{errorMsg.packageCityName}</p>
                            )}

                        </div>
                        <div className="form-group">
                            <div className="InputBox">
                                <h6>From</h6>
                                <input
                                    type="date"
                                    min={moment(new Date()).format("YYYY-MM-DD")}

                                    className="form-control-date"
                                    name="packageStartDate"
                                    value={packageStartDate}
                                    onChange={handleChangePackage}

                                />

                                <span className="FilterIcon">
                                    <img src={images.calendarBlueImg} />
                                </span>
                            </div>
                            {errorMsg.packageStartDate && !packageStartDate && (
                                <p style={{ color: "red" }}>{errorMsg.packageStartDate}</p>
                            )}
                        </div>
                        <div className="form-group">
                            <div className="InputBox">
                                <h6>To</h6>
                                <input
                                    type="date"

                                    className="form-control-date"

                                    disabled={!packageStartDate}



                                    name="packageEndDate"
                                    min={packageStartDate}

                                    value={packageEndDate}
                                    onChange={handleChangePackage}

                                />


                                <span className="FilterIcon">
                                    <img src={images.calendarBlueImg} />
                                </span>
                            </div>
                            {errorMsg.packageEndDate && !packageEndDate && (
                                <p style={{ color: "red" }}>{errorMsg.packageEndDate}</p>
                            )}
                        </div>

                        <div className="form-group">
                            <div className="InputBox SelectBox">
                                <span className="StaffIcon">
                                    <img src={images.userIcon} />
                                </span>
                                <h6>Guests</h6>
                                <select name="numberOfGuests" value={numberOfGuests} onChange={handleChangePackage}>
                                    <option selected="">select</option>
                                    {
                                        MaxAdults?.map((item) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </select>

                                <span className="FilterIcon">
                                    <img src={images.chevronDown} />
                                </span>
                            </div>

                            {errorMsg.numberOfGuests && !numberOfGuests && (
                                <p style={{ color: "red" }}>{errorMsg.numberOfGuests}</p>
                            )}
                        </div>
                        <div className="form-group">
                            <a onClick={handleSubmit} className="ShowHotels"

                            >
                                <img src={images.showHotel} /> Show Packages
                            </a>
                            {/* <Link >Show Package</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className="modalBox modalBoxNew"
                show={confirmModal}
                centered
                onHide={handleConfirmModalClose}
            >
                <Modal.Body>

                    <div class="TransferBox">
                        <div class="TransferHeader">
                            <h5>
                                <span><img src={require("../assests/images/Refresh.png")} /> </span>
                                Searching for {' '}
                                <strong>{packageCityName}</strong>
                            </h5>
                            <button type="button" data-dismiss="modal" onClick={handleConfirmModalClose}> ×</button>
                        </div>
                        <div class="TransferBody">
                            <a >
                                <div class="SearchInfo">
                                    <h6>
                                        <strong>Destination</strong>
                                        <span>{packageCityName}</span>
                                    </h6>
                                    <p><span><img src={require("../assests/images/Calender.png")} /> </span> {moment(packageStartDate)?.format('ll')} -{moment(packageEndDate)?.format('ll')}</p>
                                </div>
                            </a>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PackageSearch