import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const BookingDetail_Flight = () => {
  return (
    <div>
      <Header />
      <main id="main" className="ProductMain">
        <section>
          <div className="FilterProductsArea">
            <div className="breadcrumb mb-0">
              <ul>
                <li>
                  <a href="#">
                    Turkey{" "}
                    <span>
                      <img src="images/right-arrow.png" />
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Istanbul{" "}
                    <span>
                      <img src="images/right-arrow.png" />
                    </span>
                  </a>
                </li>
                <li>CVK Park Bosphorus Hotel Istanbul</li>
              </ul>
            </div>
            <div className="EnterCustomerDetailsHeading">
              <h4>Enter Passenger Details </h4>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="EnterCustomerDetails BottomPadding">
                  <h5>Adult 1</h5>
                  <form>
                    <div className="form-group ForName">
                      <div className="NameSelectBox">
                        <select>
                          <option>Mr.</option>
                        </select>
                        <span>
                          <i className="fa fa-angle-down" aria-hidden="true" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                      />
                    </div>
                    <div className="form-group">
                      <div className="NameSelectBox mr-0">
                        <select>
                          <option>Nationality</option>
                        </select>
                        <span>
                          <i className="fa fa-angle-down" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="CheckBox">
                        {" "}
                        Frequent Flyer (Optional)
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Frequent Flyer Airline"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Frequent Flyer Number"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="EnterCustomerDetails BottomPadding">
                  <h5>Children</h5>
                  <form>
                    <div className="form-group ForName">
                      <div className="NameSelectBox">
                        <select>
                          <option>Mr.</option>
                        </select>
                        <span>
                          <i className="fa fa-angle-down" aria-hidden="true" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                      />
                    </div>
                    <div className="form-group">
                      <div className="NameSelectBox mr-0">
                        <select>
                          <option>Nationality</option>
                        </select>
                        <span>
                          <i className="fa fa-angle-down" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Date of Birth"
                      />
                    </div>
                    <div className="form-group">
                      <label className="CheckBox">
                        {" "}
                        Frequent Flyer (Optional)
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Frequent Flyer Airline"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Frequent Flyer Number"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="EnterCustomerDetailsHeading">
                  <h4>Contact Information</h4>
                  <div className="EnterCustomerDetails BottomPadding">
                    <form>
                      <div className="form-group ForName">
                        <div className="NameSelectBox">
                          <select>
                            <option>+91</option>
                          </select>
                          <span>
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Mobile Number"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Id"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="EnterCustomerDetailsHeading">
                  <h4>GST number for business travel (optional)</h4>
                  <div className="EnterCustomerDetails BottomPadding GstNumber">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter GST Number"
                        />
                        <a
                          href="javascript:void(0);"
                          className="BrandBtn h-100"
                        >
                          Verify
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="DepartingFlightArea">
                  {/* <div class="DepartingFlightHeading">
                          <h6>Departing Flight</h6>
                          <a href="javascript:void(0);">Flight Details</a>
                      </div>
                      <div class="FlightBrandName">
                         <figure><img src="images/emirates_logo.png"></figure>
                         <p>Economy</p>
                         <h4>Emirates A380 Airbus</h4>
                      </div>
                      
                      <div class="BookingHotelChecks">
                          <div>
                              <h5>12:00 pm</h5>
                              <p>Delhi (DEL)</p>
                          </div>
                          <div>
                              <figure><img src="images/non_stop_img.png"></figure>
                          </div>
                          <div>
                              <h5>12:00 pm</h5>
                              <p>Chandigarh (IXC)</p>
                          </div>
                      </div>
                      <ul>
                          <li><span><img src="images/baggage_icon.png" alt="baggage"></span>7 kg  Cabin Baggage</li>
                          <li><span><img src="images/check_baggage_icon.png" alt="baggage"></span>15 kg 01 Check-in Baggage</li>
                      </ul> */}
                  <div className="DepartingFlightHeading">
                    <h6>Return Flight</h6>
                    <a href="javascript:void(0);">Flight Details</a>
                  </div>
                  <div className="FlightBrandName">
                    <figure>
                      <img src="images/emirates_logo.png" />
                    </figure>
                    <p>Economy</p>
                    <h4>Emirates A380 Airbus</h4>
                  </div>
                  <div className="BookingHotelChecks">
                    <div>
                      <h5>12:00 pm</h5>
                      <p>Delhi (DEL)</p>
                    </div>
                    <div>
                      <figure>
                        <img src="images/non_stop_img.png" />
                      </figure>
                    </div>
                    <div>
                      <h5>12:00 pm</h5>
                      <p>Chandigarh (IXC)</p>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span>
                        <img src="images/baggage_icon.png" alt="baggage" />
                      </span>
                      7 kg Cabin Baggage
                    </li>
                    <li>
                      <span>
                        <img
                          src="images/check_baggage_icon.png"
                          alt="baggage"
                        />
                      </span>
                      15 kg 01 Check-in Baggage
                    </li>
                  </ul>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Price Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Base Fare</td>
                        <td>$ 400</td>
                      </tr>
                      <tr>
                        <td>Taxes</td>
                        <td>$ 400</td>
                      </tr>
                      <tr>
                        <td>Service Fee</td>
                        <td>$ 400</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>$ 400</td>
                      </tr>
                    </tbody>
                  </table>
                  <label className="CheckBox">
                    I have read <strong>Terms &amp; conditions</strong> and{" "}
                    <strong>Cancellation Policies</strong>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                  <div className="PbdHotelName WalletBox">
                    <label className="CheckBox my-0">
                      Wallet
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <h5>
                      INR 200<span>(Add Money)</span>
                    </h5>
                  </div>
                </div>
                <div className="form-group">
                  <a href="javascript:void(0);" className="ConfirmBookigBtn">
                    Continue to Pay
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BookingDetail_Flight;
