import React from "react";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js";

const ShareDealsPage = () => {
  const { state } = useLocation();

  const downloadPoster = async () => {
    const posterImg = document.getElementById("poster");
    console.log("posterImg", posterImg);

    const pdfOptions = {
      margin: 10,
      filename: 'downloaded-file.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      imageOptions: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };

    const downloadedImage = await html2pdf().from(posterImg).set(pdfOptions).save();
    console.log('Downloaded image', downloadedImage);
  };

  return (
    <div>
      <section>
        <div className="ShareThisArea">
          <div className="container">
            <div className="ContactFormBody BrandDetails">
              <form>
                <div className="row">
                  <div className="col-md-7">
                    <div className="EnterCustomerDetails" id="poster">
                      <figure>
                        <img src={state?.flyerBanner} alt="poster" height='80px' width='70px'/>
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="EnterCustomerDetails ShareDealsBox">
                      <h4>Share this deal</h4>
                      <div className="form-group">
                        <h6>Enter the price</h6>
                        <input type="text" className="form-control" placeholder="Enter Price" />
                      </div>
                      <a className="ConfirmBookingBtn" onClick={downloadPoster}>Download Posters</a>
                      <p>* A single .zip file containing all images will be downloaded. You can unzip using free software like WinRar and share with your customers. For faster sharing, use TravClan App.</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShareDealsPage;

// import React from 'react'

// function ShareDealsPage() {
//   return (
//     <div>ShareDealsPage</div>
//   )
// }

// export default ShareDealsPage
