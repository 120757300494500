// countrySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const apiUrl = 'https://api.countrystatecity.in/v1/countries';

export const fetchCountries = createAsyncThunk('countries/fetchCountries', async () => {
  const response = await axios.get(apiUrl,{
    headers: { 
      'X-CSCAPI-KEY': 'TjFJOGV1d0FXZDQ0NU5NcnhlQkFmUnpXWlg1bEFBcUo4ejg3TmU3OQ=='
    }
  });
  return response.data;
});

const countrySlice = createSlice({
  name: 'countries',
  initialState: {
    countries: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.countries = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default countrySlice.reducer;
