import React, { useEffect } from 'react'
import { images } from '../utils/images'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHotelCartList } from '../reduxToolKit/slices/hotelCartListApiSlice'

const Header2 = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const hoteCartList = useSelector(state => state?.hotelCartList)
    const userData = useSelector(state => state?.userData?.userData?.data)
    console.log('user data', userData)

    const handleLogout = async () => {
        try {
            const token = JSON.parse(window.localStorage.getItem('swtadmin'))

            const result = await axios.get('https://swtclub.com:4300/api/v1/user/logout', {
                headers: {
                    "Authorization": token
                },
            })
            console.log('result', result)
            if (result?.data?.status === 200) {
                window.localStorage.clear()
                //window.localStorage.removeItem("swtadmin")

                toast.success('Logged Out Sucessfully', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                })
                navigate('/');
            }
        } catch (e) {
            if (e.response?.data.status === 401) {
                window.localStorage.clear()
                //window.localStorage.removeItem("swtadmin")

                toast.success('Logged Out Sucessfully', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                })
                navigate('/');
            }
        }
    }


    useEffect(() => {
        // debugger
        // console.log('user data', userData)
        dispatch(fetchHotelCartList())
    }, [])

    return (
        <div>
            <header className='header1'>
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="#">
                        <img src={images.logo} />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/indexLoggedIn">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">
                                    About Us
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" href="/">
                                    Services
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/packages"
                                >
                                    Package
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to='/contact'
                                >
                                    Contact Us
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/wallet' replace={true} >
                                    Wallet
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="UserLogin">
                        <Link className="Cart" to="/bookingDetail_Hotel">
                            <img src={images.shoppingCart} />
                            <span className="CartOverlay">{hoteCartList?.hoteCartList?.data?.length}</span>
                        </Link>
                        {/* <div className="Avater">
                            <ul>
                                <li>
                                    <figure>
                                        <img src={images.avatar} />
                                    </figure>
                                    <h4>
                                        {" "}
                                        SWT <span>Club</span>
                                    </h4>
                                </li>
                                <li>
                                    <a href="index.html">
                                        <span>
                                            <i className="fa fa-sign-out" />
                                        </span>{" "}
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div> */}

                    </div>
                    <div class="Navigation">
                        <div className="Avater">
                            <a href="javascript:void(0);">
                                <figure>
                                    <img src={images.adventureNew} />
                                </figure>

                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={images.adventureNew} />
                                    </figure>
                                    <h4>
                                        <span>{userData?.userName}</span>
                                    </h4>
                                </li>
                                <li>
                                    <Link to='/hotelListingHistory/hotels'>
                                        Bookings
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/brandDetails'>
                                        Brand Details
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/marketing'>
                                        Flyer
                                    </Link>
                                </li>
                                <li>
                                    <a onClick={handleLogout}>
                                        <span>
                                            <i className="fa fa-sign-out"></i>
                                        </span> Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="clear"></div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default Header2