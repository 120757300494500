import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { images } from "../utils/images";

const Activities = () => {
  return (
    <div>
      <Header />
      <main id="main" className="ProductMain FlightListingArea">
        <section>
          <div className="ProductFilter">
            <div className="TopMainBox mt-0">
              <div className="Filter">
                <div className="form-group">
                  <div className="CommonInputBox First">
                    <span className="CibLeftOverlay">
                      <img src={images.hotelImg} />
                    </span>
                    <h6>Country</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="DEL, Delhi"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonInputBox First">
                    <span className="CibLeftOverlay">
                      <img src={images.hotelImg} />
                    </span>
                    <h6>Destination</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="IXC, Chandigarh"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="InputBox">
                    <h6>From</h6>
                    <input type="date" className="form-control-date" />
                    <span className="FilterIcon">
                      <img src={images.calendarBlueImg} />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="InputBox">
                    <h6>To</h6>
                    <input type="date" className="form-control-date" />
                    <span className="FilterIcon">
                      <img src={images.calendarBlueImg} />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="InputBox SelectBox">
                    <span className="StaffIcon">
                      <img src={images.userIcon} />
                    </span>
                    <h6>Guests</h6>
                    <select>
                      <option>2 Guests</option>
                      <option>1 Guests</option>
                    </select>
                    <span className="FilterIcon">
                      <img src={images.chevronDown} />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <a href="javascript:void(0);" className="SearchBtn w-100">
                    <img src={images.destinationWhite} />
                    Show Activities
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="FilterLeftArea">
                <h4>Filters</h4>
                <div className="LeftCommonBox">
                  <div className="LeftCommonContent">
                    <div className="form-group">
                      <div className="CommonInputBox Custom">
                        <span className="CibLeftOverlay">
                          <img src={images.hotelImg} />
                        </span>
                        <h6>Activity Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Taj, Mumbai"
                        />
                        <span className="CibRightOverlay">
                          <i className="fa fa-search" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="LeftCommonBox">
                  <div
                    className="LeftCommonHeading"
                    data-toggle="collapse"
                    href="#Categories"
                  >
                    <h6>Categories</h6>
                    <span>
                      <i className="fa fa-angle-up" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="collapse show multi-collapse" id="Categories">
                    <div className="card card-body">
                      <div className="LeftCommonContent">
                        <label className="CheckBox">
                          {" "}
                          Zoo, Aquarium &amp; Nature (1)
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          {" "}
                          Art &amp; culture (2)
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="LeftCommonBox">
                  <div
                    className="LeftCommonHeading"
                    data-toggle="collapse"
                    href="#Duration"
                  >
                    <h6>Duration</h6>
                    <span>
                      <i className="fa fa-angle-up" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="collapse show multi-collapse" id="Duration">
                    <div className="card card-body">
                      <div className="LeftCommonContent">
                        <label className="CheckBox">
                          {" "}
                          Half-day morning (2)
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          {" "}
                          Full day (1)
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="LeftCommonBox">
                  <div
                    className="LeftCommonHeading"
                    data-toggle="collapse"
                    href="#Price"
                  >
                    <h6>Price</h6>
                    <span>
                      <i className="fa fa-angle-up" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="collapse show multi-collapse" id="Price">
                    <div className="card card-body">
                      <div className="LeftCommonContent">
                        <figure>
                          <img
                            src={images.priceRange}
                            className="w-100"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="LeftCommonBox">
                  <div
                    className="LeftCommonHeading"
                    data-toggle="collapse"
                    href="#Service"
                  >
                    <h6>Service</h6>
                    <span>
                      <i className="fa fa-angle-up" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="collapse show multi-collapse" id="Service">
                    <div className="card card-body">
                      <div className="LeftCommonContent">
                        <label className="CheckBox">
                          {" "}
                          Free breakfast
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          {" "}
                          Free breakfast
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          {" "}
                          Free internet
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          {" "}
                          Free airport shuttle
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="ShowingRightArea">
                <div className="ShowingPlacesRecommend">
                  <h6>
                    Showing 4 of <span>257 places</span>
                  </h6>
                  <h6>
                    Sort by
                    <select>
                      <option selected="">Recommended</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                    </select>
                  </h6>
                </div>
                <div className="ProductBox">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="ProductLeft">
                        <figure>
                          <img src={images.interior} />
                        </figure>
                        <div className="ProductLeftOverlay">
                          <div className="PloImages">
                            <figure>
                              <img src={images.small_1} />
                            </figure>
                            <figure>
                              <img src={images.small_2} />
                            </figure>
                            <figure>
                              <img src={images.small_3} />
                            </figure>
                            <figure>
                              <img src={images.small_4} />
                            </figure>
                            <figure>
                              <img src={images.small_5} />
                            </figure>
                          </div>
                          <p>9 Images</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ProductRight">
                        <div className="ProductDetails">
                          <h6>Sahakari Spice Farm - Private Tour</h6>
                          <p>
                            <i className="fa-solid fa-location-dot" />
                            1995 Broadway, New York
                          </p>
                          <div className="Zoonature">
                            <span>Zoo, Aquarium &amp; Nature: </span>
                            <p>
                              Depart from your hotel and drive to Sahakari Spice
                              farm. A place for relaxation under the dense
                              forest cover, an aroma of a variety of genuine
                              spices coming
                            </p>
                          </div>
                          <div className="ProductRightOverlay Option">
                            <div className="d-flex align-items-center">
                              <span>
                                <img src={images.heart} />
                              </span>
                              <a
                                href="hotel-listing-activityflow.html"
                                className="AddToCartBtn"
                              >
                                Select Option
                              </a>
                            </div>
                            <h6>1 Option Available</h6>
                          </div>
                        </div>
                        <div className="PrpArea">
                          <span className="StartingFrom">Starting From</span>
                          <div className="PrpBox">
                            <div className="d-flex">
                              <span className="SpanBlue">
                                Half-day morning, 6 hours{" "}
                                <img src="images/esclamination.png" />{" "}
                              </span>
                              <span className="SpanBlue">Guide in English</span>
                            </div>
                            <h2>
                              $3,000 <span>/month Excl. tax</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="ProductBox">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="ProductLeft">
                        <figure>
                          <img src="images/interior.png" />
                        </figure>
                        <div className="ProductLeftOverlay">
                          <div className="PloImages">
                            <figure>
                              <img src="images/small_01.png" />
                            </figure>
                            <figure>
                              <img src="images/small_02.png" />
                            </figure>
                            <figure>
                              <img src="images/small_03.png" />
                            </figure>
                            <figure>
                              <img src="images/small_04.png" />
                            </figure>
                            <figure>
                              <img src="images/small_05.png" />
                            </figure>
                          </div>
                          <p>9 Images</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ProductRight">
                        <div className="ProductDetails">
                          <h6>Sahakari Spice Farm - Private Tour</h6>
                          <p>
                            <i className="fa-solid fa-location-dot" />
                            1995 Broadway, New York
                          </p>
                          <div className="Zoonature">
                            <span>Zoo, Aquarium &amp; Nature: </span>
                            <p>
                              Depart from your hotel and drive to Sahakari Spice
                              farm. A place for relaxation under the dense
                              forest cover, an aroma of a variety of genuine
                              spices coming
                            </p>
                          </div>
                          <div className="ProductRightOverlay Option">
                            <div className="d-flex align-items-center">
                              <span>
                                <img src="images/heart.png" />
                              </span>
                              <a
                                href="hotel-listing-activityflow.html"
                                className="AddToCartBtn"
                              >
                                Select Option
                              </a>
                            </div>
                            <h6>1 Option Available</h6>
                          </div>
                        </div>
                        <div className="PrpArea">
                          <span className="StartingFrom">Starting From</span>
                          <div className="PrpBox">
                            <div className="d-flex">
                              <span className="SpanBlue">
                                Half-day morning, 6 hours{" "}
                                <img src="images/esclamination.png" />{" "}
                              </span>
                              <span className="SpanBlue">Guide in English</span>
                            </div>
                            <h2>
                              $3,000 <span>/month Excl. tax</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ProductBox">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="ProductLeft">
                        <figure>
                          <img src="images/interior.png" />
                        </figure>
                        <div className="ProductLeftOverlay">
                          <div className="PloImages">
                            <figure>
                              <img src="images/small_01.png" />
                            </figure>
                            <figure>
                              <img src="images/small_02.png" />
                            </figure>
                            <figure>
                              <img src="images/small_03.png" />
                            </figure>
                            <figure>
                              <img src="images/small_04.png" />
                            </figure>
                            <figure>
                              <img src="images/small_05.png" />
                            </figure>
                          </div>
                          <p>9 Images</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ProductRight">
                        <div className="ProductDetails">
                          <h6>Sahakari Spice Farm - Private Tour</h6>
                          <p>
                            <i className="fa-solid fa-location-dot" />
                            1995 Broadway, New York
                          </p>
                          <div className="Zoonature">
                            <span>Zoo, Aquarium &amp; Nature: </span>
                            <p>
                              Depart from your hotel and drive to Sahakari Spice
                              farm. A place for relaxation under the dense
                              forest cover, an aroma of a variety of genuine
                              spices coming
                            </p>
                          </div>
                          <div className="ProductRightOverlay Option">
                            <div className="d-flex align-items-center">
                              <span>
                                <img src="images/heart.png" />
                              </span>
                              <a
                                href="hotel-listing-activityflow.html"
                                className="AddToCartBtn"
                              >
                                Select Option
                              </a>
                            </div>
                            <h6>1 Option Available</h6>
                          </div>
                        </div>
                        <div className="PrpArea">
                          <span className="StartingFrom">Starting From</span>
                          <div className="PrpBox">
                            <div className="d-flex">
                              <span className="SpanBlue">
                                Half-day morning, 6 hours{" "}
                                <img src="images/esclamination.png" />{" "}
                              </span>
                              <span className="SpanBlue">Guide in English</span>
                            </div>
                            <h2>
                              $3,000 <span>/month Excl. tax</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ProductBox">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="ProductLeft">
                        <figure>
                          <img src="images/interior.png" />
                        </figure>
                        <div className="ProductLeftOverlay">
                          <div className="PloImages">
                            <figure>
                              <img src="images/small_01.png" />
                            </figure>
                            <figure>
                              <img src="images/small_02.png" />
                            </figure>
                            <figure>
                              <img src="images/small_03.png" />
                            </figure>
                            <figure>
                              <img src="images/small_04.png" />
                            </figure>
                            <figure>
                              <img src="images/small_05.png" />
                            </figure>
                          </div>
                          <p>9 Images</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ProductRight">
                        <div className="ProductDetails">
                          <h6>Sahakari Spice Farm - Private Tour</h6>
                          <p>
                            <i className="fa-solid fa-location-dot" />
                            1995 Broadway, New York
                          </p>
                          <div className="Zoonature">
                            <span>Zoo, Aquarium &amp; Nature: </span>
                            <p>
                              Depart from your hotel and drive to Sahakari Spice
                              farm. A place for relaxation under the dense
                              forest cover, an aroma of a variety of genuine
                              spices coming
                            </p>
                          </div>
                          <div className="ProductRightOverlay Option">
                            <div className="d-flex align-items-center">
                              <span>
                                <img src="images/heart.png" />
                              </span>
                              <a
                                href="hotel-listing-activityflow.html"
                                className="AddToCartBtn"
                              >
                                Select Option
                              </a>
                            </div>
                            <h6>1 Option Available</h6>
                          </div>
                        </div>
                        <div className="PrpArea">
                          <span className="StartingFrom">Starting From</span>
                          <div className="PrpBox">
                            <div className="d-flex">
                              <span className="SpanBlue">
                                Half-day morning, 6 hours{" "}
                                <img src="images/esclamination.png" />{" "}
                              </span>
                              <span className="SpanBlue">Guide in English</span>
                            </div>
                            <h2>
                              $3,000 <span>/month Excl. tax</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="form-group">
                  <a href="javascript:void(0);" className="ShowMore">
                    Show more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Activities;
