import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const loginApiSlice = createAsyncThunk(
  "loginApi/postloginApi",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.post(
      `https://swtclub.com:4300/api/v1/user/signIn`,
      payload
    );
    return response.data
      ;
  }
);

const initialState = {
  userData: [],
  status: 'idle',
  error: null,
}
const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginApiSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginApiSlice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userData = action.payload;
      })
      .addCase(loginApiSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userDataSlice.reducer;