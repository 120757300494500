import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const BookingDetail_Activity = () => {
  return (
    <div>
      <Header />
      <main id="main" className="ProductMain">
        <section>
          <div className="FilterProductsArea">
            <span className="FilterProductsAreaOverlay">
              3 products added to the cart
            </span>
            <div className="row">
              <div className="col-md-6">
                <div className="BookingHotelDetailsArea">
                  <h2>Flensburg Private Walking Tour</h2>
                  <div className="HotelNameRatings">
                    <figure>
                      <img src="images/flensburg.png" />
                    </figure>
                    <h6 className="Heading">
                      City tours, Day Trips &amp; Excursions, Art &amp; culture
                    </h6>
                    <p>
                      <img src="images/location.png" />
                      Flensburg, Germany
                    </p>
                  </div>
                  <div className="BookingHotelChecks">
                    <div>
                      <h5>Thursday, Dec 8</h5>
                      <p>Start Date</p>
                    </div>
                    <div>
                      <figure>
                        <img src="images/img-10.png" />
                      </figure>
                    </div>
                    <div>
                      <h5>Friday, Dec 9</h5>
                      <p>End Date</p>
                    </div>
                  </div>
                  <h5 className="Watch">
                    <img src="images/watch-icon.png" />
                    <span>Half-day morning, Half-day afternoon,</span> 1.5 hours
                  </h5>
                </div>
                <div className="EnterCustomerDetails">
                  <h4>Enter Hotel Details </h4>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Hotel Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Hotel Address"
                      />
                    </div>
                    <div className="EnterHotelDetails">
                      <h6>Remarks</h6>
                      <p>
                        Meeting point: Saint Nikolai Church, Nikolaikirchhof 8,
                        24937 Flensburg, Germany // Start/Opening time:
                        depending on option selected // End/Closing time:
                        depending on option selected // End point: Catholic
                        church of St. Mary, Nordergraben 36, 24937 Flensburg,
                        Germany // Duration:1,5 hours // Inclusions: Private
                        Local Guide // Exclusions: Gratuities, Entrances //
                        Supplier name: Travmonde // Supplier emergency phone:
                        +49 802 137 39 008. +49 802 137 390 003. +49 802 137 390
                        005 (9am-6pm) // Voucher type: Printed or E-voucher.
                        Print and bring the voucher or show the voucher on your
                        mobile device to enjoy the activity //
                      </p>
                    </div>
                  </form>
                </div>
                <div className="EnterCustomerDetails">
                  <h4>Enter Customer Details</h4>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Full Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email Address"
                      />
                    </div>
                    <p>
                      We’ll call or text you to confirm your number. Standard
                      message and data rates apply. Privacy Policy
                    </p>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <div className="PriceBookingDetails">
                  <div className="EmptyCartBox">
                    <p>
                      <img src="images/clock_icon.png" />
                      Expires in <span>03:00</span>
                    </p>
                    <span className="EmptyCart">Empty Cart</span>
                  </div>
                  <div className="PbdHotelName">
                    <h6>CVK Park Bosphorus Hotel Istanbul</h6>
                    <h5>
                      INR 200<span>(Remove)</span>
                    </h5>
                  </div>
                  <div className="PbdHotelName">
                    <h6>CVK Park Bosphorus Hotel Istanbul</h6>
                    <h5>
                      INR 200<span>(Remove)</span>
                    </h5>
                  </div>
                  <label className="CheckBox">
                    I have read <strong>Terms &amp; conditions</strong> and{" "}
                    <strong>Cancellation Policies</strong>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Price Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Amount</td>
                        <td>INR 60</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>INR 0</td>
                      </tr>
                      <tr>
                        <td>Service Fee</td>
                        <td>INR 25</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td>INR 2650</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="PbdHotelName WalletBox">
                    <label className="CheckBox my-0">
                      Wallet
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <h5>
                      INR 200<span>(Add Money)</span>
                    </h5>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#BookingConfirmed"
                  className="ConfirmBookigBtn"
                >
                  Confirm the Booking
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BookingDetail_Activity;
