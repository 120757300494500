import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../reduxToolKit/slices/countryListApiSlice";
import { fetchStates } from "../reduxToolKit/slices/stateListApiSlice";
import { fetchCities } from "../reduxToolKit/slices/cityListApiSlice";
import { useNavigate } from "react-router-dom";

const PackageModal = ({ setShowPackageModel, showPackageModal }) => {
  const dispatch = useDispatch();
  const openPackageModal = () => {
    setShowPackageModel(true);
  };
  const closePackageModal = () => {
    setShowPackageModel(false);
  };

  const countries = useSelector((state) => state.countries.countries);
  const states = useSelector((state) => state.states.states);
  const cities = useSelector((state) => state.cities.cities);
  const navigate = useNavigate()

  const [iState, updateState] = useState({ preferredCountry: "", preferredState:"", preferredCity:"" });

  const { preferredCountry, preferredCity, preferredState } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);


  useEffect(() => {
    if (preferredCountry) {
      dispatch(fetchStates(preferredCountry));
    }
  }, [preferredCountry]);

  useEffect(() => {
    if (preferredCountry && preferredState) {
      dispatch(fetchCities({ preferredCountry, preferredState }));
    }
  }, [preferredCountry, preferredState]);



    
  useEffect(() => {
    if (preferredCity) {
        navigate('/packages',{state:preferredCity})
    }
  }, [preferredCity]);


  return (
    <div>
      <Modal
        className="ModalBox"
        show={showPackageModal}
        // onHide={false}
      >
        <Modal.Body>
     
          <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"> <span><i class="fa-solid fa-check"></i></span> Booking Confirmed</h5>
                        <button type="button" class="close" data-dismiss="modal"  onClick={closePackageModal}  aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
          <div class="ModalContent">
            <div class="Heading">
              <h3> Countries</h3>
            </div>
            <select
              className="form-control"
              name="preferredCountry"
              value={preferredCountry}
              onChange={handleChange}
            >
              <option value="">Select Country</option>
              {countries?.length > 0 &&
                countries?.map((item, ind) => {
                  return (
                    <option key={ind} value={item.iso2}>
                      {item.name}
                    </option>
                  );
                })}
            </select>

            <div class="Heading">
              <h3> States</h3>
            </div>

            <select
                        className="form-control"
                        name="preferredState"
                        value={preferredState}
                        onChange={handleChange}
                      >
                        <option>Select Preferred State</option>
                        {states?.length > 0
                          ? states?.map((item, ind) => {
                            return (
                              <option key={ind} value={item.iso2}>
                                {item.name}
                              </option>
                            );
                          })
                          : "No data found"}
                      </select>

                      <div class="Heading">
              <h3> City</h3>
            </div>
            <select
                        className="form-control"
                        name="preferredCity"
                        value={preferredCity}
                        onChange={handleChange}
                      >
                        <option value="">Select Preferred City</option>
                        {cities?.length > 0
                          ? cities?.map((item, ind) => {
                            return (
                              <option key={ind} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })
                          : "No data found"}
                      </select>
{/* 
            <div class="CountriesArea">
              <input
                type="text"
                class="form-control"
                placeholder="Destinations"
              />
              <input type="text" class="form-control" placeholder="Zones" />
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PackageModal;
