
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../utils/LoggedIn';


export const fetchhotelBookingHistoryList = createAsyncThunk('hotelBookingHistory/fetchhotelBookingHistoryList', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`https://swtclub.com:4300/api/v1/user/hotel/list`, {
        headers: {
            "Authorization": isLoggedIn("swtadmin")
        },
    });
    return response.data;
});

const initialState = {
    hotelBookingHistoryList: [],
    status: 'idle',
    error: null,
}
const hotelBookingHistorySlice = createSlice({
    name: 'hotelCartList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchhotelBookingHistoryList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchhotelBookingHistoryList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.hotelBookingHistoryList = action.payload;
            })
            .addCase(fetchhotelBookingHistoryList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default hotelBookingHistorySlice.reducer;
