import React from 'react';

const Card = ({ item, addToCart, images, itemCode, itemName }) => {

    console.log('itemCode', itemCode, 'itemName', itemName)
    const cardStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#F1F1F1',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '16px 0',
        padding: '16px',
        borderRadius: '10px',
        width: '100%',
    };

    const sectionStyle = {
        margin: '0 16px',
    };

    const priceStyle = {
        color: '#050C9C',
        fontWeight: 'bold',
    };

    const cancellationStyle = {
        color: 'green',
    };

    const buttonStyle = {
        background: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        fontSize: '16px',
    };

    const iconStyle = {
        marginRight: '8px',
    };

    console.log('Item in Hotel Detail Card', item)

    return (
        <>
            <div className='NewCardStyle'>
                <h6 className='Occupancy'>Occupancy <br /> <span>{item?.adults}</span></h6>
                <h2 className='Purple'>{item?.boardName} <br />
                    <span className='LightBlue'>
                        {/* <img src={images.flightSvgRepo} alt="Flight" width="24" height="24" /> */}
                        {item?.packaging ? (
                        <>
                            <img src={images.flightSvgRepo} alt="Flight" width="24" height="24" />
                            <span>Product for packaging</span>
                        </>
                    ) : (
                        'Not For Packaging'
                    )}</span>
                </h2>
                <h2 className='Blue'>INR {item?.net}<span>/night Excl. tax</span><br />
                    <span className='Green'> {item?.cancellationPolicies?.length < 1
                        ? 'Non Refundable'
                        : `${item?.cancellationPolicies?.[0]?.amount} Cancellation Charges`}</span>
                </h2>
                <button onClick={() => addToCart(itemCode, itemName, item?.rateKey, item?.net, item?.adults)}><i class="fa-solid fa-cart-shopping"></i>Add to Cart</button>
            </div>
            {/* <div style={cardStyle}>
            <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                <span>Occupancy <h3 style={{color:'#940B92',fontWeight:'400', }}>{item?.adults}</h3></span>
            </div>
            <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                <span> <h4 style={{color:'#940B92',fontWeight:'400', }}>{item?.boardName}</h4></span>
            </div>
            <div style={{ ...sectionStyle, color: item?.packaging ? 'blue' : 'gray', display: 'flex', alignItems: 'center' }}>
                {item?.packaging ? (
                    <>
                        <img src={images.flightSvgRepo} alt="Flight" width="24" height="24" />
                        <span>Product for packaging</span>
                    </>
                ) : (
                    'Not For Packaging'
                )}
            </div>
            <div style={{ ...sectionStyle, ...priceStyle }}>
                INR {item?.net}<span>/night Excl. tax</span>
            </div>
            <div style={{ ...sectionStyle, ...cancellationStyle }}>
                {item?.cancellationPolicies?.length < 1
                    ? 'Non Refundable'
                    : `${item?.cancellationPolicies?.[0]?.amount} Cancellation Charges`}
            </div>
            <button
                style={buttonStyle}
                //onClick={() => addToCart(item?.code, item?.name, item?.rates?.[0]?.rateKey, item?.rates?.[0]?.net, item?.adults)}
                onClick={() => addToCart(itemCode, itemName, item?.rateKey, item?.net, item?.adults)}
            >
                <i className="fa-solid fa-cart-shopping" style={iconStyle} />
                Add to Cart
            </button>
        </div> */}
        </>
    );
};

export default Card;
