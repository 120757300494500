// citySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { privateData } from '../../config/apiKey';
import { xSignature } from '../../config/x-signature';
import signature from '../../config/signature';

// const apiUrl = 'https://api.example.com/cities';

export const fetchHotelDetail = createAsyncThunk('hotelDetail/fetchHotelDetail', async (payload) => {
  console.log('payload===>',payload)
  const response = await axios.get(`${privateData["proxy-url"]}/hotel-content-api/1.0/hotels/${payload}/details`,{
    headers: {
      "Api-key": privateData["Api-key"],
      "X-Signature": signature() ,
    },
  });
  return response.data;
});

const hotelDetailSlice = createSlice({
  name: 'hotelDetail',
  initialState: {
    hotelDetails: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelDetail.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchHotelDetail.fulfilled, (state, action) => {
        state.status = true;
        state.hotelDetails = action.payload;
      })
      .addCase(fetchHotelDetail.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export default hotelDetailSlice.reducer;
