// citySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// const apiUrl = 'https://api.example.com/cities';

export const fetchCities = createAsyncThunk('cities/fetchCities', async (payload) => {
  console.log('payload===>',payload)
  const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${payload.preferredCountry}/states/${payload.preferredState}/cities`,{
    headers: { 
      'X-CSCAPI-KEY': 'TjFJOGV1d0FXZDQ0NU5NcnhlQkFmUnpXWlg1bEFBcUo4ejg3TmU3OQ=='
    }
  });
  return response.data;
});

const initialState = {
  cities: [],
  status: 'idle',
  error: null,
}
const citySlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cities = action.payload;
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default citySlice.reducer;
