export function isLoggedIn(userType) {
    let session = getObject(userType) || {};
    session = Object.keys(session).length && JSON.parse(session)
//   console.log("ssessioness",session)
//     let accessToken = (session?.data?.token) || "";
      console.log(session)
    return session;
  }
  
  
  
  
  export function getObject(key) {
    if (window && window.localStorage) {
        console.log(window.localStorage.getItem(key))
        return window.localStorage.getItem(key);
        // return true
    }else{
        return false
    }
  }