import React, { useState } from 'react'
import { images } from '../utils/images'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { forgotPasswordApi } from '../reduxToolKit/slices/forgotPasswordApiSlice'
import { toast } from 'react-toastify'
import Header from './Header'

const initialState = {
    email: ""
}

const ForgotPassword = () => {
    const [iState, updateState] = useState(initialState)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { email } = iState
    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            email,
        }
        dispatch(forgotPasswordApi(data)).then((res) => {
            console.log('response of forgot password api', res)
            if (res?.payload?.status === 200) {
                toast.success(res?.payload?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                })
                 navigate('/otp', { state: { otp: res?.payload, email } })
            } else {
                toast.error(res?.payload?.message ?? "AN Error Occured", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                })
            }
        })
    }
    return (
        <div>
            <Header/>
            <>
                <main id="main">
                    <section className="hero" id="hero">
                        <video autoPlay="" muted="" loop="" width="100%">
                            <source src={images.bannerVideo} type="video/mp4" />
                        </video>
                        <div
                            className="hero-content animate fadeInUp"
                            data-animate="fadeInUp"
                            data-duration="1.5s"
                            data-delay="0.3s"
                        >
                            <h1>
                                Book Travel Boldly With SWT CLUB <br />
                                <span>A Trusted Global B2B travel platform</span>
                            </h1>
                        </div>
                        {/* <a href="sign-up.html" class="SignUpBtn">Sign Up</a> */}
                    </section>
                </main>
                <section>
                    <div className="ContactFormArea ContactArea">
                        <div className="container">
                            <div className="ContactFormHead TitleBox">
                                <h3>Login</h3>
                                <span>Forgot Password</span>
                            </div>

                            <form
                                onSubmit={handleSubmit}
                            >
                                <div className="ContactFormBody">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Your Email"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                />
                                                {/* <span style={{ color: "red" }} className="d-flex">
                        {errors?.emailIdError}
                      </span> */}
                                            </div>
                                            <div className="form-group">
                                                <button type="submit">Send OTP</button>
                                            </div>
                                            <p className="signIn">
                                                Don't have account{" "}
                                                <Link to="/signUp"> Sign Up</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </>
        </div>
    )
}

export default ForgotPassword