import React from 'react'
import { useLocation } from 'react-router-dom'

const Performa = () => {
    const { state } = useLocation()
    console.log('state in performa', state)
    return (
        <div>
            <table
                cellSpacing={0}
                cellPadding={0}
                border={0}
                style={{ width: "100%", margin: "auto", backgroundColor: "#fff" }}
            >
                <tbody>
                    <tr>
                        <td style={{ padding: 0 }}>
                            <table
                                cellSpacing={0}
                                cellPadding={0}
                                border={0}
                                style={{ width: "100%", margin: "auto" }}
                            >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: 0, width: "100%" }}>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <figure style={{ margin: "0 0 10px 0" }}>
                                                                <img src="https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/Logo.png" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: "100%",
                                                                textAlign: "left",
                                                                backgroundColor: "#fff"
                                                            }}
                                                        >
                                                            <div
                                                                className="Gray"
                                                                style={{ textAlign: "center", padding: "15px 0px" }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontSize: 16,
                                                                        fontWeight: 700,
                                                                        lineHeight: 22
                                                                    }}
                                                                >
                                                                    Voucher / Accommodation
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        fontSize: 13,
                                                                        fontWeight: 700,
                                                                        lineHeight: 22
                                                                    }}
                                                                >
                                                                    Booking confirmed and guaranteed - Voucher - Hotel
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "100%", padding: 5 }}>
                                                            <table
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "100%" }}>
                                                                            <table style={{ width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{
                                                                                                backgroundColor: "#db7a03",
                                                                                                textAlign: "center",
                                                                                                padding: "10px 10px",
                                                                                                width: "30%"
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    color: "#fff",
                                                                                                    fontSize: 16,
                                                                                                    fontWeight: 700,
                                                                                                    margin: "0 0 10px 0"
                                                                                                }}
                                                                                            >
                                                                                                Reference number:
                                                                                            </p>
                                                                                            <h5
                                                                                                style={{
                                                                                                    color: "#000",
                                                                                                    fontSize: 25,
                                                                                                    fontWeight: 800,
                                                                                                    margin: "0 0 7px 0"
                                                                                                }}
                                                                                            >
                                                                                                {state?.fullResponse?.fullResponse?.reference}
                                                                                            </h5>
                                                                                            <h6>Valid for the hotel</h6>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "25px 20px",
                                                                                                width: "70%",
                                                                                                backgroundColor: "#fff0de"
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: "0 0 10px 0",
                                                                                                    fontSize: 16,
                                                                                                    color: "#db7a03"
                                                                                                }}
                                                                                            >
                                                                                                <strong style={{ color: "#000" }}>
                                                                                                    {state?.fullResponse?.fullResponse?.hotel?.name}{" "}
                                                                                                </strong>
                                                                                                <i
                                                                                                    className="fa fa-star"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                <i
                                                                                                    className="fa fa-star"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                <i
                                                                                                    className="fa fa-star"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                <i
                                                                                                    className="fa fa-star"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                <span
                                                                                                    style={{
                                                                                                        border: "1px solid",
                                                                                                        padding: "5px 15px",
                                                                                                        borderRadius: 5,
                                                                                                        display: "inline-block"
                                                                                                    }}
                                                                                                >
                                                                                                    Includes 4-star hotels, 4-superior
                                                                                                    and 4 de luxe,
                                                                                                </span>
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: "0 0 10px 0",
                                                                                                    fontSize: 13,
                                                                                                    color: "#545353"
                                                                                                }}
                                                                                            >
                                                                                                <strong style={{ color: "#000" }}>
                                                                                                    Passenger name :{" "}
                                                                                                </strong>
                                                                                                <span>----</span>
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: "0 0 10px 0",
                                                                                                    fontSize: 13,
                                                                                                    color: "#545353"
                                                                                                }}
                                                                                            >
                                                                                                <strong style={{ color: "#000" }}>
                                                                                                    File To :{" "}
                                                                                                </strong>
                                                                                                <span>----</span>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{ width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ padding: 10 }}>
                                                                                            <p
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    fontWeight: 600,
                                                                                                    color: "#545353"
                                                                                                }}
                                                                                            >
                                                                                                <strong style={{ color: "#000" }}>
                                                                                                    From :{" "}
                                                                                                </strong>{" "}
                                                                                                03/06/2024
                                                                                                <strong style={{ color: "#000" }}>
                                                                                                    To :{" "}
                                                                                                </strong>{" "}
                                                                                                07/06/2024
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{ width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th
                                                                                            style={{
                                                                                                textAlign: "left",
                                                                                                fontSize: 13,
                                                                                                color: "#000",
                                                                                                fontWeight: 800,
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            Units
                                                                                        </th>
                                                                                        <th
                                                                                            style={{
                                                                                                textAlign: "left",
                                                                                                fontSize: 13,
                                                                                                color: "#000",
                                                                                                fontWeight: 800,
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            Room type
                                                                                        </th>
                                                                                        <th
                                                                                            style={{
                                                                                                textAlign: "left",
                                                                                                fontSize: 13,
                                                                                                color: "#000",
                                                                                                fontWeight: 800,
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            Adults
                                                                                        </th>
                                                                                        <th
                                                                                            style={{
                                                                                                textAlign: "left",
                                                                                                fontSize: 13,
                                                                                                color: "#000",
                                                                                                fontWeight: 800,
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            Children
                                                                                        </th>
                                                                                        <th
                                                                                            style={{
                                                                                                textAlign: "left",
                                                                                                fontSize: 13,
                                                                                                color: "#000",
                                                                                                fontWeight: 800,
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            Children Ages
                                                                                        </th>
                                                                                        <th
                                                                                            style={{
                                                                                                textAlign: "left",
                                                                                                fontSize: 13,
                                                                                                color: "#000",
                                                                                                fontWeight: 800,
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            Board
                                                                                        </th>
                                                                                    </tr>
                                                                                    {
                                                                                        state?.fullResponse?.fullResponse?.hotel?.rooms?.map((item) => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                            fontSize: 13,
                                                                                                            color: "#505050",
                                                                                                            fontWeight: 600,
                                                                                                            padding: 10,
                                                                                                            borderTop: "1px solid #ddd"
                                                                                                        }}
                                                                                                    >
                                                                                                        1x
                                                                                                    </td>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                            fontSize: 13,
                                                                                                            color: "#505050",
                                                                                                            fontWeight: 600,
                                                                                                            padding: 10,
                                                                                                            borderTop: "1px solid #ddd"
                                                                                                        }}
                                                                                                    >
                                                                                                        {item?.name}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                            fontSize: 13,
                                                                                                            color: "#505050",
                                                                                                            fontWeight: 600,
                                                                                                            padding: 10,
                                                                                                            borderTop: "1px solid #ddd"
                                                                                                        }}
                                                                                                    >
                                                                                                        {state?.fullResponse?.fullResponse?.hotel?.rooms?.[0]?.rates?.[0]?.adults}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                            fontSize: 13,
                                                                                                            color: "#505050",
                                                                                                            fontWeight: 600,
                                                                                                            padding: 10,
                                                                                                            borderTop: "1px solid #ddd"
                                                                                                        }}
                                                                                                    >
                                                                                                        {state?.fullResponse?.fullResponse?.hotel?.rooms?.[0]?.rates?.[0]?.children}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                            fontSize: 13,
                                                                                                            color: "#505050",
                                                                                                            fontWeight: 600,
                                                                                                            padding: 10,
                                                                                                            borderTop: "1px solid #ddd"
                                                                                                        }}
                                                                                                    >NA</td>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                            fontSize: 13,
                                                                                                            color: "#505050",
                                                                                                            fontWeight: 600,
                                                                                                            padding: 10,
                                                                                                            borderTop: "1px solid #ddd"
                                                                                                        }}
                                                                                                    >
                                                                                                        {state?.fullResponse.fullResponse.hotel.rooms[0].rates[0].boardName}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{ width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            <h6
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "#000",
                                                                                                    margin: "0 0 5px 0"
                                                                                                }}
                                                                                            >
                                                                                                Remarks
                                                                                            </h6>
                                                                                            <h6
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#000",
                                                                                                    margin: "0 0 5px 0"
                                                                                                }}
                                                                                            >
                                                                                                Rate Information: Please note that
                                                                                                these rates don't allow modifications,
                                                                                                therefore you would need to cancel and
                                                                                                create a new booking. Cancellation
                                                                                                charges apply as per cancellation
                                                                                                policy.
                                                                                            </h6>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: "0 0 5px 0",
                                                                                                    fontSize: 13,
                                                                                                    fontWeight: 600,
                                                                                                    color: "#545353"
                                                                                                }}
                                                                                            >
                                                                                                Car park YES (with additional debit
                                                                                                notes). Check-in hour -- - ---.
                                                                                                Deposit on arrival. Identification
                                                                                                card at arrival
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    fontSize: 13,
                                                                                                    fontWeight: 600,
                                                                                                    color: "#545353"
                                                                                                }}
                                                                                            >
                                                                                                Included: Free breakfast. Included:
                                                                                                Free WiFi.
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{ width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: 10,
                                                                                                borderTop: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            <h6
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "#000",
                                                                                                    margin: "0 0 10px 0"
                                                                                                }}
                                                                                            >
                                                                                                Customer remarks
                                                                                            </h6>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    fontSize: 13,
                                                                                                    fontWeight: 600,
                                                                                                    color: "#545353"
                                                                                                }}
                                                                                            >
                                                                                                Double bed. The remarks for the
                                                                                                establishment are for reference only.
                                                                                                Hotelbeds cannot guarantee them.
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "100%", padding: 5 }}>
                                                            <table
                                                                style={{ width: "100%", border: "1px solid #ddd" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "100%", padding: 10 }}>
                                                                            <p
                                                                                style={{
                                                                                    margin: "0 0 10px 0",
                                                                                    fontSize: 14,
                                                                                    color: "#db7a03"
                                                                                }}
                                                                            >
                                                                                <strong style={{ color: "#000" }}>
                                                                                    {state?.fullResponse?.fullResponse?.hotel?.name}{" "}
                                                                                </strong>
                                                                                <i
                                                                                    className="fa fa-star"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <i
                                                                                    className="fa fa-star"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <i
                                                                                    className="fa fa-star"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <i
                                                                                    className="fa fa-star"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        border: "1px solid",
                                                                                        padding: "5px 15px",
                                                                                        borderRadius: 5
                                                                                    }}
                                                                                >
                                                                                    Includes 4-star hotels, 4-superior and 4 de
                                                                                    luxe,
                                                                                </span>
                                                                            </p>
                                                                            <p
                                                                                style={{ margin: "0 0 10px 0", fontSize: 14 }}
                                                                            >
                                                                                <strong>
                                                                                    {state?.fullResponse?.fullResponse?.hotel?.destinationName}
                                                                                </strong>
                                                                            </p>
                                                                            <p
                                                                                style={{ margin: "0 0 10px 0", fontSize: 14 }}
                                                                            >
                                                                                <strong>Fax :</strong>---
                                                                                <strong>Telephone :</strong>---
                                                                                <strong>Email :</strong> ---
                                                                            </p>
                                                                            <p
                                                                                style={{ margin: "0 0 0px 0", fontSize: 14 }}
                                                                            >
                                                                                <strong>GMS :</strong> 59° 20' 48.070'' N -
                                                                                18° 2' 23.323''
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table
                                                cellSpacing={0}
                                                cellPadding={5}
                                                border={0}
                                                style={{ width: "100%", margin: "auto" }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "100%", padding: 5 }}>
                                                            <table style={{ width: "100%" }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "100%" }}>
                                                                            <p
                                                                                style={{
                                                                                    backgroundColor: "#005aae",
                                                                                    padding: 15,
                                                                                    color: "#fff",
                                                                                    fontWeight: 700,
                                                                                    fontSize: 13
                                                                                }}
                                                                            >
                                                                                Telephone
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <p
                                                                                style={{
                                                                                    margin: "10px 0 0 0",
                                                                                    fontSize: 13,
                                                                                    fontWeight: 600
                                                                                }}
                                                                            >
                                                                                <strong>Booked and payable by </strong>{" "}
                                                                                Selected partners
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default Performa