import React from 'react'
import { Circles } from 'react-loader-spinner'

const Loader = (props) => {
  console.log('props in loader', props)
  return (
    <div>
        <Circles
  height={props?.height?"80":props?.width}
  width={props?.width?"80":props?.width}
  color="#4fa94d"
  ariaLabel="circles-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  /></div>
  )
}

export default Loader