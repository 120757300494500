import React, { useEffect } from "react";

import {
  Route,
  HashRouter,
  BrowserRouter as Router,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
//import { ToastContainer, toast, Slide } from "react-toastify";
import Index from "./components/Index";
import HotelList from "./components/HotelList";
import Packages from "./components/Packages";
import Transfer from "./components/Transfer";
import Flights from "./components/Flights";
import SignUp from "./components/SignUp";
import Activities from "./components/Activities";
import BookingDetail_Activity from "./components/BookingDetail_Activity";
import BookingDetail_Flight from "./components/BookingDetail_Flight"
import BookingDetail_Hotel from "./components/BookingDetail_Hotel"
import BookingDetail_Transfer from "./components/BookingDetail_Transfer"
import HotelDetail from "./components/HotelDetail";
import AgentLogin from "./components/AgentLogin";
import { isLoggedIn } from "./utils/LoggedIn";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from "./components/ForgotPassword";
import Otp from "./components/Otp";
import ResetPassword from "./components/ResetPassword";
import IndexLoggedIn from "./components/IndexLoggedIn";
import ImagesForHotelDetail from "./components/ImagesForHotelDetail"
import ConvertExceltojson from "./components/ConvertExceltojson";
import PackageDetails from "./components/PackageDetails";
import HotelListingHistory from "./components/HotelListingHistory";
import About from "./components/About";
import Contact from "./components/Contactus"
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import BookPackage from "./components/BookPackage";
import Quotation from "./components/Quotation";
import BrandDetails from "./components/BrandDetails";
import ShareDealsPage from "./components/ShareDealsPage";
import Marketing from "./components/Marketing";
import HotelsImages from "./components/HotelsImages";
import NewIndex from "./components/NewIndex";
import Voucher from "./CustomComponent/Voucher";
import Performa from "./CustomComponent/Performa";
import Undermaintenance from "./components/Undermaintenance";
import Wallet from "./components/Wallet";
import WalletFallbackPage from "./components/WalletFallbackPage";
//======================= Google Analytics =================================//
//==========================================================================//

function App() {
  //====================================================================================//

  //==============================================Google Analytics ===========================//
  function PrivateRoute({ component: Component, userType, ...rest }) {
    const isLogged = isLoggedIn("swtadmin");
    console.log('Logged in ', isLogged)
    return isLogged ? <Component checkAuth={() => isLogged} {...rest} /> : <Navigate to="/" />;
  }
  return (
    <div>
      <HashRouter>
        <Routes>
          {/* <Route path="/" element={<Undermaintenance />} /> */}
          <Route path="/" element={<NewIndex />} />
          {/* <Route path="/hotelList" element={<HotelList />} /> */}
          <Route path="/hotelList" element={<PrivateRoute component={HotelList} />} />
          {/* <Route path="/packages" element={<Packages />} />
          <Route path="/bookPackage" element={<BookPackage/>} />
          <Route path="/package_details" element={<PackageDetails />} />  */}


          <Route path="/packages" element={<PrivateRoute component={Packages} />} />
          <Route path="/bookPackage" element={<PrivateRoute component={BookPackage} />} />
          <Route path="/package_details" element={<PrivateRoute component={PackageDetails} />} />
          <Route path="/wallet" element={<PrivateRoute component={Wallet} />} />
          <Route path="/walletFallbackPage" element={<PrivateRoute component={WalletFallbackPage} />} />


          {/* <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} /> */}

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />


          <Route path="/transfer" element={<Transfer />} />
          <Route path="/flights" element={<Flights />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/bookingDetail_Activity" element={<BookingDetail_Activity />} />
          <Route path="/bookingDetail_Flight" element={<BookingDetail_Flight />} />
          <Route path="/bookingDetail_Hotel" element={<BookingDetail_Hotel />} />
          <Route path="/bookingDetail_Transfer" element={<BookingDetail_Transfer />} />
          <Route path="/hotelDetail" element={<HotelDetail />} />
          <Route path="/agentLogin" element={<AgentLogin />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/Otp" element={<Otp />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/imagesForHotelDetail" element={<ImagesForHotelDetail />} />
          <Route path="/convertExceltojson" element={<ConvertExceltojson />} />
          {/* <Route path="/indexLoggedIn" element={<PrivateRoute component={IndexLoggedIn}/>} /> */}
          <Route path="/indexLoggedIn" element={<IndexLoggedIn />} />
          <Route path="/hotelsImages" element={<HotelsImages />} />

          <Route path="/bookPackage" element={<BookPackage />} />
          <Route path="/hotelListingHistory/:type" element={<HotelListingHistory />} />
          <Route path="/quotation" element={<Quotation />} />

          <Route path="/transfer" element={<Transfer />} />
          <Route path="/brandDetails" element={<BrandDetails />} />
          <Route path="/shareDealsPage" element={<ShareDealsPage />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/newIndex" element={<NewIndex />} />
          <Route path="/performa" element={<Performa />} />
          <Route path="/voucher" element={< Voucher />} />

        </Routes>
      </HashRouter>
      <ToastContainer />
    </div>
  );
}


export default App;
