// citySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";

// const apiUrl = 'https://api.example.com/cities';

export const fetchTransferList = createAsyncThunk(
  "transferList/fetchTransferList",
  async (payload) => {
    console.log("payload===>", payload.data);
    const response = await axios.get(
      `${privateData["proxy-url"]}/transfer-api/1.0/availability/en/from/IATA/BCN/to/ATLAS/57/${payload?.data?.date}T12:00:00/${payload?.data?.adults}/${payload?.data?.children}/0`,
      {
        headers: {
          "Api-key": privateData["Api-key"],
          "X-Signature": xSignature,
        },
      }
    );
    return response.data
      ;
  }
);

// const hotelListSlice = createSlice({
//   name: "hotelList",
//   initialState: {
//     hotelLists: [],
//     status: "idle",
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchHotelList.pending, (state) => {
//         state.status = "loading";
//       })
//       .addCase(fetchHotelList.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.hotelLists = action.payload;
//       })
//       .addCase(fetchHotelList.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.error.message;
//       });
//   },
// });

//export default hotelListSlice.reducer;
