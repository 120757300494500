import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const forgotPasswordApi = createAsyncThunk(
  "forgotpassword/postforgotpassword",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.post(
      `https://swtclub.com:4300/api/v1/user/forget-otp-on-mail`,
      payload,
      // {
      //   headers: {
      //     "Authorization": isLoggedIn("adminLogin")
      //   },
      // }
    );
    return response.data
      ;
  }
);