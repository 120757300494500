import React, { useEffect, useState } from "react";
import { images } from "../utils/images";
import { useDispatch, useSelector } from "react-redux";
import { fetchHotelDetail } from "../reduxToolKit/slices/HotelDetailApiSlice";
import { Link, useLocation } from "react-router-dom";
import SwtMap from "./SwtMap";
import { addToCartApi } from "../reduxToolKit/slices/addToCartApiSlice";
import Header2 from "./Header2";
import { toast } from "react-toastify";
import { fetchHotelCartList } from "../reduxToolKit/slices/hotelCartListApiSlice";
import { starCount } from "../utils/stars";
import axios from "axios";
import { Carousel, Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import html2pdf from 'html2pdf.js';
// import './styles.css';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Card from "./HotelDetailCard";
//=======================proxy =================================================//

const buttonStyle = {
  background: 'transparent', // Makes the background transparent
  border: 'none', // Removes the default border
  outline: 'none', // Removes the default outline
  color: 'blaok', // Inherits the text color from its parent
  fontSize: '16px', // Adjust font size as needed
  cursor: 'pointer', // Changes the cursor to pointer
};


const HotelDetail = () => {
  const dispatch = useDispatch();
  const { hotelDetails, status } = useSelector((state) => state?.hotelDetails);//hotelDetails.hotelDetails.hotel
  const roomTypeImages = hotelDetails?.hotel?.images?.filter((item) => item?.type?.description?.content === 'Room')
  console.log('Room images', roomTypeImages)
  const [show, setShow] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { state } = useLocation();
  const [requirement, setRequirement] = useState(0)


  console.log('state in rooms detail', state)

  const handleSwiper = (e) => {
    setThumbsSwiper(e)

  }

  const handleOpenModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  }

  const Rooms = state?.item?.rooms
  console.log('Rooms in room detail', Rooms)
  const availableFacilityGroupCode = hotelDetails?.hotel?.facilities?.filter((item) => item.facilityGroupCode === 70)
  const imgArr = hotelDetails.hotel?.images?.filter((item) => item?.type?.description?.content === "Room")?.map((item) => item?.path)
  useEffect(() => {
    if (state?.item?.code) {
      dispatch(fetchHotelDetail(state?.item?.code)).then((res) => {
        console.log('Response of hotel detail', res)
      });
      scrollToRoomList()
      const totalRequiredBed = requirement ? 0 : state?.totalGuest?.reduce((acc, curr) => acc + curr.adults ?? 0, 0) ?? 0
      setRequirement(totalRequiredBed)
    }
  }, [state]);
  const addToCart = (roomId, roomsType, roomKey, roomRate, occupancy) => {
    console.log('clicked to add to cart', 'code ==>', roomId, 'name ==>', roomsType, 'rate key', roomKey, state?.code, 'destination ==>', state?.destinationName, "checkIn", state?.checkIn, "checkOut", state?.checkOut, "guests", "roomRate", roomRate)

    const data = {
      "hotelId": state?.item?.code,
      "hotelName": state?.item?.name,
      "rooms": [
        {
          "rateKey": roomKey,
          "roomRate": roomRate,
          "paxes": [
            {
              "roomId": roomId,
              "roomType": roomsType
            }
          ]
        }
      ],
      "description": hotelDetails.hotel?.description.content,
      "destination": state?.item?.destinationName,
      "checkIn": state?.checkIn,
      "checkOut": state?.checkOut,
      "guests": occupancy ?? 2
    }
    const addedToCart = dispatch(addToCartApi(data)).then((res) => {
      console.log('Response of when hotel is added', res)
      if (res?.payload?.status === 200) {
        dispatch(fetchHotelCartList())
        setRequirement(requirement - occupancy)// requirement

        toast.success(`Room with occupancy with ${occupancy} added sucessfully in cart`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      } else {
        toast.error('Cannot add hotel in cart', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    })
    console.log('added to cart', addedToCart)
  }
  const scrollToRoomList = () => {
    console.log('scrollToRoomList clicked')
    // e.screenX('400px')
    window.scrollTo({
      top: 1200,
      behavior: 'smooth'
    })
  }
  const downloadAsPDF = async () => {
    const overView = document.getElementById('downloadOverviewArea');
    const amenities = document.getElementById('downloadAmenities');
    //const images = document.getElementById('downLoadImage')// don't show
    const rooms = document.getElementById('downloadAvailableRooms');
    const map = document.getElementById('downloadLocationMapArea');

    const downloadDataArray = [overView, amenities, rooms, map];


    if (downloadDataArray.length) {

      const container = document.createElement('div');


      downloadDataArray.forEach((element) => {
        if (element) {
          container.innerHTML += element.innerHTML;
        }
      });


      const pdfOptions = {
        margin: 10,
        filename: 'downloaded-file.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };


      await html2pdf(container, pdfOptions);
    } else {
      console.error('Target element not found.');
    }
  };

  return (
    <div>
      <Header2 />
      <main className="FilterProductsArea HotelListingPage">

        <div className="breadcrumb">
          <ul>
            <li>
              <a href="javascript:void(0);">
                {hotelDetails.hotel?.country?.description?.content}
                {" "}
                <span>
                  <img src={images.rightArrow} />
                </span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                {hotelDetails.hotel?.city.content}
                {" "}
                <span>
                  <img src={images.rightArrow} />
                </span>
              </a>
            </li>
            <li>
              {hotelDetails.hotel?.name?.content}
            </li>
          </ul>
        </div>
        <div className="HotelNameInformation">
          <div className="HotelNameInformationLeft">
            <h3>
              {hotelDetails.hotel?.name?.content}
            </h3>
            <p>
              <img src={images.location} />
              {hotelDetails.hotel?.address?.content}
              ,
              {hotelDetails.hotel?.city?.content}
            </p>
            <div className="PrpBox">
              <div className="d-flex align-items-center">
                {/* <h5>{state?.item?.categoryCode?.split('').slice(0, 1).join('')}</h5> */}
                <div className="RatingStars">
                  {starCount(Number(state?.item?.categoryCode?.split('').slice(0, 1).join('')))?.map((i) => { return (i) })}
                </div>
                <span>Review Rating</span>
              </div>
            </div>
          </div>
          <div className="HotelNameInformationRight">
            <h6>
              Starting From<span>Inr {state?.item?.minRate}</span>
              {/* /month Excl. tax */}
            </h6>
            <div className="Icons">
              <a className="Icon">
                <img src={images.heart} />
              </a>
              <a className="Icon">
                <img src={images.shareIcon} />
              </a>
              <a className="Icon" onClick={downloadAsPDF}>
                <img src={images.downloadIcon} />
              </a>
              <button href="booking_details_hotel.html" className="AddToCartBtn" onClick={scrollToRoomList}>
                Book now
              </button>
            </div>
          </div>
        </div>
        <div className="HotelBannerArea" id="downLoadImage">
          <div className="HotelBannerAreaLeft">
            <figure>
              <img src={`http://localhost:8011/proxy/${hotelDetails.hotel?.images?.[0]?.path}`} height={315} width={210} />
            </figure>
          </div>
          <div className="HotelBannerAreaRight">
            <div className="HotelBannerAreaRightOne">
              <figure className="FirstFigure">
                <img src={`http://localhost:8011/proxy/${hotelDetails.hotel?.images?.[0]?.path}`} height={155} width={103} />
              </figure>
              <figure className="SecondFigure">
                <img src={`http://localhost:8011/proxy/${hotelDetails.hotel?.images?.[1]?.path}`} height={155} width={103} />
              </figure>
            </div>
            <div className="HotelBannerAreaRightOne">
              <figure className="FirstFigure">
                <img src={`http://localhost:8011/proxy/${hotelDetails.hotel?.images?.[2]?.path}`} height={155} width={103} />
              </figure>
              <figure className="SecondFigure LastFigure">
                <img src={`http://localhost:8011/proxy/${hotelDetails.hotel?.images?.[4]?.path}`} height={155} width={103} />
                {
                  hotelDetails.hotel?.images?.length > 5 ?
                    <Link
                      className="ShowAllphotosBtn"
                      to='/hotelsImages'
                      state={imgArr}
                    //onClick={handleOpenModal}
                    >
                      {/* <img src={`http://localhost:8011/proxy/${hotelDetail?.images?.[5]?.path}`} /> */}
                      Show All photos
                    </Link> : ""
                }

              </figure>
            </div>
          </div>
        </div>

        {/*  */}
        <section>
          <div className="OverviewArea" id="downloadOverviewArea">
            <h4>Overview</h4>
            <p>
              {
                hotelDetails.hotel?.description?.content
              }
            </p>
            <ul>
              {
                hotelDetails.hotel?.interestPoints?.map((item) => {
                  return (
                    <li>
                      <span>
                        <img src={images.overviewIcon} />
                      </span>
                      <h6>{item?.poiName}</h6>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </section>
        <section>
          <div className="AvailableRoomsArea" id="downloadAvailableRooms">
            <h4>Available Rooms
              {/* <span style={{ float: 'right' }}>Requirement {requirement}</span> */}
            </h4>
            {Rooms?.map((item1, index) => {
              const roomImage = roomTypeImages?.find((item) => item?.roomCode === item1.code)
              console.log('Room Image', roomImage)
              return (
                <div className="AvailableRooms">
                  <div className="d-flex align-items-center justify-content-between"
                  //className="AvailableRoomsHead"
                  >
                    <figure>
                      <img src={`http://localhost:8011/proxy/${roomImage?.path}`} width='200px' height='200px' style={{ borderRadius: '10px' }} />
                    </figure>
                    <figcaption style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-evenly', background: '#FDE49E', height: '80px', boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px", width: "calc(100% - 225px)", borderRadius: "10px", alignItems: "center" }}>
                      <h5 className="mb-0">{item1?.name}</h5>
                      {/* <h6 className="mb-0">{item?.rates[0]?.boardName}</h6> */}
                      {/* <ul>
                        <li>
                          <span>
                            <img src={images.cancellationFees} />
                          </span>
                          {item.rates[0].cancellationPolicies[0].amount}
                        </li>
                      </ul> */}


                    </figcaption>
                  </div>
                  {
                    item1?.rates?.map((item) => {
                      return (
                        // <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-evenly', background: '#FCDC94', boxShadow: '5px 5px 5px #ECB176', margin: '6px', borderRadius: '5px' }}>
                        //   <div style={{ color: 'blue' }}> {item?.packaging ? (
                        //     <div>
                        //       <img src={images.flightSvgRepo} />
                        //       Product for packaging
                        //     </div>
                        //   ) : 'Not For Packaging'}</div>
                        //   <div style={{ color: 'red' }}>Inr {item?.net}<span>/night Excl. tax</span></div>
                        //   <div style={{ color: 'green' }}>
                        //     <div style={{ color: 'green' }}>
                        //       <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'space-evenly' }}>
                        //         <div style={{ margin: '20px' }}>
                        //           {item?.cancellationPolicies?.length < 1
                        //             ? 'Non Refundable'
                        //             : `${item?.cancellationPolicies?.[0]?.amount} Cancellation Charges`}
                        //         </div>
                        //         <button
                        //           //className="AddToCartBtn"
                        //           style={buttonStyle}
                        //           onClick={() => addToCart(item?.code, item?.name, item?.rates?.[0]?.rateKey, item?.rates?.[0]?.net)}
                        //         >
                        //           <i className="fa-solid fa-cart-shopping" />
                        //           Add to Cart
                        //         </button>
                        //       </div>
                        //     </div>

                        //   </div>
                        // </div>
                        <Card item={item} addToCart={addToCart} images={images} itemCode={item1.code} itemName={item1?.name} />
                        // <div className="AvailableRoomsBody">
                        //   <h5>
                        //     Inr {item?.net}<span>/night Excl. tax</span>
                        //   </h5>
                        //   <div>
                        //     {item?.packaging ? (
                        //       <div>
                        //         <img src={images.flightSvgRepo} />
                        //         Product for packaging
                        //       </div>
                        //     ) : 'Not For Packaging'}
                        //   </div>

                        //   <div>{item?.cancellationPolicies?.length<1?'Non Refundable':<>{item?.cancellationPolicies?.[0]?.amount} Cancellation Charges</>}</div>
                        //   <button className="AddToCartBtn" onClick={() => addToCart(item?.code, item?.name, item?.rates?.[0]?.rateKey, item?.rates?.[0]?.net)}>
                        //     <i className="fa-solid fa-cart-shopping" />
                        //     Add to Cart
                        //   </button>
                        // </div>
                      )
                    })
                  }

                </div>




              );
            })}
          </div>
        </section>
        <section>
          <div className="LocationMapArea" id="downloadLocationMapArea">
            <div className="LocationMapTop">
              <h4>Location/Map</h4>
              {/* <a href="javascript:void(0);" className="AddToCartBtn">
                View on google maps
              </a> */}
            </div>
            <>
              <SwtMap lat={hotelDetails.hotel?.coordinates?.latitude} lng={hotelDetails.hotel?.coordinates?.longitude} />
            </>

            <p>
              <span>
                <img src={images.locationMapIcon} />
              </span>
              Gümüssuyu Mah. Inönü Cad. No:8, Istanbul 34437
            </p>
          </div>
        </section>
        <section>
          <div className="AmenitiesArea" id="downloadAmenities">
            <h4>Amenities</h4>
            <div className="AmenitiesBody">
              <aside>
                <ul>
                  {
                    availableFacilityGroupCode?.length > 0 ? availableFacilityGroupCode?.map((item) => {
                      return (
                        <li>
                          <span>
                            <img src={images.ameneties} />
                          </span>
                          {
                            item.description.content
                          }
                        </li>
                      )
                    }) : 'No Facilites Available'
                  }

                </ul>
              </aside>
            </div>
          </div>
        </section>
        <section>
          <div className="ReviewsArea">
            <div className="ReviewsAreaTop">
              <h4>Reviews</h4>
              <a href="javascript:void(0);" className="AddToCartBtn">
                Give your review
              </a>
            </div>
            <div className="ReviewBox First">
              <h1>4.2</h1>
              <div>
                <h3>Very good</h3>
                <p>371 verified reviews</p>
              </div>
            </div>
            <div className="ReviewBox">
              <figure>
                <img src="images/review_01.png" />
              </figure>
              <i className="fa fa-flag" aria-hidden="true" />
              <h6>
                5.0 Amazing <span>Omar Siphron</span>
              </h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="ReviewBox">
              <figure>
                <img src="images/review_02.png" />
              </figure>
              <i className="fa fa-flag" aria-hidden="true" />
              <h6>
                5.0 Amazing <span>Omar Siphron</span>
              </h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="ReviewBox">
              <figure>
                <img src="images/review_03.png" />
              </figure>
              <i className="fa fa-flag" aria-hidden="true" />
              <h6>
                5.0 Amazing <span>Omar Siphron</span>
              </h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="ReviewBox">
              <figure>
                <img src="images/review_04.png" />
              </figure>
              <i className="fa fa-flag" aria-hidden="true" />
              <h6>
                5.0 Amazing <span>Omar Siphron</span>
              </h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="ReviewBox">
              <figure>
                <img src="images/review_05.png" />
              </figure>
              <i className="fa fa-flag" aria-hidden="true" />
              <h6>
                5.0 Amazing <span>Omar Siphron</span>
              </h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="Pagination">
              <h6>
                <i className="fa fa-angle-left" aria-hidden="true" />
                1 of 40
                <i className="fa fa-angle-right" aria-hidden="true" />
              </h6>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} onHide={handleClose} className="modalBox modalBoxNew" centered>
        <Modal.Body className="modal-dialog-2"
        >
          <div>
            <div className="Category">
              <a className="CloseModal" onClick={handleClose}>
                ×
              </a>
              <div className="backGroundColor">
                <Carousel
                  indicators={false}
                  controls={true}
                  interval={null} // Set to null for manual control
                  className="myCarousel backGroundColor"
                >
                  {imgArr?.length > 0 &&
                    imgArr?.map((item, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="testimonialImages d-block w-50"
                          style={{ marginLeft: '25%' }}
                          src={`http://localhost:8011/proxy/${item}`}
                          alt={`Slide ${index + 1}`}
                          onClick={() => handleImageClick(index)}
                          height="200"
                          width="150"
                        />
                      </Carousel.Item>
                    ))}
                </Carousel>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );

};




export default HotelDetail;
