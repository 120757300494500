import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { juspayApi, walletAddApi } from '../reduxToolKit/slices/payment/payment'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'

const initialState = {
    status: "",
    time: "",
    amount: "",
    trxnId: "",
    orderId: "",
    loader: false
}
const WalletFallbackPage = () => {
    const [iState, updateState] = useState(initialState)
    const { status, time, amount, trxnId, loader, orderId } = iState
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        const data = {
            orderId: window.localStorage.getItem('orderId')
        }
        if (data?.orderId) {
            dispatch(juspayApi(data)).then((res) => {
                console.log('Response of juspay response', res)
                updateState({
                    ...iState,
                    trxnId: res?.payload?.payment_gateway_response?.txn_id,
                    amount: res?.payload?.amount,
                    status: res?.payload?.status,
                    time: res?.payload?.payment_gateway_response?.created,
                    orderId: res?.payload?.order_id

                })
                if (status === 'CHARGED') {
                    const data = {
                        status: res?.payload?.status,
                        transId: res?.payload?.payment_gateway_response?.txn_id,
                        balance: res?.payload?.amount,
                        orderId: res?.payload?.order_id
                    }
                    dispatch(walletAddApi(data)).then((res) => {
                        console.log('res of internal add wallet', res)
                        if (res?.payload?.status === 200) {
                            setTimeout(() => {
                                // window.location.href = 'https://swtclub.com/#/wallet';
                                updateState(initialState)
                                window.localStorage.removeItem('orderId')
                                navigate('/wallet')
                            }, 5000)
                        }
                    })
                } else {
                    setTimeout(() => {
                        // window.location.href = 'https://swtclub.com/#/wallet';
                        updateState(initialState)
                        window.localStorage.removeItem('orderId')
                        navigate('/wallet')
                    }, 5000)
                }


            })
        }
    }, [])

    // try {

    // } catch (error) {
    //     console.log('errrrror', error)
    // }

    const handleNavigate = () => {
        navigate('/wallet', { replace: true })
    }
    console.log('wallet fallback', iState)
    return (
        <div className='PaymentStatusArea'>
            {
                status ? <div>
                    <div className='PaymentStatusBox'>
                        <h3>Payment Status</h3>
                        <span className={status === "CHARGED" ? 'GreenBorder' : 'RedBorder'}>
                            {
                                status === "CHARGED" ? <i class="fa-solid fa-check"></i> : <i class="fa-solid fa-xmark"></i>
                            }
                        </span>

                        <h6 style={{ color: status == 'CHARGED' ? 'green' : 'red' }}>Payment Status: {status}</h6>
                        <p>Order ID: {orderId}</p>
                        <p>Paid Amount: INR {amount}</p>
                        <p>Payment Date & Time: {moment(time).format('DD/MM/YYYY hh:mm a')}</p>
                        <p>Transaction ID: {trxnId}</p>
                    </div>
                    <div className='Loader'>
                        <a onClick={handleNavigate}>Click Here to navigate to wallet page</a>
                    </div>
                </div> : <Loader />
            }

        </div>
    )
}

export default WalletFallbackPage