import React, { useState } from "react";
import { images } from "../utils/images";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginApiSlice } from "../reduxToolKit/slices/loginApiSlice";
import { toast } from "react-toastify";
import { forgotPasswordApi } from "../reduxToolKit/slices/forgotPasswordApiSlice";
import Header from "./Header";

const initialState = {
  email: "",
  password: "",
  errors: "",
};

const AgentLogin = () => {
  const [iState, updateState] = useState(initialState);
  const [toggle, setToggle] = useState(false)
  const { email, password, errors } = iState;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  let handleValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!password || !password.trim()) {
      error.passwordError = " * Password can't be empty";
      formIsValid = false;
    } else {
      const validPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
      if (!validPassword) {
        error.passwordError = " * Enter valid Password";
        formIsValid = false;
      }
    }

    if (!email.trim()) {
      error.emailIdError = " * Email id can't be empty";
      formIsValid = false;
    }
    // if (email) {
    //   if (!/^.+?@.+?\..+$/.test(email)) {
    //     error.emailIdError = " * Email format is not valid";
    //     formIsValid = false;
    //   }
    // }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      password,
    };
    const isValid = handleValidation()

    if (isValid) {
      try {
        dispatch(loginApiSlice(payload))
          .then((loginres) => {
            console.log('Response of login api', loginres)
            console.log('verification status', loginres.payload?.data?.idVerification)
            if (loginres.payload?.status === 200 && loginres.payload?.data?.idVerification === "approved") {
              //console.log('admin credential',res?.payload?.data)
              // window.localStorage.setItem("swtadmin",JSON.stringify(res?.payload?.data?.jwtToken))
              toast.success('Please verify otp sent on registered email Id', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              })
              const data = {
                email
              }
              dispatch(forgotPasswordApi(data)).then((res) => {
                console.log('response of agent login in api', res)
                if (res?.payload?.status === 200) {
                  // toast.success(res?.payload?.message, {
                  //   position: toast.POSITION.TOP_RIGHT,
                  //   autoClose: 1000,
                  // })
                  window.localStorage.setItem("swtaDetails", JSON.stringify(loginres?.payload?.data))
                  navigate('/otp', {
                    state: {
                      email: data,
                      otp: res?.payload?.data?.otp,
                      token: loginres?.payload?.data?.jwtToken,
                      from: "Agentlogin", token: loginres?.payload?.data?.jwtToken, from: "Agentlogin"
                    }
                  })
                } else {
                  toast.error(res?.payload?.message ?? "InValid Credentials", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                  })
                }
              })
              // navigate('/forgotPassword',{state:{token:res?.payload?.data?.jwtToken}})
            }
            else if (loginres.payload?.status === 200 && loginres.payload?.data?.idVerification === "pending") {
              toast.info('Your sign up is pending for approval',
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              )
            }
            else if (loginres.payload?.status === 200 && loginres.payload?.data?.idVerification === "rejected") {
              toast.error('User have not signned up ', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              })
              navigate('/signUp', { state: { loginRes: loginres?.payload } })
            }
            else {
              toast.error(loginres?.payload?.message ?? "InValid Credentials", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              })
            }
          })
      } catch (e) {
        console.log("Error in loggin component", e);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value);
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleTogglePassword = () => {
    setToggle(!toggle)
  }
  return (
    <div>
      <Header />
      <>
        <main id="main">
          <section className="hero" id="hero">
            <video autoPlay="" muted="" loop="" width="100%">
              <source src={images.bannerVideo} type="video/mp4" />
            </video>
            <div
              className="hero-content animate fadeInUp"
              data-animate="fadeInUp"
              data-duration="1.5s"
              data-delay="0.3s"
            >
              <h1>
                Book Travel Boldly With SWT CLUB <br />
                <span>A Trusted Global B2B travel platform</span>
              </h1>
            </div>
            {/* <a href="sign-up.html" class="SignUpBtn">Sign Up</a> */}
          </section>
        </main>
        <section>
          <div
            className="ContactFormArea ContactArea"
          >
            {/* <div className="container">
              <div className="ContactFormHead TitleBox">
                <h3>Login</h3>
                <span>Login Details</span>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="ContactFormBody">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Your Email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                        <span style={{ color: "red" }} className="d-flex">
                          {errors?.emailIdError}
                        </span>
                      </div>
                      <div className="form-group mb-0">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Your Password"
                          name="password"
                          value={password}
                          onChange={handleChange}
                        />
                        <span style={{ color: "red" }} className="d-flex">
                          {errors?.passwordError}
                        </span>
                        <span className="EyesIcon"
                          onClick={handleTogglePassword}
                        >
                          {
                            showPassword ?
                              (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                              )}
                        </span>
                      </div>
                      <div className="text-right">
                        <Link to='/forgotPassword' className="ForgotPassword" >
                          Forgot password?
                        </Link>
                      </div>
                      <div className="form-group">
                        <button type="submit">Submit</button>
                      </div>
                      <p className="signIn">
                        Don't have account{" "}
                        <Link to="/signUp"> Sign Up</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div> */}
            <div className="LoginArea">
              <div className="LoginBox" style={{ margin: "0px auto" }}>
                <figure>
                  <img src={images.logo} alt="" />
                </figure>
                <form onSubmit={handleSubmit}>
                  <h3>SWT-Agent Login</h3>
                  <div className="form-group">
                    <label style={{ textAlign: 'left' }}>Enter Your Email Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email Address"
                      name='email'
                      value={email}
                      onChange={handleChange}

                    />
                    <span style={{ color: 'red' }} >{errors.emailIdError}</span>
                  </div>

                  <div className="form-group">
                    <label style={{ textAlign: 'left' }}>Enter Your Password</label>
                    <input
                      type={toggle ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter Password"
                      name='password'
                      value={password}
                      onChange={handleChange}
                      autoComplete='off'
                    />
                    <span className="Icon">
                      <i className={toggle ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => { setToggle(!toggle) }} />
                    </span>
                    <span style={{ color: 'red' }} >{errors.PasswordError}</span>
                  </div>
                  <div className="form-group form-group-checkBox">
                    {/* <div className="check">
                      <input
                        type="checkbox"
                      // checked={isChecked}
                      // onChange={handleChecked}
                      />
                      <label>Remember Me</label>
                    </div> */}
                    <div className="Checkboxs">
                      <Link to="/forgotPassword">Forgot password</Link>
                    </div>
                  </div>
                  <button
                    className="Button NewButton"
                  // style={{ background: 'red', border: '2px', borderRadius: '5px', width: '30%' }}
                  //disabled={loading}
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default AgentLogin;


//======================================

{/* <div className="LoginArea">
<div className="LoginBox">
    <figure>
        <img src={logo} alt="" />
    </figure>
    <form onSubmit={handleSubmit}>
        <h3>SWT-Admin Login</h3>
        <div className="form-group">
            <label>Enter Your Email Address</label>
            <input
                type="text"
                className="form-control"
                placeholder="Enter Email Address"
                name='email'
                value={email}
                onChange={handleInputChange}
                
            />
            <span style={{color:'red'}} >{errors.emailIdError}</span>
        </div>
        
        <div className="form-group">
            <label>Enter Your Password</label>
            <input
                type={toggle?"text":"password"}
                className="form-control"
                placeholder="Enter Password"
                name='password'
                value={password}
                onChange={handleInputChange}
                autoComplete='off'
            />
            <span className="Icon">
                <i className={toggle?"fa fa-eye":"fa fa-eye-slash"} onClick={()=>{setToggle(!toggle)}}/>
            </span>
            <span style={{color:'red'}} >{errors.PasswordError}</span>
        </div>
        <div className="form-group form-group-checkBox">
            <div className="check">
                <input
                 type="checkbox"
                 checked={isChecked}
                 onChange={handleChecked}
                />
                <label>Remember Me</label>
            </div>
            <div className="Checkboxs">
                <Link to="/forgot">Forgot password?</Link>
            </div>
        </div>
        <button className="Button" disabled={loading}>
            Login
        </button>
    </form>
</div>
</div> */}
