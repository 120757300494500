import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { isLoggedIn } from "../../utils/LoggedIn";

// const apiUrl = 'https://api.example.com/cities';

export const packageApi = createAsyncThunk(
  "addToCart/postAddToCart",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.get(
      `https://swtclub.com:4300/api/v1/user/package/list`,
      {
        headers: {
          "Authorization": isLoggedIn("adminLogin")
        },
        params: payload
      }
    );
    return response.data
      ;
  }
);

const packageSlice = createSlice({
  name: "hotelList",
  initialState: {
    packageList: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(packageApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(packageApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.packageList = action.payload;
      })
      .addCase(packageApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});


export default packageSlice.reducer;