import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../App.css";
import { toast } from "react-toastify";
import Header from "./Header";
// import SideNav from "./SideNav";
import * as XLSX from "xlsx";
import axios from "axios";

const ConvertExceltojson = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [excelData, setExcelData] = useState([]);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const formattedData = XLSX.utils.sheet_to_json(sheet);

            setExcelData(formattedData);
        };

        reader.readAsArrayBuffer(file);
    };

    console.log("excelData", excelData);

    const handleSendData = async () => {
        if (!excelData) {
            toast.error("Please Select File", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                theme: "colored",
            });
        } else {
            try {
                // const res = await dispatch(uploadbulkData({ bulk: excelData }));
                const res = await axios.post(`https://swtclub.com:4300/api/v1/user/import`, { bulk: excelData })
                if (res.data.code === 200) {
                    navigate("/customerMgmt");
                    toast.success(
                        `${res.data.message}& Created:${res.data.data.created} & Failed:${res.data.data.failed}`,
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                            theme: "colored",
                        }
                    );
                } else {
                    toast.error(res?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                        theme: "colored",
                    });
                }
            } catch (error) {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                    theme: "colored",
                });
            }
        }
    };
    return (
        <div>
            {/* <Header />
            <SideNav /> */}
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="NewTitleBox">
                        <h4>Add Bulk users</h4>
                        <Link className="TitleLink" to="/customerMgmt">
                            <i className="fa fa-long-arrow-left"></i> Back
                        </Link>
                    </div>

                    <div className="Small-Wrapper">
                        <div className="download-excel">
                            <div className="row">
                                <div className="col-6">
                                    <aside>
                                        <h3>Download Excel</h3>
                                        <p>
                                            Download excel template file to insert bulk user details:
                                        </p>
                                    </aside>
                                </div>

                                <div className="col-6">
                                    <a
                                        className="Button AddButton"
                                        href="https://basscoastsscowes.s3.amazonaws.com/photos/AddEmployee.xlsx"
                                        target="_blank"
                                        style={{ padding: "10px 20px", marginLeft: "5px" }}
                                    >
                                        Download Template
                                    </a>
                                </div>

                                <div className="col-6">
                                    <aside>
                                        <h3>Upload Excel</h3>
                                        <p>
                                            Upload filled excel template file to upload bulk users:
                                        </p>
                                    </aside>
                                </div>

                                <div className="col-6">
                                    <input
                                        type="file"
                                        accept=".xlsx"
                                        onChange={handleFileUpload}
                                    />

                                </div>

                                <button onClick={handleSendData} className="Button">Add Bulk</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConvertExceltojson