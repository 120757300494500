import React, { useEffect } from 'react'
import { fetchProudPartnerList } from "../reduxToolKit/slices/proudPartnerApiSlice";
import { useDispatch, useSelector } from 'react-redux';

import OwlCarousel from 'react-owl-carousel2';
const ProudPartner = () => {
    const proudPartner = useSelector((state) => state?.proudPartenrList.ProudPartnerList.data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProudPartnerList())
    }, [])


    const options = {
        rewind: true,
        autoplay: true,
        autoplayTimeout: 5000, // Time between slides
        smartSpeed: 600, // Speed of transition
        dots: false,
        nav: false,
        navText: [],
        loop: true,
        margin: 10,
        responsive: {
            0: { items: 3 },
            600: { items: 5 },
            1000: { items: 6 }
        }
    };

    const events = {
        // onDragged: function (event) {...},
        // onChanged: function(event) { ...}
    };
    return (
        <OwlCarousel
            className="owl-theme"
            options={options} events={events}>
            {proudPartner?.length > 0 ? (
                proudPartner.map((item, index) => (
                    <figure key={item.id}> {/* Assuming each item has a unique `id` */}
                        <img src={item?.image} height="70px" width="100px" style={{ margin: '0 10px', aspectRatio: '16:9', borderRadius: '5px' }} alt={`Partner ${index}`} />
                    </figure>
                ))
            ) : ""
            }
        </OwlCarousel>
        // <div>
        //     <div className="tab-content">
        //         <div
        //         //className="tab-pane show active" 
        //         >
        //             <OwlCarousel
        //                 className="owl-theme"
        //                 options={options} events={events}>
        //                 {proudPartner?.length > 0 ? (
        //                     proudPartner.map((item, index) => (
        //                         <figure key={item.id}> {/* Assuming each item has a unique `id` */}
        //                             <img src={require('../assests/images/package_1.png')} height="70px" width="100px" style={{ margin: '0 10px' }} alt={`Partner ${index}`} />
        //                         </figure>
        //                     ))
        //                 ) : ""
        //                 }
        //             </OwlCarousel>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ProudPartner