import { bucketConfig } from "../config/bucketConfig";
import S3FileUpload from "react-s3"
export const uploadImageFunction = async (file, image, updateState, iState) => {
    try {
        console.log('In upload image function')
        const data = await S3FileUpload.uploadFile(file, bucketConfig);
        updateState({
            ...iState,
            [image]: data.location,
            // imageLoading: false,
        });
    } catch (err) {
        // Handle the error if needed
        return err;
    }
};

