import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoggedIn } from "../../../utils/LoggedIn";


//==============================   initiateJuspayPayment=================
export const addMoneyApi = createAsyncThunk(
    "bookNowApi/postBookNowApi",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.post(
            `https://swtclub.com:4300/initiateJuspayPayment`,
            payload,
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                },
            }
        );
        return response.data
            ;
    }
);


//=========================== handleJuspayResponse ==============


export const juspayApi = createAsyncThunk(
    "bookNowApi/postBookNowApi",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.post(
            `https://swtclub.com:4300/handleJuspayResponse`,
            payload,
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                },
            }
        );
        return response.data
            ;
    }
);


//===========================   wallet-add / {{loc}}/api/v1/user/wallet/add

export const walletAddApi = createAsyncThunk(
    "walletAddApi/postWalletAddApi",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.post(
            `https://swtclub.com:4300/api/v1/user/wallet/add`,
            payload,
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                },
            }
        );
        return response.data
            ;
    }
);


//=================================== list of transaction 

export const fetchtransactionList = createAsyncThunk(
    "countryList/fetchtransactionList",
    async (payload) => {
        try {
            const response = await axios.get(
                `https://swtclub.com:4300/api/v1/user/wallet/list`,
                {
                    headers: {
                        "Authorization": isLoggedIn("swtadmin")
                    }
                }
            )
            return response.data
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    transactionData: [],
    status: "idle",
    error: null,
}

const transactionList = createSlice({
    name: "transactionList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchtransactionList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchtransactionList.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.transactionData = action.payload;
            })
            .addCase(fetchtransactionList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default transactionList.reducer;