import React from "react";
// import { images } from "../utils/images";
// import axios from "axios";



const BrandDetails = () => {

  return (
    <div>
      <section>
        <div className="ContactFormArea ContactArea">
          <div className="container">
            <div className="ContactFormBody BrandDetails">
              <form>
                <div className="row">
                    <div className="col-md-12">
                         <div className="NewTitleHeading">
                            <h3>Brand Detail</h3>
                         </div>
                    </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                        <h6>Company Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter company name"
                        name="companyName"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Contact Person</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Person Name"
                        name="companyName"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Contact Number</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Number"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Email Id</h6>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Id"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Organisation Website</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Organisation Website"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Logo</h6>
                      <div className="BrandDetailsLogo">
                         <h6>img</h6>
                          <span><input type="file" /><i class="fa-solid fa-pencil"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default BrandDetails;
