// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import countryReducer from './slices/countryListApiSlice'
import stateReducer from './slices/stateListApiSlice';
import cityReducer from './slices/cityListApiSlice';
import hotelDetailReducer from './slices/HotelDetailApiSlice';
import hotelListReducer from './slices/hotelListApiSlice'
import countryListSliceFromHotelBed from './slices/countryListApiHotelBedSlice'
import destinationReducer from './slices/destinationListApiSlice'
import addToCartReducer from './slices/addToCartApiSlice'
import packageReducer from './slices/packageApiSlice'
import hotelCartListApiReducer from './slices/hotelCartListApiSlice';
import checkRateApiReducer from './slices/checkRateApiSlice';
import roomsReducer from './slices/roomTypeApiSlice'
import accomodationReducer from './slices/accomodationApiSlice'
import boardsReducer from './slices/boardApiSlice'
import locationListReducer from './slices/locationListApiSlice'
import hotelBookingHistoryListReducer from './slices/hotelBookingHistoryApiSlice';
import partnerListReducer from './slices/partnerListApiSlice';
import staticContentReducer from './slices/staticContentApiSlice'
import packagePriceReducer from './slices/packagePriceList'
import proudPartnerReducer from './slices/proudPartnerApiSlice'
import packageBookedReducer from './slices/packagesBookedApiSlice'
import commissionReducer from './slices/commissionApiSlice'
import confirmHotelBookingReducer from './slices/confirmHotelBookingHotelBedApiSlice'
import flyerReducer from './slices/flyerApiSlice'
import userReducer from './slices/loginApiSlice'
import transactionReducer from './slices/payment/payment'


const rootReducer = combineReducers({
  countries: countryReducer,
  states: stateReducer,
  cities: cityReducer,
  hotelDetails: hotelDetailReducer,
  hotelList: hotelListReducer,
  countryList: countryListSliceFromHotelBed,
  destination: destinationReducer,
  addedToCart: addToCartReducer,
  packageList: packageReducer,
  hotelCartList: hotelCartListApiReducer,
  checkedRoomRateList: checkRateApiReducer,
  roomsList: roomsReducer,
  accomodationList: accomodationReducer,
  boardsList: boardsReducer,
  locationList: locationListReducer,
  hotelBookingHistoryList: hotelBookingHistoryListReducer,
  partnerList: partnerListReducer,
  staticContentData: staticContentReducer,
  pacakgePrice: packagePriceReducer,
  proudPartenrList: proudPartnerReducer,
  bookedPackageList: packageBookedReducer,
  commissionList: commissionReducer,
  confirmedHotelBookedData: confirmHotelBookingReducer,
  flyerList: flyerReducer,
  userData: userReducer,
  transactionData: transactionReducer
});

export default rootReducer;
