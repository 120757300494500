import React, { useEffect, useState } from 'react'
import HotelMainImageCard from '../components/HotelMainImageCard';
import { starCount } from '../utils/stars';
import { Link } from 'react-router-dom';
import { images } from '../utils/images';
import { useDispatch } from 'react-redux';
import { fetchHotelDetail } from '../reduxToolKit/slices/HotelDetailApiSlice';

const initialState = {
  imagesArr: [],
  hotelFacility: [],
  chainName: "",
}
const CustomHotelCard = ({ cardData: item, checkIn, checkOut, totalGuest }) => {


  const [iState, updateState] = useState(initialState)
  const { imagesArr, hotelFacility, chainName, status } = iState
  const dispatch = useDispatch()
  let hotelFacilityNew = hotelFacility.filter((item) => item?.facilityGroupCode === 70) ?? []

  console.log('Room detail', totalGuest)

  useEffect(() => {
    if (item?.code) {
      dispatch(fetchHotelDetail(item?.code)).then((res) => {
        updateState((prevState) => ({
          ...prevState,
          imagesArr: res?.payload?.hotel?.images?.filter((item) => item?.type?.description?.content === 'General view'),
          hotelFacility: res?.payload?.hotel?.facilities ?? [],
          chainName: res?.payload?.hotel?.chain?.description?.content ?? "",
          status: true
        }));
      });
    }
  }, [item])
  return (
    <div className="ProductBox">
      <div className="row">
        <div className="col-md-3">
          <div className="ProductLeft">
            <a >
              <figure>

                <div>
                  <img src={`http://localhost:8011/proxy/${imagesArr?.[0]?.path}`} height={200} width={200} style={{ borderRadius: '10px' }} />
                </div>
              </figure>

            </a>
            {/* <div className="ProductLeftOverlay">
            <div className="PloImages">
              <figure>
                <img src={images?.small_1} />
              </figure>
         
            </div>
            <p>9 Images</p>
          </div> */}
          </div>
        </div>
        <div className="col-md-9">
          <div className="ProductRight">
            <div className="ProductDetails">
              <Link to="/hotelDetail" state={{ item, checkIn, checkOut, totalGuest }}>
                <h6>
                  {item?.name}
                </h6>
              </Link>
              <p>
                <i className="fa-solid fa-location-dot" />
                {item.destinationName},{" "}{item?.zoneName}
              </p>
              <ul>
                <li>
                  {/* <i className="fa-solid fa-star checked" /> */}
                  {starCount(Number(item?.categoryCode?.split('').slice(0, 1)))?.map((i) => { return (i) })}
                  {/* <span>{item?.categoryCode?.split('').slice(0, 1).join('')}</span> */}
                  <span>{chainName}</span>
                </li>
                {/* <li>
                <img src={images?.flightSvgRepo} />{" "}
                Product for Packaging
                {item?.rooms?.[0]?.rates?.[0]?.packaging ? "Product for Packaging":"Product Not for Packaging"}
              </li> */}
                {/* <li>
                <img src={images?.cancellationFees} />
                46% Cancellation fees
              </li> */}
              </ul>
              <div></div>
              <div className="HotelFacility" >
                {
                  hotelFacilityNew?.slice(0, 5)?.map((item) => {
                    return (<span style={{ background: 'rgba(234, 240, 255, 1)', color: 'black' }}>{item?.description?.content}</span>)
                  })

                }
                <span style={{
                  fontSize: '17px',
                  fontWeight: '600',
                  textDecorationLine: "underline",
                  color: "black",
                  background: 'transparent'
                }}>+{hotelFacilityNew?.length} more</span>
              </div>

              <div className="ProductRightOverlay">
                <span>
                  <img src={images?.heart} />
                </span>
                <Link to='/hotelDetail' className="AddToCartBtn" state={{ item, checkIn, checkOut }} >
                  <i className="fa-solid fa-cart-shopping" />
                  {/* Add to Cart */} Rooms
                </Link>
              </div>
            </div>
            <div className="PrpArea">
              <span className="StartingFrom">
                Starting From
              </span>
              <div className="PrpBox">
                <div className="d-flex align-items-center">
                  <div className="RatingStars">
                    {starCount(item?.reviews[0].rate)?.map((i) => { return (i) })}
                  </div>
                  <span>Review Rating</span>
                </div>
                <h2>
                  {/* {item?.currency}  */}
                  INR {" "}
                  {item?.minRate} <span>/night Excl. tax</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomHotelCard