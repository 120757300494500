// citySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { privateData } from '../../config/apiKey';
import { xSignature } from '../../config/x-signature';
import signature from '../../config/signature';

// const apiUrl = 'https://api.example.com/cities';

export const fetchConfirmHotelBooking = createAsyncThunk('confirmhotelbooking/fetchConfirmHotelBooking', async (payload) => {
  console.log('payload===>', payload)
  const response = await axios.post(`${privateData["proxy-url"]}/hotel-api/1.0/bookings`, payload, {
    //https://api.test.hotelbeds.com/hotel-api/{version}/bookings
    headers: {
      "Api-key": privateData["Api-key"],
      "X-Signature": signature(),
    },
  });
  return response.data;
});

const confirmHotelBookingSlice = createSlice({
  name: 'checkedRoomRate',
  initialState: {
    confirmHotelBooking: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfirmHotelBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConfirmHotelBooking.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.confirmHotelBooking = action.payload;
      })
      .addCase(fetchConfirmHotelBooking.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default confirmHotelBookingSlice.reducer;
