// citySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { privateData } from '../../config/apiKey';
import { xSignature } from '../../config/x-signature';

// const apiUrl = 'https://api.example.com/cities';

export const fetchHotelBookingCancellation = createAsyncThunk('HotelBookingCancellation/fetchHotelBookingCancellation', async (payload) => {
  console.log('payload===>',payload)
  const response = await axios.delete(`${privateData["proxy-url"]}/hotel-api/1.0/bookings/${payload}`,{
    headers: {
      "Api-key": privateData["Api-key"],
      "X-Signature": xSignature ,
    },
  });
  return response.data;
});

// const checkedRoomRateSlice = createSlice({
//   name: 'checkedRoomRate',
//   initialState: {
//     checkedRoomRateList: [],
//     status: 'idle',
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchCheckedRoomRate.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(fetchCheckedRoomRate.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.checkedRoomRateList = action.payload;
//       })
//       .addCase(fetchCheckedRoomRate.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.error.message;
//       });
//   },
// });

// export default checkedRoomRateSlice.reducer;
