// citySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";

// const apiUrl = 'https://api.example.com/cities';

export const fetchCountryListOfHotelBeds = createAsyncThunk(
    "countryList/fetchCountryList",
    async (payload) => {
        try {
            const response = await axios.get(
                `${privateData["proxy-url"]}/hotel-content-api/1.0/locations/countries`,
                {
                    headers: {
                        "Api-key": privateData["Api-key"],
                        "X-Signature": xSignature,
                    }
                }
            )
            return response.data
        } catch (e) {
            console.log('Error', e)
        }
    }
);
//console.log('fetchCountryListOfHotelBeds =========> createAsyncThunk', fetchCountryListOfHotelBeds)

const initialState = {
    countryList: [],
    status: "idle",
    error: null,
}


const countryListSliceFromHotelBed = createSlice({
    name: "countryList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountryListOfHotelBeds.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCountryListOfHotelBeds.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.countryList = action.payload;
            })
            .addCase(fetchCountryListOfHotelBeds.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default countryListSliceFromHotelBed.reducer;
